<template>
  <div class="goodsPriceSuggested">
    <p
      v-expose="{ id: '1-6-1-217' }"
      class="goodsPriceSuggested__price"
      :style="strategyStyle.price"
    >
      {{ value }}
    </p>
    <p
      v-if="tip"
      class="goodsPriceSuggested__tip"
      :style="strategyStyle.tip"
    >
      &ensp;{{ tip }}
    </p>
  </div>
</template>

<script>
const SUGGESTED_SALE_PRICE_STYLE_MAP = {
  normal: {
    price: { color: '#666' },
    tip: { color: '#959595' } 
  },
  light: {
    price: { color: 'rgba(255, 255, 255, 0.8)' },
    tip: { color: 'rgba(255, 255, 255, 0.8)' } 
  },
}
export default {
  name: 'SuggestedSalePrice',
  props: {
    suggestedSalePrice: {
      type: Object,
      default: () => ({})
    },
    isBeltTypeBrand: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    value() {
      return this.suggestedSalePrice?.value
    },
    tip() {
      return this.suggestedSalePrice?.tip
    },
    strategyStyle() {
      let { normal, light } = SUGGESTED_SALE_PRICE_STYLE_MAP
      if (this.isBeltTypeBrand) return light
      return normal
    }
  }
}
</script>

<style lang="less">
.goodsPriceSuggested {
  display: flex;
  flex-wrap: nowrap;
  line-height: 1;
  &__price {
    .font-dpr(20px);
    text-decoration: line-through;
    color: #666;
  }
  &__tip {
    .font-dpr(20px);
    text-decoration: none;
    color: #959595;
    font-family: SF Pro;
    font-weight: 400;
  }
}
</style>
