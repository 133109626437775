<script lang="jsx">
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import ClientOnly from 'vue-client-only'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common'
import SwiperSlide from 'public/src/pages/components/swiperSlide/SwiperSlide.vue'
import CarouselsProductItem from './CarouselsProductItem.vue'
import CarouselsPagination from './CarouselsPagination.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { parseQueryString, preloadImgs } from '@shein/common-function'
import { transformImg } from '@shein/common-function'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue' 
const daEventExpose = daEventCenter.getExposeInstance()
daEventCenter.addSubscriber({ modulecode: '1-6-6' })

export default {
  name: 'NewCarousels',
  components: {
    ClientOnly,
    SwiperSlide,
    SwiperSlideItem,
    CarouselsProductItem,
    CarouselsTopBigImage: () => import(/* webpackChunkName: "carousels_Top_BigImage" */ './CarouselsTopBigImage.vue'),
    CarouselsOutfitsItem: () => import(/* webpackChunkName: "carousels_outfits_item" */ './CarouselsOutfitsItem.vue'),
    CarouselsPicInterest: () => import(/* webpackChunkName: "carousels_pic_interest" */ './CarouselsPicInterest.vue'),
    CarouselsVideoPlayBtn: () => import(/* webpackChunkName: "carousels_Video_Play_Btn" */ './CarouselsVideoPlayBtn.vue'),
    OutfitPop: () => import(/* webpackChunkName: "top_OutfitPop" */ 'public/src/pages/goods_detail/components/top/innerComponents/OutfitPop/index.vue'),
    AtmosphereFlow: () => import(/* webpackChunkName: "top_OutfitPop" */ './AtmosphereFlow.vue'),
    CarouselsNewGetTheLook: () => import(/* webpackChunkName: "carousels_new_get_the_look" */ './CarouselsNewGetTheLook.vue'),
  },
  props: {
    topContentReady: { // TODO 新数据流
      type: Boolean,
      default: false
    }
  },
  data() {
    const imgRatio = this.$route.query.imgRatio
    const fixedRatio = ['1-1', '3-4', '4-5', '5-6', '13-16'].includes(imgRatio) ? imgRatio : '3-4'
    return {
      showPicInterestComp: false, // 是否显示利益点组件
      isClickTabToSlide: false,          // 是否点击tab
      currentLookTab: 0,          // 当前 getTheLook 的聚焦 look tab
      currentIndex: 1,            // 当前页码
      currentImgId: '',      // 当前图片
      drawPointLoaded: false,     // outfits 锚点
      isOutfitPopReady: false,    // 异步加载 outfits 弹窗状态
      outfitOpenTab: 'outfit',    // outfits 弹窗 tab
      outfitOpenIndex: 0,         // outfits 弹窗 tab index
      isLoadBigImage: false,      // 是否加载大图弹窗
      bigImageShow: false,        // bigImage 弹窗
      fixedRatio, // 补图容器比例
      recDrawerShow: false,        // 加车成功后的推荐弹窗
      outfitsItemLoad: false,        // 加车成功后的推荐弹窗
      lazyMounted: false,          // 是否懒加载
      imgViewMaxIndex: 1, // 图片浏览最大下标, 以1开始
      imgViewIndexs: [1] // 浏览过的图片index集合
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['videoDuration', 'goodsReady', 'fromSwitchColor']),
    ...mapGetters('newProductDetail', ['isSsr', 'closeEstimatedAndAbPrice']),
    ...mapGetters('newProductDetail/Carousels', ['carouselsInfo', 'showArEntry', 'firstSku', 'showVideoEntry', 'outfitLanguageMap', 'picInterestInfo', 'outfitBlockShow']),
    ...mapGetters('newProductDetail/Main', ['showVimeoPlayer', 'showNativePlayer']),
    ...mapGetters('newProductDetail/common', ['isSingleMainPic', 'goodsId', 'saleAttrList', 'skuCode', 'newGtlEntryInfo', 'allDataReady', 'showAddBagRecDrawerScene', 'forbidAtmosphereFlow', 'isHitComplianceMode', 'watermarkConfig', 'isNewSizeLocal']),
    // 需要 outfitsItem 加载完成后再修改 itemLength 防止宽度计算有误
    swiperItemLength() {
      const { outfitsItemLoad, carouselsInfo, picInterestInfo, outfitBlockShow } = this
      const { productImgs } = carouselsInfo
      const { picInterestAbt, showPicInterest } = picInterestInfo
      let count = 0
      if (outfitsItemLoad && outfitBlockShow) {
        count++ 
        if(showPicInterest && picInterestAbt === 'outfitlast'){
          count++ 
        }
      }
      return productImgs?.length + count
    },
    // outfitBlockShow() {
    //   return this.carouselsInfo?.outfitBlockShow
    // },
    isDoublePic() {
      return !this.abtPicturePwaCut && this.fixedRatio !== '1-1' && !this.isSingleMainPic
    }
  },
  watch: {
    async $route(newVal, oldVal) {
      // 切颜色或者推荐跳转
      if (newVal?.params?.[1] != oldVal?.params?.[1]) {
        // 关闭大图
        this.bigImageShow = false
        this.isLoadBigImage = false
        this.currentLookTab = 0
      }
      this.getGoodsFirstImgRatio()
      this.updateVideoShowFlag(false)
      // 初始化图片浏览信息数据
      this.imgViewMaxIndex = 1
      this.imgViewIndexs = [1]
    },
    skuCode(newVal) {
      if (newVal) {
        if (this.saleAttrList?.skuList?.length <= 1) return //单个sku时不锚定
        const { productImgs } = this.carouselsInfo
        const index = productImgs.findIndex((i) => i.sku_code === newVal)
        if(index && index > -1){
          this.currentIndex = index + 1
          this.$refs?.['banner-wrap']?.slideTo(index, 500, false)
        }
      }
    },
    goodsReady: {
      immediate: true,
      handler(newVal, oldVal) {
        // 切换商品时，重置swiper的index
        this.$refs?.['banner-wrap']?.slideTo(0, 0, false)
        if (newVal && newVal != oldVal) {
          if (typeof window === 'undefined') {
            return
          }
          this.$nextTick(() => {
            this.handleExpose() // top组件不再二次mounted，曝光放在watch中
            // 渐进增强首图二图
            this.enHanceImg()
            let initIndex = 0
            if (this.fromSwitchColor) {
              const { productImgs } = this.carouselsInfo
              // 切换skc时需要定位到第一张skc图
              const _initIndex = productImgs.findIndex(i => !!i.is_skc_image)
              initIndex = _initIndex > -1 ? _initIndex : 0
              _initIndex && this.$refs?.['banner-wrap']?.slideTo(initIndex, 0, false) 
            }
            this.currentIndex = initIndex + 1
            this.updateVideoShowFlag(true)
          })
        }
      }
    },
    allDataReady(newVal){
      if (newVal) {
        if(typeof window !== 'undefined' && window.SaPageInfo && window.SaPageInfo.page_param) {
          window.SaPageInfo.page_param.is_show_pictureinterest = this.picInterestInfo?.interestList?.length ? 1 : 0  // 商详页埋点设置page_name
        }
        // outfits tab 曝光
        if (this.outfitBlockShow) {
          daEventCenter.triggerNotice({
            daId: '1-6-1-69',
          })
        }

        this.$nextTick(() => {
          this.lazyMounted = true
        })

      } else {
        this.lazyMounted = false
      }
    },
    'outfitBlockShow'(newVal, oldVal) {
      if (typeof window === 'undefined') return
      if (this.newGtlEntryInfo && newVal) {
        // outfits tab 曝光
        if (this.outfitBlockShow) {
          daEventCenter.triggerNotice({
            daId: '1-6-1-69',
          })
        }
      }
      // outfits 弹窗登录后刷新abt处理
      if (oldVal && !newVal && this.currentIndex > this.carouselsInfo?.productImgs?.length) {
        this.currentIndex = 1
        this.$refs['banner-wrap']?.slideTo?.(1, 0, false)
        this.$refs['outfitPop']?.closePop?.()
      }
    },
    // TODO:dele 加车按钮人气氛围数据依赖旧 vuex
    goodsId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          // 重置ar状态
          this.updateShowTryOnArContent(false)
          this.outfitsItemLoad = false
          // this.assignState({ addBtnAtmosphereInfo: [] })
        }
      }
    },
    outfitsItemLoad(newVal) {
      if (newVal) this.anchorToOutfits()
    }
  },
  mounted() {
    const { autostart = '' } = parseQueryString?.(window?.location?.search) || {}
    if (autostart == 1) {
      // 自动开启ar试穿，延迟加载，不影响首屏加载
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.openTryOnAr()
      }, 1500)
    }
    this.getGoodsFirstImgRatio()
    this.anchorToOutfits()
  },
  beforeDestroy() {
    this.updateVideoShowFlag(false)
  },
  methods: {
    ...mapMutations('newProductDetail/common', ['updatePlayVimeoStatus', 'updateFixedRatio', 'updateExternalSkuCode', 'updateAddLoadingAttachTop', 'updateHideStrangeBelt', 'updateCurrentLocalCountry']),
    ...mapMutations('newProductDetail', ['updateShowTryOnArContent']),
    // 旧 vuex 需要迁移
    ...mapMutations('productDetail', ['changePageStatus', 'assignState']),
    ...mapMutations('newProductDetail/PromotionEnter', [
      'updateViewPromotionStatus',
    ]),
    ...mapActions('newProductDetail', ['addToBag', 'openAddBagRecommendDrawer', 'fetchRecDataActions', 'autoGetCouponAtAddBag', 'openGetTheLookNewPopup']),
    ...mapMutations('newProductDetail/Main', ['updateVideoShowFlag']),
    ...mapActions('newProductDetail/common', ['setGoodsDetailScroll']),
    handleExpose () {
      // SwiperSlide 图片类型 曝光
      if (this.carouselsInfo?.picType) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-153',
          extraData: { pic_type: this.carouselsInfo?.picType }
        })
      }
    },
    // 主图点击事件
    handleGoodsImgClick(index) {
      this.currentIndex = index + 1
      this.currentImgId = this.carouselsInfo.productImgs[index].imgID || ''
      if (!this.isLoadBigImage) {
        this.isLoadBigImage = true
      } else {
        this.bigImageShow = true
      }
    },
    // 主图加载完成回调
    bigImageReadyCallback() {
      this.bigImageShow = true
    },
    // 关闭 bigImageBox
    changeBigImageShow() {
      this.bigImageShow = false
    },
    // 主图滑动完成
    handleSlideChangeTransitionEnd(index, isbigImage = false, imgID = '') {
      const { outfitBlockShow } = this
      const { productImgs } = this.carouselsInfo
      if (isbigImage) {
        let reallyIndex = productImgs.findIndex(i => i.imgID === imgID) || 0
        index = reallyIndex
        this.currentIndex = reallyIndex + 1
        // 保持主图同步
        this.$refs['banner-wrap'].slideTo(reallyIndex, 0, false)
      }else {
        // 4:3一页双图
        if(this.isDoublePic){
          // currentIndex为未更新之前的index，currentIndex === index，表示向右滑动，找下一张图重置曝光
          if(this.currentIndex === index && productImgs[index + 1]?.isPicInterest){
            daEventExpose.reset('expose-interest-events')
          }else if(productImgs[index - 1]?.isPicInterest){
            // 表示向左滑动，找上一张图重置曝光
            daEventExpose.reset('expose-interest-events')
          }
        }else {
          // 一页一图重置曝光
          if(productImgs[index]?.isPicInterest){
            daEventExpose.reset('expose-interest-events')
          }
        }
        this.currentIndex = index + 1
      }
      this.updateImgViewInfo()
      this.updateHideStrangeBelt(!!(this.newGtlEntryInfo && outfitBlockShow && this.currentIndex > productImgs?.length))
      if (index === productImgs?.length) {
        // 隐藏 outfit 红点
        if (typeof window !== 'undefined') {
          localStorage && localStorage?.setItem('outfitPoint', '1')
        }
      }
      // 滑动到 outfits 时加载
      if (this.currentIndex === productImgs?.length) {
        this.loadOutfitCanvas()
      }
      // 滑动到outfits发埋点
      if (!this.isClickTabToSlide && this.currentIndex > productImgs?.length) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-70',
          extraData: {
            entry_mode: 1,
          },
        })
      }
      this.isClickTabToSlide = false
    },
    // 设置图片埋点数据
    updateImgViewInfo(){
      const { productImgs } = this.carouselsInfo
      const { showPicInterest, picInterestAbt} = this.picInterestInfo
      const { currentIndex, imgViewMaxIndex, imgViewIndexs, outfitBlockShow } = this
      let picNum = productImgs.length
      let newIndex = currentIndex
      // outfits不参与埋点，这里分两种情况，1、利益点在outfit前面 2、利益点在outfit后面
      if(currentIndex > picNum) {
        // 这里就表示最后一张是outfitx
        if(!(showPicInterest && picInterestAbt === 'outfitlast' && outfitBlockShow)){
          return
        }
        // 最后一张就是利益点，要减去上一张outfit
        if(currentIndex === picNum + 1) {
          // 这里是outfit 不参与计数
          return
        }else{
          newIndex = currentIndex - 1
        }
      }
      // 更新最大浏览下标
      if(newIndex > imgViewMaxIndex) {
        this.imgViewMaxIndex = newIndex
      }
      // 如果之前没有浏览过的图片，就放入数组, 不包含利益点,
      // newIndex大于图片数量是利益点， 小于等于图片长度并且isPicInterest为true也是利益点
      if(!imgViewIndexs.includes(newIndex) && !(newIndex > picNum) && !productImgs[newIndex - 1]?.isPicInterest) {
        this.imgViewIndexs = [...imgViewIndexs, newIndex]
      }
      if(typeof window !== 'undefined' && window?.SaPageInfo?.page_param) {
        Object.assign(window.SaPageInfo.page_param, {
          img_view_index: this.imgViewMaxIndex || 1,
          gds_img_view_cnt: this.imgViewIndexs.length || 1
        })
      }
    },
    // 切换页码
    toggleActive(i, isClickTab){
      const { outfitBlockShow } = this
      const { productImgs } = this.carouselsInfo

      if (i === this.currentIndex) return
      this.isClickTabToSlide = !!isClickTab
      if (i !== 1) {
        if (isClickTab) {
          this.$refs['banner-wrap'].slideTo(productImgs.length, 500)
          // 直接切换到 outfits 时加载
          this.loadOutfitCanvas()
        }
        if (outfitBlockShow) {
          if (typeof window !== 'undefined') {
            localStorage && localStorage?.setItem('outfitPoint', '1')
          }
        }
      } else {
        isClickTab && this.$refs['banner-wrap'].slideTo(0, 500)
      }
      this.currentIndex = i
    },
    // 加载 DrawPoint 
    loadOutfitCanvas() {
      const { outfitImage, outfitsData } = this.carouselsInfo
      // 确保数据来源是outfit，有可能是getTheLook
      if (outfitImage && outfitsData?.labels?.length && !this.drawPointLoaded) {
        this.drawPointLoaded = true
      }
    },
    // 打开 OutfitPop 
    handleOpenOutfitPop(item, index = 0) {
      // 新Gtl模块
      if (this.newGtlEntryInfo?.labels?.length || item === 'newGtlOutfit') {
        this.openGetTheLookNewPopup({ initCateTab: item !== 'newGtlOutfit' ? this.newGtlEntryInfo?.labels?.[index]?.cv_goods_id : '' })
        return
      }
      this.outfitOpenTab = item
      this.outfitOpenIndex = index
      
      if (this.isOutfitPopReady) {
        this.isOutfitPopReadyCallback()
      } else {
        // 首次通过 OutfitPop 加载完成回调触发
        this.isOutfitPopReady = true
      }
    },
    // outfits 弹窗加载完成回调
    isOutfitPopReadyCallback() {
      const { outfitOpenTab, outfitOpenIndex, currentIndex, carouselsInfo } = this
      this.$nextTick(() => {
        this.$refs.outfitPop?.openPop(outfitOpenTab, outfitOpenIndex)
      })

      // 图片的最后一张，只露出半屏的outfit时，点击outfit锚点切换到outfit图片
      const { productImgs } = carouselsInfo
      if (outfitOpenTab == 'outfit' && currentIndex == productImgs?.length) {
        this.toggleActive(productImgs?.length + 1, true)
      } 
    },
    // 侧边 getTheLook 切换look tab
    handleToggleLook(index) {
      this.currentLookTab = index
    },
    // 获取首图宽高比例
    async getGoodsFirstImgRatio() {
      const { productImgs } = this.carouselsInfo
      let { imgRatio = null } = this.$route.query
      if (!imgRatio) {
        const firstImg = productImgs[0]?.origin_image || productImgs[0]?.image_url
        imgRatio = firstImg && await getImageRatio(firstImg)
      }
      let fixedRatio = Array.isArray(imgRatio) ? imgRatio[0] : imgRatio
      this.updateFixedRatio(fixedRatio || '3-4')
      if (this.fixedRatio != fixedRatio) {
        this.fixedRatio = fixedRatio
        setTimeout(() => this.$refs['banner-wrap']?.onUpdate?.(), 500)
      }
    },
    clickEstimatedTag() {
      this.updateViewPromotionStatus({ status: true })
    },
    // 快速加车
    handleOpenQuick() {
      const { detail, mallCode, quantity, externalSizeInfoIndex } = this.carouselsInfo
      let drawerEle = document.querySelector('.estimated-experiment-area')
      const hasPromotionDrawer = drawerEle && (window.getComputedStyle(drawerEle).display !== 'none')

      this.$quickAdd.open({
        mainPageGoodsId: detail?.goods_id,
        goods_id: detail?.goods_id,
        mallCode: mallCode,
        imgRatio: this.fixedRatio,
        selectedAttrIndex: externalSizeInfoIndex,
        isClickToDetail: false,
        isShowWishBtn: false,
        customAddSuc: true,
        isShowAttrPlusSize: this.showMultiLayer,
        quantity,
        addSource: 'detailPage',
        forbidAtmosphereFlow: this.forbidAtmosphereFlow,
        showBestDealLabel: true,
        showFollowBeltByOrigin: true,
        // showOneClickPay: oneClickPayState.support,
        showEstimatedPrice: !this.closeEstimatedAndAbPrice,
        closeEstimatedAndAbPrice: this.closeEstimatedAndAbPrice,
        showNoSatisfied: true,
        needCartTagTipsUpdate: false,
        clickCallBack: {
          // 加车成功弹窗推荐
          loadAddBagRecDrawer: async ({ data = {} }) => {
            const { abtPicNewCart } = this.carouselsInfo
            const { showAddBagRecDrawerSceneOne, showAddBagRecDrawerSceneTwo } = this.showAddBagRecDrawerScene
            if (abtPicNewCart === 'C' && data?.addCartGoodsId === this.goodsId && (showAddBagRecDrawerSceneOne || showAddBagRecDrawerSceneTwo)) {
              // 时机一提前获取加车推荐数据
              await this.fetchRecDataActions()
              const status = await this.openAddBagRecommendDrawer()
              return status
            }
            return false
          },
          complete: (result) => {
            this.autoGetCouponAtAddBag({ needToast: result?.data?.code != 0 })
            const { abtPicNewCart } = this.carouselsInfo
            if (String(result?.data?.code) !== '0') {
              return
            }
            if (abtPicNewCart === 'C') {
              // 关闭大图
              this.bigImageShow = false
              // 激活 AddPop.vue
              this.changePageStatus({ addToBagCompletedImg: true })
              window?._gb_cart_tag_tips_?.update({
                excludeScroll: true
              })
              this.$nextTick(() => {
                setTimeout(async() => {
                  window.vBus && window.vBus.$emit('triggerAddCompletedAnimation')
                  if(!result?.data?.loadAddBagRecDrawerStatus) {
                    this.setGoodsDetailScroll()
                  }
                }, 100)
              })
              return
            }
            window?._gb_cart_tag_tips_?.update({
              excludeScroll: false
            })
          },
          ...(hasPromotionDrawer ? {} : {
            clickEstimated: () => {
              this.updateViewPromotionStatus({
                status: true,
                isQuickAdd: true
              })
            }
          }),
          handleChangeLocalCountry: (value) => {
            // 数据联动控制
            this.isNewSizeLocal && this.updateCurrentLocalCountry(value)
          },
        },
        analysisConfig: {
          postData: { location: 'popup_detail_image' },
        },
      })
    },
    // 加车
    handleAddToBag() {
      const { abtPicNewCart, language } = this.carouselsInfo
      this.updateAddLoadingAttachTop(true)
      this.addToBag({
        target: null,
        isReview: false,
        scrollToRecommend: false,
        reportPostData: { location: 'page_detail_image' },
      })
        .then(async (result) => {
          if (!result) {
            return
          }
          if (String(result.data?.code) !== '0') return
          // 加车成功，弹出提示
          if (abtPicNewCart === 'C') {
            // 关闭大图
            this.bigImageShow = false
            if (result.loadAddBagRecDrawerStatus) return
            this.$toast(language?.SHEIN_KEY_PWA_15060)
            this.setGoodsDetailScroll()
          } else {
            if (result.loadAddBagRecDrawerStatus) return
            this.$toast(language?.SHEIN_KEY_PWA_15060)
          }
        })
        .finally(() => {
          this.updateAddLoadingAttachTop(false)
        })
    },
    enHanceImg(){
      if(this.isSsr) return
      const productImgs = this.carouselsInfo?.productImgs || []
      let preLoadImgs = []
      productImgs.forEach((item, index)=>{
        const isFsIndex = this.isDoublePic ? index > 2 : index > 1
        if (isFsIndex) return // 列表跳转前两张手动补图
        const dataSrc = item?.origin_image || item?.image_url
        dataSrc && preLoadImgs.push(transformImg({ img: dataSrc }))
      })
      preloadImgs({ imgs: preLoadImgs }).then(()=>{
        const imgList = this.$refs?.['banner-wrap']?.$el?.querySelectorAll('.fsp-element') || []
        imgList.forEach((item, index) => {
          let src = item.getAttribute('src') || ''
          let resultSrc = preLoadImgs[index]
          // 商品中心原图
          // 接入 Apollo 控制是否或者商品中心原图(不带后缀)
          if (this.carouselsInfo?.isSupportCropImage && resultSrc) {
            resultSrc =  resultSrc?.replace(/(_square)|(_squfix)/ig, '')
          }
          if (item && src !== resultSrc && resultSrc) { item.setAttribute('src', resultSrc) }
        })
      })
    },
    // 开启试穿ar
    openTryOnAr() {
      if (!this.showArEntry) return
      if (!this.skuCode) {
        // 没选中默认选中第一个sku
        this.updateExternalSkuCode(this.firstSku?.sku_code || '')
        // 延迟等待skuCode更新
        const timer = setTimeout(() => {
          clearTimeout(timer)
          this.updateShowTryOnArContent(true)
        })
        return
      }
      this.updateShowTryOnArContent(true)
    },
    anchorToOutfits() {
      const { outfits = 0 } = parseQueryString?.(window?.location?.search) || {}
      const hasOutfitsQuery = +outfits === 1
      if (this.outfitsItemLoad && this.carouselsInfo?.productImgs && !this.isReachedToOutfits && hasOutfitsQuery) {
        setTimeout(() => this.toggleActive(this.carouselsInfo?.productImgs?.length + 1, true), 310)
        this.isReachedToOutfits = true
      }
    }
  },
  render() {
    const {
      carouselsInfo,
      showPicInterestComp,
      isLoadBigImage,
      bigImageShow,
      recDrawerShow,
      currentIndex,
      currentImgId,
      currentLookTab,
      drawPointLoaded,
      videoDuration,
      swiperItemLength,
      showArEntry,
      newGtlEntryInfo,
      outfitLanguageMap,
      isHitComplianceMode,
      lazyMounted,
      outfitBlockShow,
      showVimeoPlayer,
      showNativePlayer,
      showVideoEntry,
    } = this
    const {
      language,
      GB_cssRight_rp,
      productImgs,
      productBigImgs,
      outfitsData,
      cccVideoPosition,
      getTheLookBlockShow,
      getTheLookInfoData,
      outfitImage,
      locateLabelsInfo,
      abtPicturePwaCut,
      goodsImgs,
      IS_RW,
      LAZY_IMG,
      isSupportCropImage,
      atomFlowConfig,
      detail,
      userBehaviorLabels
    } = carouselsInfo
    
    const { showPicInterest, picInterestAbt, } = this.picInterestInfo
    // 是否展示人气氛围
    const isShowAtmosphereFlow = () => {
      if (showArEntry || !lazyMounted) return false
      if (!userBehaviorLabels?.length || (outfitBlockShow && currentIndex === productImgs?.length + 1)) {
        return false
      }
      return true
    }
    // 商品权益图展示
    const showInterestOutfit = () => showPicInterest && picInterestAbt === 'outfitlast' && outfitBlockShow
    // outfit模块不展示时，塞到主图最后一张
    const showInterestCarousels = () => showPicInterest && (['newlast', 'newfour'].includes(picInterestAbt) || (picInterestAbt === 'outfitlast' && !outfitBlockShow))
    // 是否显示 video
    const showVideo = () => showVideoEntry && (showVimeoPlayer || showNativePlayer)

    const renderSwiperSlide = () => (
      <SwiperSlide
        ref="banner-wrap"
        aria-hidden="true"
        touchable={this.goodsReady}
        class="goodsd-swiper j-good-detail-swiper fsp-element"
        observer-length
        direction={GB_cssRight_rp ? 'rtl' : 'ltr'}
        space-between={this.isDoublePic ? 3 : 0}
        item-length={swiperItemLength}
        vOn:slideChangeTransitionEnd={this.handleSlideChangeTransitionEnd}
      >
        {/* 商品主图 */}
        <CarouselsProductItem
          is-ssr={this.isSsr}
          is-single={this.isSingleMainPic}
          is-rw={IS_RW}
          lazy-img={LAZY_IMG}
          is-support-crop-image={isSupportCropImage}
          language={language}
          show-pic-interest-comp={showPicInterestComp}
          is-double-pic={this.isDoublePic}
          goods-ready={this.goodsReady}
          show-interest-carousels={showInterestCarousels()}
          fixed-ratio={this.fixedRatio}
          is-pic-cut={abtPicturePwaCut}
          is-rtl={GB_cssRight_rp}
          product-imgs={productImgs}
          locate-labels-info={locateLabelsInfo}
          vOn:clickGoodsImg={this.handleGoodsImgClick}
        />
        {/* outfit 图片加载 */}
        { outfitBlockShow && (
          <CarouselsOutfitsItem
            is-single={this.isSingleMainPic}
            language={language}
            lazy-img={LAZY_IMG}
            is-support-crop-image={isSupportCropImage}
            fixed-ratio={this.fixedRatio}
            is-pic-cut={abtPicturePwaCut}
            total-index={productImgs?.length}
            outfit-image={outfitImage}
            outfits-data={outfitsData}
            get-the-look-block-show={getTheLookBlockShow}
            get-the-look-info-data={getTheLookInfoData}
            current-look-tab={currentLookTab}
            draw-point-loaded={drawPointLoaded}
            $quickAdd={this.$quickAdd}
            config={{ isHitComplianceMode }}
            outfit-language-map={outfitLanguageMap}
            vOn:handleOpenOutfitPop={this.handleOpenOutfitPop}
            vOn:handleToggleLook={this.handleToggleLook}
            vOn:handleSwiperSlideLoaded={() => {
              this.outfitsItemLoad = true
            }}
          />
        )}
        {/* 商品权益保障利益点-展示在outfit后 */}
        { showInterestOutfit() && this.goodsReady && (
          <SwiperSlideItem 
            index={productImgs?.length}
          >
            <CarouselsPicInterest
              is-single={this.isSingleMainPic}
              language={language}
              fixed-ratio={this.fixedRatio}
              is-pic-cut={abtPicturePwaCut}
              total-index={productImgs?.length}
              is-double-pic={this.isDoublePic}
            />
          </SwiperSlideItem>
        )}

      </SwiperSlide>
    )

    const renderBottom = () => {
      if (!(this.goodsReady && (!this.isPerformance || this.topContentReady))) {
        return null
      }
      const showNewGetTheLook = outfitBlockShow && currentIndex > productImgs?.length
      return <div class="carousels-detail__bottom">
        <CarouselsPagination
          goods-id={detail?.goods_id}
          outfit-block-show={outfitBlockShow}
          language={language}
          is-rtl={GB_cssRight_rp}
          total-index={productImgs?.length}
          outfits-data={outfitsData}
          current-index={currentIndex}
          key={`${newGtlEntryInfo?.series_img}${this.$route.path}`}
          vOn:toggleActive={this.toggleActive}
        />
        <ClientOnly>
          {!!newGtlEntryInfo && <CarouselsNewGetTheLook 
            show={showNewGetTheLook}
            vOn:handleOpenOutfitPop={this.handleOpenOutfitPop}
          />}
          {!!newGtlEntryInfo && !!showNewGetTheLook && <div class="carousels-detail__bottom-mask"></div>}
        </ClientOnly>
      </div>
    }

    return (
      <div
        {...{ directives: [{ name: 'ada', value: { level: 1, pos: [0] } }] }}
        id="banner-detail" 
        ref="banner-detail"
        class="carousels-detail"
        role="text"
        aria-label={language?.SHEIN_KEY_PWA_15408}
      >
        {/* 轮播主图 */}
        { renderSwiperSlide() }

        {/* 底部区域 页码 + 底部套装模块 */}
        { renderBottom() }
    
        <ClientOnly>
          {/* 视频按钮 */}
          { showVideo() && (
            <CarouselsVideoPlayBtn
              total-index={productImgs?.length}
              video-duration={videoDuration}
              ccc-video-position={cccVideoPosition}
              current-index={currentIndex}
              get-the-look-block-show={getTheLookBlockShow}
              vOn:playVimeo={this.updatePlayVimeoStatus}
            />
          )}
          {/* outfis 弹窗 */}
          { this.isOutfitPopReady && (
            <OutfitPop
              ref="outfitPop"
              is-outfit-pop-ready-callback={this.isOutfitPopReadyCallback}
            />
          ) }
          { isLoadBigImage && (
            <CarouselsTopBigImage
              big-image-show={bigImageShow}
              rec-drawer-show={recDrawerShow}
              product-imgs={productBigImgs}
              current-img-id={currentImgId}
              current-index={currentIndex}
              atom-flow-config={atomFlowConfig}
              user-behavior-labels={userBehaviorLabels}
              vOn:bigImageReadyCallback={this.bigImageReadyCallback}
              vOn:handleSlideChange={this.handleSlideChangeTransitionEnd}
              vOn:clickEstimatedTag={this.clickEstimatedTag}
              vOn:clickAddToBag={this.handleAddToBag}
              vOn:clickOpenQuick={this.handleOpenQuick}
              vOn:closeBigImageShow={() => {
                this.bigImageShow = false
                if(productImgs[this.currentIndex]?.isPicInterest){
                  daEventExpose.reset('expose-interest-events')
                }
                // todo: 后续需要重构大图组件分离两边的 currentIndex 依赖
                if(this.currentIndex - 1 > 0){
                  this.$refs?.['banner-wrap']?.slideTo(this.currentIndex - 1, 0, false)
                }
              }}
              vOn:closeRecDrawerShow={() => this.recDrawerShow = false }
            />
          )}
          { isShowAtmosphereFlow() && userBehaviorLabels.length > 0 && (
            <AtmosphereFlow
              show={atomFlowConfig?.pageShow}
              key={this.carouselsInfo.detail.goods_id }
              user-behavior-labels={userBehaviorLabels}
              config={atomFlowConfig}
              location="main_image"
              style={{ bottom: '8%' }}
            />
          ) }
          {!(outfitBlockShow && currentIndex > productImgs?.length) && showArEntry ?
            <div
              {...{ directives: [{ name: 'expose', value: { id: '1-6-1-163' } }, { name: 'tap', value: { id: '1-6-1-164' } }] }}
              class="detail-tryOnAr"
              vOn:click_stop={this.openTryOnAr}>
              <Icon name="sui_icon_ar_fat_xs" size="16px" />
              TRY IN AR
            </div>
            : null}
        </ClientOnly>
      </div>
    )
  }
}

</script>

<style lang="less" scoped>
.carousels-detail {
  margin: 0 -0.32rem;
  position: relative;

  &__bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: @zindex-out;
  }

  &__bottom-mask {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% + 1.2rem);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%);
    z-index: -1;
    pointer-events: none;
  }

  .goods-img {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: #FAFAFA;
    // > img {
    //   height: 100%;
    //   width: 100%;
    //   object-fit: contain;
    // }
  }
  .cut-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .detail-tryOnAr {
    position: absolute;
    top: 0.17rem;
    left: 0.17rem;
    display: inline-flex;
    padding: 0.16892rem 0.27027rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.40541rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

    font-size: 0.23649rem;
    font-style: normal;
    font-weight: 700;
    & > span {
      margin-right: 0.14rem;
    }
  }
}
</style>
