export const skuInfoAdator = (skuInfo, { mallInfoList, isOnSale, goodsSn } = {}) => {
  // 解析旧参数
  if (skuInfo?.sku_code) {
    // 原数据结构
    // {
    //   doublePoints: 0, => 无需兼容
    //   mall: {}, => done
    //   mall_price: [], => done
    //   mall_stock: [], => done
    //   price: {}, => done
    //   promotionInfo: [], => detailPromotionInfo
    //   promotion_info: [], => detailPromotionInfo
    //   rewardPoints: '', => 无需兼容
    //   skc_name: '', => done
    //   sku_code: '', => done
    //   sku_sale_attr: [], => done
    //   stock: '', => 数字化
    // }
    const mall = {}
    const { mall_price, mall_stock, stock, priceInfo, detailPromotionInfo, sku_code, sku_sale_attr } = skuInfo || {}
    const mallPrice = mall_price?.map?.(price => priceAdaptor(price)) || mall_price
    const mallStock = mall_stock?.map?.(item => {
      const stockItem = stockAdaptor(item) || {}
      const { mall_code = '' } = item || {}
      if (mallInfoList?.find?.(mall => mall?.mall_code === mall_code)) {
        const price = mallPrice?.find((price) => price.mall_code == mall_code) || {}
        mall[mall_code] = {
          ...(stockItem || {}),
          ...(price || {}),
          ...(!isOnSale ? { stock: 0 } : {})
        }
      }
      return stockItem
    }) || mall_stock
    const promotionInfo = promotionInfoAdaptor(detailPromotionInfo) || []
    
    return {
      mall,
      mall_price: mallPrice,
      mall_stock: mallStock,
      price: {
        ...(priceInfo || {}),
        unit_discount: priceInfo?.unitDiscount,
      },
      promotionInfo,
      promotion_info: promotionInfo,
      skc_name: goodsSn || '',
      sku_code,
      sku_sale_attr,
      stock: +stock,
    }
  }
  return skuInfo
}

const priceAdaptor = (priceItem) => {
  if (Object.keys(priceItem || {}).length === 0) return priceItem
  const { 
    retail_discount_price, 
    retail_original_discount_price,
    retail_vip_discount_price,
    retail_discount_value,
    retail_unit_discount,
    retail_original_discount,
    isInversion,
  } = priceItem || {}
  // 原数据模型
  // {
  //   mall_code: '',  => done
  //   retailPrice: {}, => done
  //   retailDiscountPrice: {},  => retail_discount_price
  //   retailOriginalDiscountPrice: {}, => retail_original_discount_price
  //   retailVipDiscountPrice: {}, => retail_vip_discount_price
  //   retailDiscountValue: '', => retail_discount_price
  //   retailDiscountPercent: 0, => retail_unit_discount
  //   retailOriginalDiscount: '', => retail_original_discount
  //   salePrice: {}, => done
  //   suggestedSalePrice: {}, => done
  //   isInversion: 0, => 数字化
  //   isNewCoupon: 0, => 无需兼容
  //   discountPrice: {}, => done
  //   unit_discount: 0, => done
  //   promotion_status: null, => 无需兼容
  //   promotion_logo_type: null, => done
  //   original_discount_price: {}, => done
  //   prev_discount_value: '', => done
  //   coupon_prices: [], => done
  //   rewardPoints: 0, => done
  //   showPrice: null, => done
  //   showTypeId: null, => done
  //   vipDiscountPrice: null, => done
  //   discountValue: null, => done
  //   promotion_discount_info: [], => done
  //   coupons: [], => done
  //   mul_price_info: [], => done
  //   double_price_info: {}, => done
  //   double_optimal_coupons: [], => done
  // }

  // 下面字段都是置为null的
  // double_optimal_coupons
  // double_price_info
  // coupon_prices
  // coupons
  return {
    ...priceItem,
    retailDiscountPrice: retail_discount_price,
    retailOriginalDiscountPrice: retail_original_discount_price,
    retailVipDiscountPrice: retail_vip_discount_price,
    retailDiscountValue: retail_discount_value,
    retailDiscountPercent: retail_unit_discount,
    retailOriginalDiscount: retail_original_discount,
    isInversion: +isInversion,
  }
}

const stockAdaptor = (stockItem) => {
  if (Object.keys(stockItem || {}).length === 0) return stockItem
  const { 
    stock,
    isSupportQuickShip, 
  } = stockItem || {}
  // 原数据模型
  // {
  //   "mall_code": "2",
  //   "stock": 0, => 数字化
  //   "mall_code_sort": 1, => 无需兼容
  //   "sku_quick_ship": false, => isSupportQuickShip
  //   "skc_quick_ship": false => 无需兼容
  // }
  return {
    ...stockItem,
    stock: +stock,
    sku_quick_ship: !!isSupportQuickShip,
  }
}

const promotionInfoAdaptor = (promotionInfo) => {
  if (!promotionInfo?.length) return promotionInfo
  return promotionInfo.map((item) => {
    const { promotion_logo_type } = item || {}
    // 原数据模型
    // {
    //   "id": "3710940", => done
    //   "typeId": "31", => done
    //   "isReturn": "1", => 无需兼容
    //   "memberRule": [], => 无需兼容
    //   "endTimestamp": "1725206399", => done
    //   "promotionLogoType": 9, =>  promotion_logo_type + 数字化
    //   "categoryInfo": [], => 无需兼容
    //   "mall_code": "1" => done
    // }
    return {
      ...(item || {}),
      promotionLogoType: +promotion_logo_type,
    }
  })
}
