<script lang="jsx">
export default {
  name: 'PlaceholderGoodsSize',
  functional: true,
  props: {
    sizeInfo: {
      type: Object,
      default: () => ({})
    }
  },
  render(h, { props }) {
    const sizeInfo = props.sizeInfo || {}
    const skcSaleAttr = sizeInfo?.skcSaleAttr || []
    return skcSaleAttr.map((item, index) => (
      <div key={index}>
        <div class="placeholder-goods-country"> {item.attr_name} </div>
        <ul class="placeholder-goods-size goods-size__sizes">
          {item.attr_value_list.map((sizeItem, sizeIndex) => (
            <li
              key={sizeIndex}
              class="placeholder-goods-size-item goods-size__sizes-item twoline-text-box"
            >
              <p class="goods-size__sizes-item-text goods-size__sizes-item-text--two">
                {sizeItem.attr_value_name}
              </p>
            </li>
          ))}
        </ul>
      </div>
    ))
  }
}
</script>

<style lang="less" scoped>
.placeholder-goods-country {
  height: 0.5rem;
  margin-bottom: 0.2rem;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
.placeholder-goods-size {
  display: flex;
  margin-bottom: 0.3rem;
}
.placeholder-goods-size-item {
  min-width: 1.6rem;
  text-align: center;
}
</style>
