<template>
  <div
    v-expose="{id: '1-6-1-154', data: { freeshipping_location: 2 }}"
    class="freeshipping-tips__container"
  >
    <div class="freeshipping-tips__text">
      <i class="suiiconfont sui_icon_free_shipping_12px icon"></i>
      {{ freeshippingTipsText }}
    </div>
    <div class="freeshipping-tips__triangle"></div>
  </div>
</template>

<script>
export default {
  name: 'FreeShippingTips',
  props: {
    language: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    freeshippingTipsText () {
      return this.language.SHEIN_KEY_PWA_25388
    }
  }
}
</script>

<style lang="less">
.freeshipping-tips {
  &__container {
    position: relative;
    max-width: 100%;
    padding: 0.0533rem 0.10667rem;
    line-height: normal;
    color: @sui_color_white;
    background: @sui_color_safety; // #198055
    font-size: 0.2667rem;
    .icon {
      vertical-align: middle;
      margin-right: 0.0533rem;
    }
  }
  &__triangle {
    position: absolute;
    width: 0;
    height: 0;
    border: .1333rem solid transparent;
    border-top-color: #286b4e;
    bottom: -.1rem;
    right: 2px;
    transform: rotate(135deg);
  }
  &__text {
    vertical-align: middle;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
