<script lang="jsx">
import Qty from 'public/src/pages/goods_detail/components/qty.vue'

export default {
  name: 'QuantityJsx',
  functional: true,
  components: {
    Qty,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { props }) {
    const { language } = props
    return (
      <div class="goods-qty">
        <div class="goods-qty__body">
          {language.SHEIN_KEY_PWA_15664}:
          <Qty />
        </div>
      </div>
    )
  },
}
</script>

<style lang="less">
.goods-qty {
  padding: 0.426667rem 0 0;
  &__body {
    display: flex;
    align-items: center;
  }
}
</style>
