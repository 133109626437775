<script>
export default {
  name: 'SizeAttrPicInfo',
}
</script>
<script setup>
import { defineProps, ref, computed } from 'vue'
import { ImageBox, Slide } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  attrValueName: {
    type: String,
    default: '',
  },
  attrValueImage: {
    type: String,
    default: '',
  },
  soldOutTips: {
    type: String,
    default: '',
  },
  analysisData: {
    type: Object,
    default: () => {},
  },
})
const imgShow = ref(false)

const handleOpenBigImg = () => {
  imgShow.value = !imgShow.value
}

const cTapAnalysisData = computed(() => {
  const { analysisData } = props || {} 
  if (!analysisData?.goods_id) return {}
  // 过滤无用key
  const ingoreKeys = ['main_attr']
  return Object.keys(analysisData).reduce((map, key) => {
    if (ingoreKeys.includes(key)) return map
    map[key] = analysisData[key]
    return map
  }, {})
})

</script>
<template>
  <Slide :visible="show">
    <div class="pic-info">
      <div
        v-if="soldOutTips"
        class="pic-info__tips"
      >
        {{ soldOutTips }}
      </div>
      <p class="pic-info__text">
        <span class="pic-info__text-detail">{{ title }}</span>
        <span class="pic-info__text-name">{{ attrValueName }}</span>
      </p>
      <div
        v-tap="{ id: '1-6-1-174', data: cTapAnalysisData }"
        v-expose="{ id: '1-6-1-173', data: analysisData }"
        class="pic-info__img"
        @click="handleOpenBigImg"
      >
        <img :src="attrValueImage" />
      </div>

      <ImageBox
        :visible.sync="imgShow"
        :append-to-body="true"
      >
        <div
          class="pic-info__big"
          @click="handleOpenBigImg"
        >
          <div class="pic-info__big-close">
            <Icon
              name="sui_icon_close_18px"
              size="24px"
              color="#000"
            />
          </div>
          <img :src="attrValueImage" />
          <p class="pic-info__big-text">
            {{ attrValueName }}
          </p>
        </div>
      </ImageBox>
    </div>
  </Slide>
</template>
<style lang="less" scoped>

.pic-info {
  padding: 12px;
  background: #F6F6F6;

  &__tips {
    color: @sui_color_highlight;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 8px;
  }

  .pic-info__text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
  .pic-info__text-detail {
    color: #666;
  }
  .pic-info__text-name {
    color: #000;
  }
  .pic-info__img {
    margin-top: .2133rem;
  }
  .pic-info__img > img {
    max-width: 100%;
    // max-height: 72px;
    height: 72px;
  }
}

.pic-info__big {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pic-info__big-close {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  width: .8533rem;
  height: .8533rem;
  border-radius: .8533rem;
  position: absolute;
  top: .16rem;
  left: .2133rem;
}
.pic-info__big-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFF;
  position: absolute;
  width: 8.72rem;
  max-width: 8.72rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.6133rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
