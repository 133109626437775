<script lang="jsx">
export default {
  name: 'SizeTitle',
  functional: true,
  props: {
    bold: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, { props }) {
    const { bold, title } = props
    return (
      <div
        class="goods-size__title"
        aria-hidden="true"
      >
        <div
          class={[
            'goods-size__title-txt',
            {
              'goods-size__title-bold': bold,
            },
          ]}
        >
          {title}
        </div>
      </div>
    )
  },
}
</script>
