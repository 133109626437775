import { ref, getCurrentInstance } from 'vue'
import { template } from '@shein/common-function'
/**
 * @props
 *  @param {Object} dataMapCompose
 *   @param {Object} dataMap 库存映射
 *   @param {Object} dataMapOrder 映射id顺序
 *  @param {Object} skuList 库存列表
 *  @param {Object} skcSaleAttr 销售属性
 *  @param {Object} skuList sku信息
 */
export default function useSkuOptional(props = {}, {
  handleCalcSize,
}) {
  const instance = getCurrentInstance()
  const sizeInfoIndex = ref({})
  const isInit = ref(false)
  const handleChooseCheck = (payload) => {
    const { 
      attr_id,
      attr_name,
      attr_value_id,
      mall_code,
    } = payload || {}
    const { dataMapOrder, dataMap } = props.dataMapCompose || {}
    let key = ''
    dataMapOrder?.forEach?.((id) => {
      if (id == attr_id) {
        key += attr_name + '__' + attr_value_id + ','
        return
      }
      const { attr_name: otherAttrName, attr_value_id: otherAttrValueId } = sizeInfoIndex.value?.[id] || {}
      if (otherAttrValueId) {
        key += otherAttrName + '__' + otherAttrValueId + ','
      }else {
        key += ','
      }
    })
    if (mall_code) {
      key += mall_code
    }
    if (props.realTimeSizeInfo?.realDataMap?.[key] !== undefined) {
      return +props.realTimeSizeInfo.realDataMap[key]
    }
    // 有值转Number
    return dataMap?.[key] && +dataMap?.[key]
  }
  const initSizeInfoIndex = ({ needSetSkuInfo, needSetSizeIndex } = {}) => {
    const skcSaleAttr = props.skcSaleAttr || []
    const skuList = props.skuList || []
    // TODO 备份信息感觉没必要了
    skcSaleAttr?.forEach?.((attr) => {
      if (
        !sizeInfoIndex.value?.[attr?.attr_id] 
        || (
          sizeInfoIndex.value?.[attr?.attr_id]?.attr_value_name 
          && handleChooseCheck({
            attr_id: attr?.attr_id,
            attr_name: attr?.attr_name,
            attr_value_id: sizeInfoIndex.value?.[attr?.attr_id]?.attr_value_id,
          }) === undefined
        )
      ) {
        sizeInfoIndex.value[attr?.attr_id] = {
          attr_name: attr?.attr_name,
          attr_value_name: '',
          attr_value_id: '',
        }
      }
    })
    // 再根据skuInfo去修改选中值
    if (needSetSkuInfo) {
      const skuInfo = skuList?.find?.((_) => _.sku_code == needSetSkuInfo)
      if (skuInfo) {
        skuInfo.sku_sale_attr.forEach((attr) => {
          sizeInfoIndex.value[attr?.attr_id] = {
            attr_name: attr?.attr_name,
            attr_value_name: attr?.attr_value_name,
            attr_value_id: attr?.attr_value_id,
          }
        })
        return
      }
    }
    if (Object.keys(needSetSizeIndex || {}).length > 0) {
      skcSaleAttr?.forEach?.((item) => {
        const subItem = item?.attr_value_list?.find(
          (_) => _.attr_value_id == needSetSizeIndex[item.attr_id]?.attr_value_id
        )
        if (subItem) {
          sizeInfoIndex.value[item?.attr_id] = {
            attr_name: item?.attr_name,
            ...subItem,
          }
        } else {
          sizeInfoIndex.value[item?.attr_id] = {
            attr_name: item?.attr_name,
            attr_value_name: '',
            attr_value_id: '',
          }
        }
      })
    }
    if (props.config?.showMultiLayer) {
      let data = {}
      if (skuList?.length === 1) {
        data = skuList[0]?.sku_sale_attr?.reduce?.((prev, item) => {
          prev[item.attr_id] = item.attr_value_id
          return prev
        }, {})
      }
      for (let i = 0; i < skcSaleAttr.length; i++) {
        let attr = null
        if (skcSaleAttr[i]?.attr_value_list?.length == 1) {
          attr = skcSaleAttr[i].attr_value_list[0]
        } else if (skuList.length === 1) {
          attr = skcSaleAttr[i]?.attr_value_list?.find(
            (_) => _.attr_value_id == data[skcSaleAttr[i].attr_id]
          )
        }
        if (attr) {
          sizeInfoIndex.value[skcSaleAttr[i].attr_id] = {
            ...sizeInfoIndex.value[skcSaleAttr[i].attr_id],
            attr_name: skcSaleAttr[i]?.attr_name,
            ...attr,
          }
        }
      }
    }
    isInit.value = true
  }
  const mallCheck = (str) => {
    const mallList = props.mallInfoList || []
    const curMallStock = handleChooseCheck({ 
      attr_id: -1, 
      attr_name: '',
      attr_value_id: '',
      mall_code: props.mallCode,
    })
    if (curMallStock === undefined) {
      const optionalMallCode = {}
      let hasStockMall = false
      let mallCode = ''
      const { dataMap } = props.dataMapCompose || {}
      for (let k in dataMap) {
        if (k && k.indexOf(str) > -1) {
          const attr = k.split(',')
          if (dataMap[k] > 0) {
            hasStockMall = true
          }
          optionalMallCode[attr[attr.length - 1] || ''] = dataMap[k]
        }
      }
      // mallList的优先级是降序的
      for (let i = 0; i < mallList.length; i++) {
        const mall_code = mallList[i]?.mall_code
        // 优先找有库存的mall
        if (hasStockMall) {
          if (optionalMallCode[mall_code]) {
            mallCode = mall_code
            break
          }
        } else {
          // 所有mall都没库存，选取优先级最高的那个mall
          if (optionalMallCode[mall_code] == 0) {
            mallCode = mall_code
            break
          }
        }
      }
      if (typeof window !== 'undefined' && props.mallCode) {
        const mall = mallList?.find?.((_) => _.mall_code == props.mallCode)
        instance.proxy.$toast(
          template(
            mall?.mall_name || '',
            props.language?.SHEIN_KEY_PWA_21064 || '',
          ),
          1500
        )
      }
      const mallStock = handleChooseCheck({
        attr_id: -1, 
        attr_name: '',
        attr_value_id: '',
        mall_code: mallCode,
      })
      props?.mallClick?.({
        mallCode,
        mallStock,
      })
      return mallStock
    }
    return curMallStock
  }
  const sizeEmit = ({ item = {}, needSetSkuInfo, needSetSizeIndex }) => {
    if (!isInit.value || needSetSkuInfo || needSetSizeIndex) {
      initSizeInfoIndex({ needSetSkuInfo, needSetSizeIndex })
    }
    let str = ''
    let attrs = []
    let skuInfo = {}
    const { dataMapCompose, skcSaleAttr, skuList } = props || {}
    const { dataMapOrder, skuMap } = dataMapCompose || {}
    dataMapOrder?.forEach?.((id) => {
      const sizeInfo = sizeInfoIndex.value?.[id] || {}
      const { attr_name, attr_value_id } = sizeInfo
      attrs.push({ attr_id: id, ...sizeInfo })
      str += (attr_value_id ? (attr_name + '__' + attr_value_id + ',') : ',')
    })
    const mallStock = mallCheck(str)
    // TODO: 感觉备份没必要了
    // if (isUserSelect) {
    //   // 用户的最后一次选择备份一下
    //   this.sizeInfoIndexBak = JSON.stringify(this.sizeInfoIndex)
    // }
    // 无销售属性且sku唯一
    // TODO: skc_name
    if (skuList?.length == 1 && skcSaleAttr?.length == 0) {
      skuInfo = skuList[0]
    }
    if (skuMap?.[str]) {
      skuInfo = skuList?.find?.(item => item.sku_code == skuMap[str]) || {}
    }
    // 实时sku信息更新
    if (skuInfo?.sku_code) {
      skuInfo = Object.assign(skuInfo, props.realTimeSizeInfo?.realSkuList?.find?.(item => item?.sku_code == skuInfo.sku_code) || {})
    }
    skuInfo?.sku_sale_attr?.forEach?.((item) => {
      if (typeof item !== 'object') return
      // 最终显示在页面的属性name
      item.sku_calc_name = handleCalcSize?.(item)?.renderText || item?.attr_value_name || ''
    })
    props.handleCompose?.(JSON.parse(JSON.stringify({
      attrs,
      skuInfo,
      mallStock,
      externalSizeInfoIndex: sizeInfoIndex.value,
      curSelectAttr: item,
    })))
  }
  const handleSizeClick = async (item, subItem) => {
    if (!isInit.value) {
      initSizeInfoIndex({})
    }
    const { attr_id, attr_name } = item || {}
    const { attr_value_id } = subItem || {}
    if (handleChooseCheck({ attr_id, attr_name, attr_value_id }) !== undefined) {
      if (sizeInfoIndex.value?.[attr_id]?.attr_value_id === attr_value_id) {
        sizeInfoIndex.value[attr_id] = {
          attr_name,
          attr_value_name: '',
          attr_value_id: '',
        }
      } else {
        sizeInfoIndex.value[attr_id] = {
          attr_name,
          ...(subItem || {}),
        }
      }
    }else {
      // 跟Luffy确认过，可以推全不可点击
      return
      // if (!props.config?.isNoSkuClick) return
      // for(let attr_id in sizeInfoIndex.value) {
      //   sizeInfoIndex.value[attr_id] = attr_id == item.attr_id ? {
      //     attr_name,
      //     ...(subItem || {}),
      //   } : {
      //     ...(sizeInfoIndex.value?.[attr_id] || {}),
      //     attr_value_name: '',
      //     attr_value_id: '',
      //   }
      // }
    }
    // 触发sizeInfoIndex更新
    sizeInfoIndex.value = { ...sizeInfoIndex.value }
    sizeEmit({ 
      item: {
        attr_id: item?.attr_id || '',
        ...(subItem || {}),
      }
    })
  }
  const defaultClick = (payload = {}) => {
    sizeEmit(payload)
  }
  const sizeValue = (size) => {
    const { attr_value_list, attr_name, attr_id } = props.sizeAttrInfo || {}
    if (attr_id != 87 || !size) return
    attr_value_list?.forEach?.((item) => {
      const { attr_value_name, attr_value_id } = item || {}
      if (
        attr_value_name === size 
        && 
        handleChooseCheck({
          attr_id,
          attr_name,
          attr_value_id,
        }) > 0
      ) {
        handleSizeClick({ attr_id, attr_name }, item)
      }
    })
  }
  const initStatus = ({ isNewSpu } = {}) => {
    isInit.value = false
    if (isNewSpu) {
      sizeInfoIndex.value = {}
    }
  }
  return {
    sizeInfoIndex,
    handleChooseCheck,
    handleSizeClick,
    defaultClick,
    initSizeInfoIndex,
    initStatus,
    sizeValue,
  }
}
