<template>
  <div
    :class="[
      'goods-size-group__wrapper',
      {
        'goods-size-group__relation-wrapper': isFoldScene
      }
    ]"
  >
    <div
      aria-hidden="true"
      class="goods-size-group__title"
      @click="controlSlideExpandStatus"
    >
      <div
        :class="[
          'goods-size__title-txt',
          {
            // 'goods-size__title-bold': from === 'detail',
            'goods-size-group__title-txt': isFoldScene,
          }
        ]"
      >
        {{ title }}
      </div>
      <span 
        v-if="isFoldScene"
        v-expose="{
          id: '1-6-1-196',
          data: {
            location,
            goods_id: goodsId,
          },
        }"
        class="more-icon"
      >
        <Icon 
          v-show="!globalPerfectFitShow"
          name="sui_icon_more_down_12px" 
          color="#222"
          size="14px" 
        />
        <Icon 
          v-show="globalPerfectFitShow"
          name="sui_icon_more_up_12px"
          color="#222"
          size="14px" 
        />
      </span>
    </div>
    <components 
      :is="isFoldScene ? SSlide : 'div'"
      :visible="globalPerfectFitShow"
    >
      <div 
        v-expose="getAnalysisData({ type: 'expose' })"
        :class="{
          'goods-size-group__content': true,
          'perfect-fit': isFoldScene,
        }"
      >
        <ul class="goods-size__sizes choose-size">
          <li 
            v-for="item in groups"
            :key="item.goodsId"
            v-tap="getAnalysisData({ type: 'click', item })"
            :class="{
              'goods-size__sizes-item': true,
              'size-active': item.isCurrentGroup == '1',
            }"
            type="text"
            @click="clickSizeGroupItem(item)"
          >
            <span class="goods-size__sizes-item-text">
              {{ item.displayDesc }}
            </span>
            <i 
              v-if="isAfterSize || isFoldScene"
              class="suiiconfont sui_icon_more_right_12px_2"
            ></i>
          </li>
        </ul>
      </div>
    </components>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'

import { Slide as SSlide } from '@shein/sui-mobile'
import { Icon } from '@shein-aidc/icon-vue2'

import { daEventCenter } from 'public/src/services/eventCenter/index'

const daEventExpose = daEventCenter.getExposeInstance()
const props = defineProps({
  exposeCodeMap: {
    type: Object,
    default: () => ({})
  },
  saleAttrGroups: {
    type: Object,
    default: () => ({}) 
  },
  language: {
    type: Object,
    default: () => ({})
  },
  sizeGroupAbt: {
    type: String,
    default: ''
  },
  goodsId: {
    type: String,
    default: ''
  },
  isFromDetailPage: {
    type: Boolean,
    default: false
  },
  globalPerfectFitShow: {
    type: Boolean,
    default: false
  },
  handleUpdateGlobalPerfectFitShow: {
    type: Function,
    default: () => {}
  },
  handleClickSizeGroupItem: {
    type: Function,
    default: () => {}
  }
})
const location = props.isFromDetailPage ? 'page' : 'popup'
const exposeCode = props.exposeCodeMap?.sizeGroupList || ''
const isFoldScene = computed(() => props.sizeGroupAbt === 'fold')
const isAfterSize = computed(() => props.sizeGroupAbt === 'size')
const title = computed(() => isFoldScene.value ? props.language?.SHEIN_KEY_PWA_30032 : props.saleAttrGroups?.title || '')
const groups = computed(() => {
  if (isFoldScene.value || isAfterSize.value) {
    return props.saleAttrGroups?.groups?.filter(item => item.isCurrentGroup !== '1') || []
  }
  return props.saleAttrGroups?.groups || []
})
const controlSlideExpandStatus = () => {
  if (!isFoldScene.value) return
  const target = !props.globalPerfectFitShow
  props.handleUpdateGlobalPerfectFitShow?.(target)
  if(target) { // 收起时重置曝光
    daEventExpose.reset(exposeCode)
  }
  daEventCenter.triggerNotice({
    daId: '1-6-1-197',
    bindData: {
      goods_id: props.goodsId,
      click_type: target ? 1 : 0,
      location: props.isFromDetailPage ? 'page' : 'popup',
    }
  })
}
const getAnalysisData = ({ type = 'click', item }) => {
  let goods_size_list = '' // 拼接规则 goods_id`size_group`theme_type
  if(item) {
    const { theme_type = '', displayDesc = '', goodsId = '' } = item
    goods_size_list = `${goodsId}\`${displayDesc}\`${theme_type || ''}`
  } else {
    goods_size_list = groups.value?.map?.(item => `${item.goodsId}\`${item.displayDesc}\`${item.theme_type || ''}`).join(',')
  }
  const size_group_style = isAfterSize.value ? 'size_attr' : (isFoldScene.value ? 'find_your_fit' : 'size_group')
  const data = {
    location,
    source_goods_id: props.goodsId,
    size_group_style,
    goods_size_list
  }
  return {
    id: type === 'click' ? '1-6-1-185' : '1-6-1-184',
    data,
    code: exposeCode
  }
}
const clickSizeGroupItem = item => {
  props.handleClickSizeGroupItem?.({
    ...item,
    openQuickCart: isFoldScene.value || isAfterSize.value,
  })
}
</script>

<style lang="less">
.goods-size-group {
  &__wrapper {
    margin-bottom: 0.1rem;
    .size-group-desc{
      margin-left: 0.106rem
    }
  }
  &__relation-wrapper {
    margin-bottom: 0.2133rem;
  }
  &__title {
    display: flex;
    align-items: center;
    // height: 0.43rem;
    line-height: 0.43rem;
    .more-icon{
        margin-left: 0.0533rem;
    }
  }
  &__title-txt {
    color: @sui_color_gray_dark1;
    .font-dpr(28px) !important;/* stylelint-disable-line declaration-no-important */
  }
  &__content {
    margin-top: 0.15rem;
    .goods-size__sizes-item {
      display: flex;
      max-width: 46%;
    }
    .goods-size__sizes-item-text {
      .text-overflow();
    }
  }
}
.perfect-fit {
  .goods-size__sizes-item {
    display: flex;
    max-width: 46%;
  }
  .goods-size__sizes-item-text {
    .text-overflow();
  }
}
</style>
