/**
 * 只负责导出default的模块
 * 只负责导出default的模块
 * 只负责导出default的模块
 * */ 

class ControlAsync {
  constructor({ file }) {
    if (!file) throw new Error('file is required')
    this.fileImport = file
    this.queue = []
    this.status = false
  }

  run() {
    return () => {
      if (this.result) return Promise.resolve(this.result)

      // 主要返回当前加载的文件, 如果没有加载完成, 则返回一个 Promise， push到队列中, 等待加载完成后再执行
      if (this.status) {
        return new Promise((resolve, reject) => {
          this.queue.push({
            resolve,
            reject
          })
        })
      }
      this.status = true
      return new Promise((resolve) => {
        this.fileImport().then(({ default: res }) => {
          this.result = res
          resolve(res)
          this.queue.forEach((item) => {
            item.resolve(res)
          })
          this.status = false
        }) 
      })
    }
  }
}

export default ControlAsync
