import { parseQueryString } from '@shein/common-function'

export function getAod () {
  let aod_id = '0'
  const { ici = '' } = parseQueryString(location.search) || {}
  if (ici) {
    const iciSubArr = ici.split('_')
    for (let i = 0; i < iciSubArr.length; i++) {
      if (iciSubArr[i].indexOf('aod') > -1) {
        aod_id = iciSubArr[i].split('=')[1] || '0'
      }
    }
  }
  return aod_id
}
