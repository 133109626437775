<script lang="jsx">
/**
 * 商详页固定窗口底部加车按钮栏
 */
import ClientOnly from 'vue-client-only'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter'
import { template, parseQueryString } from '@shein/common-function'
import StoreBtn from './StoreBtn'
import WishBtn from './WishBtn'
import SoldOutBtn from './SoldOutBtn'
import NormalBtnBox from './NormalBtnBox'
import { getUserAbtData, asyncAbtUserAnalysis } from '@shein-aidc/basis-abt-router'
import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import { Icon } from '@shein-aidc/icon-vue2'
import { markPoint } from 'public/src/services/mark/index.js'
import AddPopAnimation from './AddPopAnimation.vue'
import EstimatedNoThresholdNewWarper from './EstimatedNoThresholdNewWarper'
import recommendExposureListInstance from 'public/src/pages/goods_detail/utils/recommendExposureList.js'
import AddOnItem from 'public/src/pages/common/add-on/index.js'
import CommonCart from '@/public/src/pages/components/common-cart/index.vue'
import pageContextCallBack from 'public/src/pages/goods_detail/utils/pageContextCallBack.js'

const { IS_RW, langPath, SiteUID, IS_EUR_DATA_CENTER } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '1-7-1' })
daEventCenter.addSubscriber({ modulecode: '1-8-1' })
daEventCenter.addSubscriber({ modulecode: '1-10-2' })

export default {
  name: 'AddToCartBar',
  components: {
    Icon,
    ClientOnly,
    StoreBtn,
    WishBtn,
    SoldOutBtn,
    NormalBtnBox,
    AddPopAnimation,
    OneClickPayBtn: () =>
      import(
        /* webpackChunkName: "OneClickPayBtn" */ 'public/src/pages/goods_detail/components/top/innerComponents/OneClickPay/OneClickPayBtn.vue'
      ),
    AddPop: () =>
      import(
        /* webpackChunkName: "AddPop" */
        'public/src/pages/goods_detail/components/drawer/innerComponents/AddPop/index.vue'
      ),
    FindSimilarFloatLayer: () =>
      import(
        /* webpackChunkName: "FindSimilarFloatLayer" */ 'public/src/pages/goods_detail/components/drawer/innerComponents/FindSimilarFloatLayer'
      ),
    AtmosphereSwiper: () =>
      import(
        /* webpackChunkName: "AtmosphereSwiper" */ 'public/src/pages/goods_detail/top/AddBag/AtmosphereSwiper'
      ),
    EstimatedNoThresholdNewWarper: () =>
      import(
        /* webpackChunkName: "EstimatedNoThresholdNewWarper" */ './EstimatedNoThresholdNewWarper'
      )
  },
  props: {
    isReview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      awaitAddBag: false,
      complete: false,
      curExtraBusesData: {}, // 当前色块数据
      curExtraMallData: {}, // 选择的mall，关闭弹窗的时候同步到详情页
      completeQueue: [],
      abtType: '',
      // showPrice: this.isReview,
      showPrice: true,
      showSizeRecommend: false,
      cacheScroll: 0, // 缓存 滚动条高度 修复 IOS 弹窗会触发滚动 BUG
      addPopIsReady: false,
      skuUnWatch: () => {}, // sku卸载监听事件
      overMainImage: false,

      // abt 曝光数据
      // TODO-perf 可在商详页主链路或首屏初始化时一同进行，公共存储
      quickShowSa: '',
      // 评论页加车成功的商品是主商品
      isReviewMainGoods: false,
      changeCardShareIconAbt: false,
      giftInfo: {},
    }
  },
  computed: {
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapState('productDetail/gift', ['gift_list', 'gift_selected_list', 'gift_lowest_price', 'gift_selected_prices']),
    ...mapState('newProductDetail/common', [
      'colorsReady',
      'fixedRatio',
      'externalSizeInfoIndex',
      'addToBagForm',
      'skuInfo',
      'addToBagFailMsg',
      'promotionLimitType',
      'oneClickPayState',
      'quickAddState',
      'addCompleteStatus',
      'addToBagFail',
      'addToBagSuc',
      'addToBagLoading',
      'addToBagCompletedImg',
      'addLoadingAttachTop',
      'exposeGoods',
      'initExposeStatus',
      'goodsReady',
      'findSimilarFloatInfo',
      'quickAddLargeImgMode',
      'syncAddCartColorSwitch',
      'commentQuickAddState',
      'buyNowQuickAddState',
      'attrsFoldQuickAddState',
      'buyMultipleQuickAddState',
      'buyMultipleQuickAddFrom',
      'quickShip',
      'isPlayVimeo',
    ]),
    ...mapState('newProductDetail/SizeBox', ['globalPerfectFitShow']),
    ...mapGetters('productDetail/price', ['estimated_info']),
    ...mapGetters('newProductDetail', ['closeEstimatedAndAbPrice']),
    ...mapGetters('newProductDetail/common', [
      'forbidAtmosphereFlow',
      'addBtnAtmosphereInfo',
      'showMallSite',
      'productImgs',
      'ruleId',
      'saleAttrsFoldAb',
      'saleAttrsFoldQuickPop',
      'getEstimatedInfo',
      'promotionInfo',
      'language',
      'productItemsLanguage',
      'showMultiLayer',
      'isSoldOut',
      'mallCode',
      'showSizeGuide',
      'mallStock',
      'saleAttrList',
      'isCustomization',
      'isCustomizationNewLink',
      'price',
      'retentionPositionConfig',
      'unListed',
      'isNewSizeLocal',
      'detail',
      'relationProducts',
      'defaultMallCode',
      'pageCommonInfo',
      'showAddBagRecDrawerScene',
      'trendsInfo',
      'currentMall',
      'isAddWishAfterRec',
    ]),
    ...mapGetters('newProductDetail/SizeGuide', ['ruleType']),
    ...mapGetters('newProductDetail/Price', [
      'estimatedInfo',
    ]),
    ...mapGetters('newProductDetail', ['fsAbt', 'getPrice']), // 新数据流
    ...mapGetters('newProductDetail/ColorBox', ['cccSupportLargeImageList']),
    ...mapGetters('newProductDetail/SizeBox', ['recommendMySize', 'recommendMyBraSize']),
    ...mapState('newProductDetail/CommentPopup', ['viewComment']),
    ...mapGetters('newProductDetail/ShippingEnter', [
      'freeShippingInfo',
    ]),
    ...mapGetters('newProductDetail/TopOther', ['showStoreBtn']),
    // 栏位框class
    barDomClass() {
      const { showPriceModuleAbt } = this
      return {
        'beauty-color-add': false,
        'show-price': !!showPriceModuleAbt,
      }
    },
    // 联合售罄状态
    equalSoldOut() {
      const { isSoldOut, mallStock } = this
      return isSoldOut || mallStock === 0
    },
    // 商品订阅状态
    // TODO-perf 应由vuex接管计算
    subscriptionStatus() {
      const { detail } = this
      return String(detail.is_subscription) === '1'
    },
    originImage() {
      return this.productImgs && this.productImgs[0] && this.productImgs[0].origin_image
    },
    // 有需要展示addpop的依赖时, addpop进行初始化且色块ready
    addPopReady() {
      const { addToBagSuc, addToBagFail, addToBagLoading, addToBagCompletedImg } = this
      return (
        (addToBagSuc ||
          addToBagFail ||
          addToBagLoading ||
          addToBagCompletedImg ||
          this.promotionLimitType == 2) &&
        this.colorsReady
      )
    },
    // 是否展示低库存加车提示
    showLowStockAddCartTips () {
      return this.fsAbt?.outofstocktips?.param?.addtocarttoastlowstocktips === 'on'
    },
    showAtmosphere() {
      const { equalSoldOut, subscriptionStatus, fsAbt, overMainImage, addBtnAtmosphereInfo, showFreeShipping, forbidAtmosphereFlow } =
        this
      if (equalSoldOut || subscriptionStatus) return false
      const { hotnews } = fsAbt || {}
      const { whereshow, hotnews: hotnewsParam } = hotnews?.param || {}
      if (!hotnewsParam) return false
      const showBtnSwiper = (whereshow === 'andaddtobag' || whereshow === 'onlyaddtobag') && !forbidAtmosphereFlow
      return !!(showBtnSwiper && (showFreeShipping || true) && addBtnAtmosphereInfo?.length && this.goodsReady)
    },
    showFreeShipping() {
      return this.freeShippingInfo?.freeShippingStyle?.isButton || false
    },
    // 是否是一口价生效商品
    isUnderPrice() {
      return (
        !!this.getPrice?.suitRulePrice && this.fsAbt?.UnderPrice?.param?.UnderPriceShow === 'A'
      )
    },
    // 浮动栏内价格模块abt
    // TODO-perf 应由vuex接管计算
    showPriceModuleAbt() {
      const { equalSoldOut, showOneClickPayBtn } = this
      // 不显示场景 售罄、下架、一键购
      if (equalSoldOut || showOneClickPayBtn || IS_RW) {
        return ''
      }
      const quickShow = 'B' // 推全
      // const quickShow = fsAbt?.QuickShow?.p?.QuickShow
      if (quickShow === 'NO') return ''
      return quickShow
    },
    // 是否展示悬浮购物车图标
    showFloatCartIcon() {
      return this.fsAbt?.hovercart?.p?.hovercart === 'show'
    },
    // 是否展示顶部购物车图标
    showTopCartIcon() {
      return this.fsAbt?.hovercart?.p?.oldcart !== 'none'
    },
    cartConfig() {
      const { adddynamic, collectandaddcart } = this.fsAbt?.collectandaddcart?.p || {}
      return {
        cartPosition: collectandaddcart === 'A' ? 'bottom' : 'top',
        cartDynamicStyle: adddynamic === 'A' ? 'img' : 'reddot'
      }
    },
    // 是否展示加购按钮底部价格
    showBottomPrice() {
      const { showPriceModuleAbt } = this
      return showPriceModuleAbt === 'B'
    },
    showRetentionBelt() {
      const { position = '', show } = this.retentionPositionConfig
      return show && position == 'bottom'
    },
    showSimilar() {
      return this.isSoldOut && !this.unListed
    },
    // 顶部售罄浮层是否展示
    soldOutTipShow() {
      const { equalSoldOut, findSimilarFloatInfo } = this
      if (!equalSoldOut) return false
      return !!findSimilarFloatInfo?.show
    },
    // 售罄状态按钮使用特殊样式
    soldOutBtnUseSpecificStyle() {
      const { unListed, soldOutTipShow, mallStock, subscriptionStatus } = this
      // 去除未发布、浮动找相似、无库存等状态
      if (unListed || soldOutTipShow || mallStock === 0) {
        return false
      }
      // 仅为售罄状态
      return subscriptionStatus
    },
    soldOutTip() {
      return this.showMallSite && this.mallStock === 0 && !this.isSoldOut
        ? this.template(
          this.detail.mall[this.mallCode]?.mall_name,
          this.language.SHEIN_KEY_PWA_20858
        )
        : this.language.SHEIN_KEY_PWA_16794
    },
    // 价格是否使用折扣样式
    priceUseDiscountStyle() {
      const { retentionPositionConfig, isUnderPrice } = this
      return retentionPositionConfig.show || isUnderPrice
    },
    quantity() {
      const { addToBagForm } = this
      return addToBagForm?.quantity || 1
    },
    // 是否支持一键购按钮
    supportOneClickPay() {
      const { isSoldOut, mallStock, oneClickPayState } = this
      if (isSoldOut || mallStock === 0) return false
      // if (isSoldOut || mallStock === 0) return false
      return oneClickPayState.support
    },
    // 是否展示一键购按钮
    showOneClickPayBtn() {
      const { supportOneClickPay, oneClickPayState, skuInfo, price, detail, mallCode } = this
      if (this.isCustomization) return false
      // skc 层面的支持
      if (!supportOneClickPay) return false

      // 若存在 sku 且进行了选择，则需要比对 sku 的价格以及该 sku 在一键购信息中的库存
      const { sku_code = '' } = skuInfo
      const { billInfo } = oneClickPayState || {}
      const { can_add_purchase_price: limitPrice, skc_inventory_list: inventoryList } =
        billInfo || {}
      // 一键购权限信息未精确到 sku 层面，则直接展示
      if (!limitPrice || !Array.isArray(inventoryList)) {
        return true
      }

      const limitPriceNum = Number(limitPrice) || 0
      // 未选择尺码，只检测价格
      if (!sku_code) {
        const skcPrice = detail?.mall?.[mallCode]?.salePrice?.usdAmount
        const skcPriceNum = Number(skcPrice) || 0
        if (skcPriceNum && skcPriceNum > limitPriceNum) {
          return false
        }
        return true
      }

      const { inventory_list } = inventoryList[0] || {}
      if (!Array.isArray(inventory_list)) return true
      // 取出匹配当前 sku 的一键购单元，没有匹配认为不支持
      const currentSku = inventory_list.find((item) => item.sku === sku_code)
      if (!currentSku) return false
      const { is_have_inventory = 0 } = currentSku
      // 库存检测
      if (!is_have_inventory) {
        return false
      }
      // 价格检测
      const currentSkuPrice = price?.salePrice?.usdAmount
      const currentSkuPriceNum = Number(currentSkuPrice) || 0
      if (currentSkuPriceNum && currentSkuPriceNum > limitPriceNum) {
        return false
      }

      return true
    },
    // 一键购订单信息
    oneClickPayBillInfo() {
      const { oneClickPayState } = this
      return oneClickPayState.billInfo
    },
    // 一键购下单请求额外参数
    extraOneClickPayParams() {
      const { detail, mallCode, skuInfo } = this
      const currentMall = detail?.mall?.[mallCode]
      return {
        sku_code: skuInfo.sku_code || '',
        mall_code: mallCode,
        amount: currentMall?.salePrice?.amount || '',
      }
    },
    // 一键购埋点数据
    oneClickPayAnalysisData() {
      if (typeof window === 'undefined') {
        return null
      }
      const upperBillNo = parseQueryString(location.search)?.billno
      return {
        location: 'page',
        order_no: upperBillNo || '',
        activity_from: upperBillNo ? 'one_tap_pay' : '',
      }
    },
    mainSaleText() {
      // 当前销售信息
      return this.detail?.mainSaleAttribute?.[0]?.attr_value || ''
    },
    optimalCoupon() {
      let couponList = this.getEstimatedInfo?.coupons
      let bestCoupon = couponList?.find(item => {
        return item.coupon_code === this.getEstimatedInfo?.optimalCoupon?.coupon_code
      })
      return bestCoupon
        ? {
          ...this.getEstimatedInfo?.optimalCoupon,
          ...bestCoupon,
          rule: this.getEstimatedInfo?.optimalCoupon?.rule
        }
        : {}
    },
    optimalCouponCode() {
      return this.optimalCoupon?.coupon_code
    },
  },
  watch: {
    addPopReady(val) {
      if (!this.addPopIsReady && val) {
        this.addPopIsReady = true
      }
    },
    showSimilar: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSimilarEntry()
        }
      },
    },
    recommendMySize(val) {
      if (val && this.handleDrawState) {
        this.$quickAdd.changeStatus('recommendMySize', val)
      }
    },
    'quickAddState': function (isOpen) {
      if (!isOpen) return
      if (!this.isReview) {
        this.$nextTick(() => {
          this.handleOpenQuick()
        })
      }
    },
    'commentQuickAddState': function (isOpen) {
      if (!isOpen || !this.isReview) return
      location.hash += '/comment-quick-add'
      this.handleOpenQuick()
    },
    buyNowQuickAddState(isOpen) {
      if (!isOpen) return
      this.handleOpenQuick({ isBuyNow: true })
    },
    attrsFoldQuickAddState(isOpen) {
      if (!isOpen) return
      this.handleOpenQuick({
        isAttrFold: '1',
      })
    },
    buyMultipleQuickAddState(isOpen) {
      if (!isOpen) return 
      let buyMultipleQuantity = 1
      if(this.MAIN_BFF_APOLLO.v3) {
        buyMultipleQuantity = this.estimated_info?.purchasePcs
      } else {
        buyMultipleQuantity = this.getEstimatedInfo?.buyMultiplePic
      }
      this.handleOpenQuick({
        buyMultipleQuantity
      })
    },
    async viewComment(val) {
      // 评论弹窗关闭的时候
      if (val) return
      this.closePopoverAddBag()
      if (this.complete || this.addCompleteStatus) {
        // 加购成功了
        this.isReviewMainGoods = false
        this.complete = false
        // this.changePageStatus({ addCompleteStatus: false })
        this.updateAddCompleteStatus(false)
        this.addSuccessCallBack({}, true)
      }
    },
    goodsReady(newVal) {
      if (newVal && this.awaitAddBag) {
        this.changeAddBag()
      }
    },
    'detail.goods_id': {
      immediate: true,
      async handler(newValue, oldValue) {
        // this.scrollHandle()
        // 初始状态
        // this.showPrice = false
        this.overMainImage = false
        // 定制按钮发曝光
        if (typeof window !== 'undefined' && this.isCustomization) {
          daEventCenter.triggerNotice({
            daId: '1-6-1-167',
            bindData: [{ activity_from: 'main' }],
          })
        }
        if (typeof window !== 'undefined' && !IS_RW && !this.isReview && newValue !== oldValue) {
          // 销毁之前的sku监听事件
          this.skuUnWatch?.()
          this.mallUnWatch?.()
          const { skucode = '' } = parseQueryString?.(window?.location.search) || {}
          if (!oldValue && skucode && !this.skuInfo?.sku_code) {
            await new Promise((resolve) => {
              this.skuUnWatch?.()
              this.skuUnWatch = this.$watch('skuInfo', (skuNewVal, skuOldVal) => {
                if (skuNewVal?.sku_code !== skuOldVal?.sku_code) {
                  this.skuUnWatch?.()
                  resolve?.()
                }
              })
            })
          }
          this.updateFindSimilarFloat?.({
            from: !oldValue ? 'pageInit' : 'skcChange'
          })
          // 注册sku变化监听
          this.$nextTick(() => {
            this.skuUnWatch = this.$watch('skuInfo', (newVal, oldVal) => {
              if (newVal?.sku_code !== oldVal?.sku_code) {
                this.updateFindSimilarFloat?.({
                  // sku无值走skcChange
                  from: newVal?.sku_code ? 'skuChange' : 'skcChange',
                })
              }
            })
            this.mallUnWatch = this.$watch('mallCode', (newVal, oldVal) => {
              if (newVal !== oldVal) {
                this.updateFindSimilarFloat?.({ from: 'skuChange' })
              }
            })
          })
        }
      },
    },
  },
  mounted() {
    this.getSellingattriAbt()
    this.initExpose()
    if (!this.isReview) {
      $(window).on('scroll', this.scrollHandle)
    }
  },
  destroyed() {
    if (!this.isReview) {
      $(window).off('scroll', this.scrollHandle)
    }
  },
  methods: {
    ...mapMutations('productDetail/gift', ['update_gift_selected_list', 'update_gift_selected_prices', 'update_quick_add_has_gifts']),
    ...mapActions('productDetail/gift', ['batch_gift_add_to_bag', 'get_selected_gift_prices']),
    ...mapActions('newProductDetail', ['autoGetCouponAtAddBag']),
    ...mapMutations('newProductDetail/common', [
      'updateOneClickPayState',
      'updatePromotionLimitType',
      'updateCurrentLocalCountry',
      'updateExternalSizeInfoIndex',
      'updateAddToBagForm',
      'updateSkuInfo',
      'updateQuickAddState',
      'updateBuyMultipleQuickAddState',
      'updateAddCompleteStatus',
      'updateAddSkcCompleteStatus',
      'updateAsyncScrollStatus',
      'updateQuickAddLargeImgMode',
      'updateCommentQuickAddState',
      'updateAsyncScrollFromReview',
      'updateAttrsFoldQuickAddState',
      'updateBuyNowQuickAddState'
    ]), // 新的
    ...mapMutations('newProductDetail/SizeBox', [
      'updateSwitchMallCode',
      'updateGlobalPerfectFitShow'
    ]),
    ...mapMutations('newProductDetail/common', ['updateSaveStatus', 'updateNotifyMe']),
    ...mapMutations('newProductDetail/PromotionEnter', ['updateViewPromotionAddBag', 'updateViewPromotionStatus']),
    
    // ...mapActions('productDetail', [
    // 'setGoodsDetailScroll',
    // 'updateRecommendGoods',
    // 'updateExposeStatus',
    // 'updateFindSimilarFloat',
    // ]),
    ...mapActions('newProductDetail', [
      'addToBag',
      'openAddBagRecommendDrawer',
      'openDetailRecommendDrawer',
      'fetchRecDataActions',
      'openSizeRecommendPop',
      'updateRecommendGoods',
    ]),
    ...mapActions('newProductDetail/common', [
      'updateExposeStatus',
      'updateFindSimilarFloat',
      'setGoodsDetailScroll'
    ]),
    closeLimitTips() {
      this.updatePromotionLimitType(null)
    },
    onHandleSucTip() {
      this.addToBagSuc = true
      setTimeout(() => {
        this.addToBagSuc = false
      }, 2000)
    },
    goToBag() {
      sa('send', { activity_name: 'click_single_promotion_bag' })
      markPoint('toNextPageClick', 'public')
      this.$router.push(`${langPath}/cart`)
    },
    showNewSizeGuide() {
      if (this.saleAttrsFoldAb) return this.showSizeGuide
      return (
        this.showSizeGuide &&
        this.showSizeRecommend
      )
    },
    async getSellingattriAbt() {
      const { Sellingattri, collectandaddcart } = (await getUserAbtData()) || {}
      this.showSizeRecommend = Sellingattri?.param == 'type=1'
      this.changeCardShareIconAbt = collectandaddcart?.p?.collectandaddcart === 'A' // 调整购物车与收藏入口位置abt
    },
    // 初始化曝光信息
    // TODO-perf 可在商详页主链路或首屏初始化时一同进行，公共存储
    async initExpose() {
      const abtest = await asyncAbtUserAnalysis({ posKeys: 'QuickShow' })
      // const abtest = abtUserAnalysis({ posKeys: 'QuickShow' })?.sa
      this.quickShowSa = abtest?.sa
    },
    scrollHandle() {
      // 监听主图滚动距离
      const bannerDetail = document.querySelector('#banner-detail')
      if (bannerDetail?.getBoundingClientRect?.()?.top < -114) {
        this.overMainImage = true
      }
      // if (!this.showPriceModuleAbt) return this.showPrice = false
      // const priceDom = document?.querySelector('.goods-price-dom')
      // const headerDom = document?.querySelector('.j-common-header-dom')
      // if (!priceDom || !headerDom) return this.showPrice = false
      // const { top, bottom } = priceDom?.getBoundingClientRect() || {}
      // const { height: headerHeight } = headerDom?.getBoundingClientRect() || {}
      // this.showPrice = !((top <= window?.innerHeight && bottom >= headerHeight) || 0)
    },
    changeAddBag(target, interestpoints, btnType) {
      if (this.goodsReady) {
        this.awaitAddBag = false
        this.addToBag({
          interestpoints,
          target: this.$refs.NormalBtnBox?.$refs?.refAddBtn,
          isReview: this.isReview,
          btnType,
        })
      } else {
        this.awaitAddBag = true
      }
    },
    // 快加车
    handleOpenQuick(params) {
      const { isAttrFold = '0', isBuyNow, buyMultipleQuantity } = params || {}
      let drawerEle = document.querySelector('.estimated-experiment-area')
      const hasPromotionDrawer = drawerEle && window.getComputedStyle(drawerEle).display !== 'none'
      const { isReview, cccSupportLargeImageList, quantity, quickAddLargeImgMode, trendsInfo } = this
      // this.quickAddLargeImgMode 加车弹窗是否直接打开大图模式
      const reportData = hasPromotionDrawer
        ? {
          location: 'promotion_popup',
          threshold: this.getEstimatedInfo?.isAb ? 3 : (this.getEstimatedInfo?.optimalCoupon?.satisfied == 1 ? 1 : 2), // 满足门槛上报为 1 ，未满足门槛上报为2， ab 价上报3
        }
        : {}
      appEventCenter.$emit('allLazyComponentRender')
      this.curExtraBusesData = {}
      this.curExtraMallData = {}
      this.cacheScroll = window.scrollY
      this.giftInfo = {
        gift_list: this.gift_list,
        gift_selected_list: this.gift_selected_list,
        gift_lowest_price: this.gift_lowest_price,
        gift_selected_prices: this.gift_selected_prices,
        gift_list_loding: false,
        productItemsLanguage: this.productItemsLanguage,
      }
      // 打开快加车窗口
      let quickAddParam = {
        mainPageGoodsId: this.detail.goods_id,
        oneClickPayBillNo: parseQueryString(location.search)?.billno,
        goods_id: this.detail.goods_id,
        mallCode: this.mallCode,
        imgRatio: this.fixedRatio,
        selectedAttrIndex: this.externalSizeInfoIndex,
        isClickToDetail: false,
        isShowWishBtn: true,
        isShowAttrPlusSize: this.showMultiLayer,
        showSizeRecommend: this.showSizeRecommend,
        showNewSizeGuide: this.showNewSizeGuide(),
        saleAttrsFoldAb: this.saleAttrsFoldAb,
        ruleId: this.ruleId,
        ruleType: this.ruleType,
        recommendMyBraSize: this.recommendMyBraSize,
        recommendMySize: this.recommendMySize,
        addSource: 'detailPage',
        forbidAtmosphereFlow: this.forbidAtmosphereFlow,
        cccSupportLargeImageList,
        largeImageMode: quickAddLargeImgMode,
        quantity: Number(buyMultipleQuantity || quantity),
        showOneClickPay: true,
        showBestDealLabel: true,
        showFollowBeltByOrigin: true,
        showEstimatedPrice: !this.closeEstimatedAndAbPrice,
        showNEstimatedPrice: !this.closeEstimatedAndAbPrice,
        closeEstimatedAndAbPrice: this.closeEstimatedAndAbPrice,
        isFromPromotion: hasPromotionDrawer,
        needCartTagTipsUpdate: false,
        showNoSatisfied: true,
        globalPerfectFitShow: this.globalPerfectFitShow,
        isBuyNow,
        giftInfo: this.giftInfo,
        clickCallBack: {
          isActive: this.handleDrawState, // 快加车弹窗状态
          selectSizeDefault: (params) => {
            // 非用户选择的尺寸默认选中
            this.changeSizeAttrs(params)
          },
          // 尺寸选择
          selectSize: (params) => {
            this.changeSizeAttrs(params)
            // 在加车弹窗里切换了skc，需要延迟更新一下
            if (params?.curGoodsId && params?.curGoodsId !== this.detail?.goods_id) {
              pageContextCallBack.set('changeColorReady', () => {
                if (this.detail?.goods_id === params?.curGoodsId && params?.externalSizeInfoIndex) {
                  this.updateExternalSizeInfoIndex(params?.externalSizeInfoIndex)
                }
              })
            }else {
              pageContextCallBack.clear('changeColorReady')
            }
          },
          // 切换色块
          changeColor: (item = {}) => {
            if (this.syncAddCartColorSwitch) {
              this.curExtraBusesData = item
            }
          },
          // 切换mall
          mallClick: (mallInfo) => {
            this.curExtraMallData = mallInfo
          },
          // 加车成功弹窗推荐
          loadAddBagRecDrawer: async ({ data = {} }) => {
            const { showAddBagRecDrawerSceneOne, showAddBagRecDrawerSceneTwo } = this.showAddBagRecDrawerScene
            if (data?.addCartGoodsId === this.detail?.goods_id && (showAddBagRecDrawerSceneOne || showAddBagRecDrawerSceneTwo)) {
              // 时机一提前获取加车推荐数据
              await this.fetchRecDataActions()
              const status = await this.openAddBagRecommendDrawer()
              return status
            }
            return false
          },
          // 加车成功后的回调
          complete: async ({ data = {} }) => {
            this.updateAddCompleteStatus(true)
            this.updateAddSkcCompleteStatus(true)
            if (hasPromotionDrawer) {
              window.vBus && window.vBus.$emit('addToBagSuccess', { data })
            } else {
              this.autoGetCouponAtAddBag({ needToast: data?.code == 0 })
            }
            setTimeout(() => {
              window?._gb_cart_tag_tips_?.update({
                excludeScroll: true
              })
              window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
            }, 2000)
            window.scrollTo({ top: this.cacheScroll })
            if (!isReview) {
              if(!data?.loadAddBagRecDrawerStatus) {
                this.completeQueue.push(() => this.addSuccessCallBack(data))
              }
            } else {
              const { code } = data
              if (+code !== 0) return
              this.setBounce(true)
              this.complete = true
              this.isReviewMainGoods = data?.addCartGoodsId === this.detail?.goods_id
              history.go(-1)
            }
            if (data?.code == 0) {
              this.updateExposeStatus({
                addBagStatus: 1,
                addWishStatus: this.initExposeStatus?.addWishStatus || 0,
              })
              // 从大图模式点击到手价弹出时，点击加车关闭大图
              hasPromotionDrawer &&
                this.updateViewPromotionAddBag(this.viewPromotionAddBag + 1)
              setTimeout(() => { 
                // 包材料商品批量加车
                this.batch_gift_add_to_bag()
              }, 1500)
            }
          },
          // 收藏成功后是否显示add to group弹窗
          hookCheckAddToGroup: async ({ state, next }) => {
            if (!this.isAddWishAfterRec) {
              next()
              return
            }
            const status = await this.openDetailRecommendDrawer({ name: 'wish',
              extendConfig: {
                showAddBoardBtn: state.show,
              }
            })
            // 如果不展示推荐弹窗，走原逻辑
            if (!status) {
              next()
            } else {
              // 如果拉起弹窗，需要关闭快车保持单例
              appEventCenter.$emit('quickAddOpenAddOn')
            }
          },
          // 收藏状态
          toggleWishStatus: ({ wishStatus }) => {
            this.updateSaveStatus(wishStatus)
            this.updateExposeStatus({
              addWishStatus: wishStatus && 1,
              addBagStatus: this.initExposeStatus?.addBagStatus || 0,
            })
          },
          handlePerfectFitShowStatus: (status) => {
            this.updateGlobalPerfectFitShow(status)
          },
          toggleSizeRecommend: () => {
            this.openSizeRecommendPop()
          },
          clickClose: () => {
            this.clickCloseHandle()
          },
          handleChangeLocalCountry: (value) => {
            // 数据联动控制
            this.isNewSizeLocal && this.updateCurrentLocalCountry(value)
          },
          changeQuantity: (quantity = 1) => {
            this.updateAddToBagForm({
              ...this.addToBagForm,
              quantity,
            })
          },
          ...(hasPromotionDrawer || (this.getEstimatedInfo?.isSatisfiedBuyMultiple && this.buyMultipleQuickAddFrom === 1)
            ? {}
            : {
              clickEstimated: () => {
                if (JSON.stringify(this.curExtraBusesData) !== '{}') {
                  appEventCenter.$emit('close-popover-add-bag', this.curExtraBusesData)
                }
                this.clickEstimated()
                // this.updateViewPromotionStatus({
                //   status: true,
                //   isQuickAdd: true
                // })
              },
            }),
          handleSelectGiftList: async (data) => {
            this.giftInfo = data
          },
        },
        analysisConfig: {
          // 埋点数据
          from: 'main',
          review_location: isReview ? 'page_review' : '',
          isAttrFold,
          postData: {
            ...reportData,
          },
          sa: {
            ...(trendsInfo ? {
              passParams: {
                trend_tag: `${trendsInfo.trend_word_id}-${trendsInfo.product_select_id}`
              }
            } : {})
          }
        },
      }
      this.$quickAdd.open(quickAddParam)
      if (quickAddLargeImgMode) {
        this.updateQuickAddLargeImgMode(false)
      }
    },
    clickEstimated() {
      const clickjumpath = this.fsAbt?.collestrategy?.p?.clickjumpath || ''
      let { isSatisfied, isSatisfiedBuyMultiple } = this.getEstimatedInfo || {}

      if (clickjumpath !== 'gatherpopup' || isSatisfied || isSatisfiedBuyMultiple) {
        this.updateViewPromotionStatus({
          status: true,
          isQuickAdd: true
        })
        return
      }
      
      appEventCenter.$emit('quickAddOpenAddOn')
      const isTopGoods = this.fsAbt?.Gatheringorders?.p?.Gatheringorders === 'Topping'
      const isOpenRecommend = this.fsAbt?.EstimatedNothreShowType?.p?.recommendtype == '1'
      const queryInfo = {
        addOnType: 2, // 问产品拿
        sceneId: 152, // 推荐场景id
        cateIds: [this.detail?.cat_id]
      }
      if(isTopGoods) {
        // 置顶主skc
        queryInfo.adp = [this.detail?.goods_id]
      }else{
        queryInfo.goodsIds = [this.detail?.goods_id]
      }
      if(isOpenRecommend) {
        // 基于商详商品推出凑单商品
        queryInfo.mainGoodsIds = [this.detail?.goods_id]
      }
      // @@seb TODO ...mapActions('productDetail/common', ['add_on_item_open']),
      AddOnItem.open({
        props: {
          type: 'coupon',
          coupon: this.optimalCouponCode, // **券码** 必传！！！
          queryInfo,
          saInfo: {
            // 主要用于埋点
            activity_from: 'goods_detail_coupon_add',
            state: 'goods_detail_coupon_add'
          },
          config: {
            isClickToDetail: true,
          },
        },
        on: {
          close: () => {
            // if (!this.isQuickAdd) return
            appEventCenter.$emit('quickAddCloseAddOn')
          }
        }
      })
    },
    // 快加车弹窗加购成功回调
    addSuccessCallBack(data, isReviewBack) {
      const { cat_id, goods_id } = Object.assign({}, this.detail, this.curExtraBusesData)
      appEventCenter.$emit('recommend-refresh-data', { cat_id, goods_id }) // 刷新促销数据
      if (isReviewBack) {
        if (JSON.stringify(this.curExtraBusesData) !== '{}') {
          this.asyncGoodsDetailScroll(isReviewBack)
        } else {
          this.setGoodsDetailScroll({ isReview: isReviewBack }) // 滚动到 recommend
        }
      } else if (isReviewBack) {
        // 其他情况下 需要去除购物车动画
        this.setBounce(false)
      } else {
        if (JSON.stringify(this.curExtraBusesData) !== '{}') {
          this.asyncGoodsDetailScroll()
        } else {
          !isReviewBack && this.setGoodsDetailScroll()
        }
      }
    },
    asyncGoodsDetailScroll(isReviewBack) {
      this.updateAsyncScrollFromReview(!!isReviewBack)
      this.updateAsyncScrollStatus(true)
    },
    // 设置购物车动画
    setBounce(status) {
      const classHook = '.j-header-bag-is-review'
      const $headerBag = document.querySelector(classHook)
      $headerBag?.classList?.[status ? 'add' : 'remove']('bounce')
    },
    handleDrawState(isShow) {
      if (isShow) return
      const { isReview } = this // 评论页
      if (!isReview) {
        this.completeQueue?.forEach?.((_) => _())
        this.updateQuickAddState(false)
        this.updateBuyMultipleQuickAddState({ show: false })
        this.closePopoverAddBag()
        this.completeQueue.length && (this.completeQueue = [])
      } else {
        this.updateCommentQuickAddState(false)
      }
      this.updateAttrsFoldQuickAddState(false)
      // 立即购弹窗返回商详页，下滑至推荐位
      const afterbuyroadpop = this.fsAbt?.DetailBuynow?.p?.afterbuyroadpop === 'recommend'
      if(this.buyNowQuickAddState && afterbuyroadpop) {
        this.setGoodsDetailScroll()
      }
      this.updateBuyNowQuickAddState(false)
    },
    closePopoverAddBag() {
      if (JSON.stringify(this.curExtraMallData) !== '{}') {
        const { mallCode } = this.curExtraMallData
        this.updateSwitchMallCode(mallCode)
      }
      this.update_gift_selected_list(this.giftInfo.gift_selected_list)
      this.update_gift_selected_prices(this.giftInfo.gift_selected_prices)
      if (JSON.stringify(this.curExtraBusesData) !== '{}') {
        if(this.giftInfo.gift_selected_list.length) {
          // 快车选中gift包情况下，回到商详页面不需要重新请求gift列表
          this.update_quick_add_has_gifts(true)
        }else{
          this.update_quick_add_has_gifts(false)
        }
        // 色块切换
        appEventCenter.$emit('close-popover-add-bag', this.curExtraBusesData)
      }
    },
    // 快加车尺寸切换
    changeSizeAttrs({ attrs, skuInfo, externalSizeInfoIndex, curSelectAttr, isAttrFold }) {
      const addToBagForm = this.addToBagForm
      addToBagForm.attrs = attrs
      window.vBus &&
        window.vBus.$emit('changeWakeupData', {
          sku_code: skuInfo?.sku_code || '',
          selected_attr_value_list: attrs.map((_) => String(_.attr_value_id)) || [],
        })
      this.updateSkuInfo(skuInfo)
      this.updateAddToBagForm(addToBagForm)
      this.updateExternalSizeInfoIndex(externalSizeInfoIndex)
      if (curSelectAttr.attr_value_name) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-33',
          target: {
            dataset: {
              attr_id: curSelectAttr.attr_id,
              attr_value_id: curSelectAttr.attr_value_id || '',
              attr_value: curSelectAttr.attr_value_name,
              sku_code: skuInfo?.sku_code,
              review_location: this.isReview ? 'page_review' : '',
              location: 'popup',
              isAttrFold,
            },
          },
        })
      }
    },
    handleShowSimilar() {
      SimilarInstance.showModal(this.detail, {
        similarFrom: 'out_of_stock',
        getExposeTarget: async (params) => {
          if (this.exposeGoods.status) {
            const targetsGoods = await this.getExposeSimilarTargetHandle(params)
            recommendExposureListInstance.pushQueen({
              targets: targetsGoods,  
              isDomList: false,
            })
            return
          }
          recommendExposureListInstance.pushQueen({
            targets: params?.targets || [],
          })
        },
      })
      // 点击找相似 数据上报
      let { goods_id, goods_sn } = this.detail
      daEventCenter.triggerNotice({
        daId: '1-7-1-3',
        extraData: {
          sku: goods_sn,
          goods_id,
          activeFrom: 'detail',
          code: 'goodsDetail',
          isOutOfStock: 1,
        },
      })
    },
    // 获取 Similar 曝光
    getExposeSimilarTargetHandle({ targets }) {
      return this.updateRecommendGoods({ targets, type: 'similar' })
    },
    getSimilarEntry() {
      let { goods_id, goods_sn } = this.detail
      daEventCenter.triggerNotice({
        daId: '1-7-1-2',
        extraData: {
          sku: goods_sn,
          goods_id,
          activeFrom: 'detail',
          code: 'goodsDetail',
          isOutOfStock: 1,
        },
      })
    },
    /**
     * 缺货通知
     */
    clickNotifyMe() {
      this.updateNotifyMe(true)
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.updateNotifyMe(false)
      }, 500)
      // this.assignState({
      //   notifyMe: true,
      // })
      // setTimeout(() => {
      //   this.assignState({
      //     notifyMe: false,
      //   })
      // }, 500)
    },
    // 点击快加关闭按钮回掉
    clickCloseHandle() {
      const { isReview } = this
      if (!isReview) return
      history.go(-1) // 移除 hash
    },
    // 一键购按钮点击时的检测
    testBeforeOCP() {
      // TODO same as action.addToBag, should to be optimize
      const { detail, skuInfo, saleAttrList, saleAttrsFoldQuickPop } = this
      const hasAttrs = saleAttrList?.skcSaleAttr?.length
      let sku_code = hasAttrs ? skuInfo?.sku_code || '' : saleAttrList?.skuList?.[0]?.sku_code || ''
      if (saleAttrsFoldQuickPop || hasAttrs && !sku_code) {
        // 打开加车弹窗
        this.updateQuickAddState(true)
        // * HACK-oneClickPay 在此也会上报打开加车弹窗事件
        daEventCenter.triggerNotice({
          daId: '1-6-1-91',
          extraData: {
            goods_id: detail.goods_id,
            activity_from: 'main',
            button_type: 'one_tap_pay',
          },
        })
        return false
      }
      return true
    },
    // 一键购状态更新
    updateOCPStage() {
      const { oneClickPayState } = this
      const currentOCPStage = oneClickPayState?.stage
      this.updateOneClickPayState({
        ...oneClickPayState,
        stage: (currentOCPStage || 1) + 1,
      })
      // this.assignState({
      //   oneClickPayState: {
      //     ...oneClickPayState,
      //     stage: (currentOCPStage || 1) + 1,
      //   },
      // })
    },
    // 一键购成功
    async onOneClickPaySuccess(successInfo) {
      this.updateOCPStage()
      // * HACK-oneClickPay 在此也会上报打开加车弹窗事件
      if (typeof window === 'undefined') {
        return
      }
      let { detail, quickShip, defaultMallCode, saleAttrList, skuInfo, isCustomization, language, getEstimatedInfo, mallCode: _mallCode, isCustomizationNewLink } = this
      let mallCode = successInfo?.reqParams?.mall_code || _mallCode
      let variant = ''
      if (saleAttrList?.skcSaleAttr?.length) {
        skuInfo.sku_sale_attr.forEach(item => {
          if (item.attr_id == 87) {
            variant = item.attr_value_name
          }
        })
      }
      let is_customize = ''
      if (isCustomization) {
        const customerMade = await import(
          'public/src/pages/goods_detail/components/drawer/innerComponents/CustomeowDrawer/customerMade.js'
        )
        const customization_info = await customerMade?.default?.({
          sku: successInfo.reqParams.sku_code,
          isNewLink: isCustomizationNewLink,
          errorTip: language?.SHEIN_KEY_PWA_26887
        })
        if (!customization_info) return
        is_customize = customization_info
      }
      let { isAb, isAbCoexist, isSatisfied, isSatisfiedBuyMultiple } = getEstimatedInfo || {}
      let threshold
      if (getEstimatedInfo) {
        if (isAb) {
          threshold = 3
        } else if (isAbCoexist) {
          threshold = isSatisfied ? 4 : 5
        }  else if (isSatisfiedBuyMultiple) {
          threshold = 6
        } else {
          threshold = isSatisfied ? 1 : 2
        }
      }
      daEventCenter.triggerNotice({
        daId: '1-8-1-1',
        extraData: {
          code: 'goodsDetail',
          result: successInfo?.response || { code: 0 },
          postData: {
            sku: successInfo.reqParams.good_sn,
            spu: detail.productRelationID || detail.sku_id,
            quickShip,
            price: detail.mall[mallCode].salePrice.usdAmount,
            catId: detail.cat_id,
            variant,
            discount: detail.mall[mallCode].unit_discount,
            index: 0,
            amount: detail.mall[mallCode].salePrice.amount,
            businessModel: detail.business_model,
            storeCode: detail.store_code,
            mallCode: mallCode,
            is_default_mall: defaultMallCode == mallCode ? 1 : 0,
            mallTag: detail.mall[mallCode].mall_tags,
            location: 'page',
            image_tp: 'small',
            is_customize,
            ...(threshold ? { threshold: 3 } : {}),
            ...successInfo.reqParams
          },
          from: 'main',
          review_location: '',
          traceid: window.SaPageInfo?.page_param?.traceid,
          button_type: 'one_tap_pay',
        },
      })
    },
    // 一键购支付失败回调
    onOneClickPayFail() {
      this.updateOCPStage()
      // * HACK-oneClickPay 在此也会上报打开加车弹窗事件
      if (typeof window === 'undefined') {
        return
      }
      daEventCenter.triggerNotice({
        daId: '1-8-1-1',
        extraData: {
          code: 'goodsDetail',
          from: 'main',
          review_location: '',
          traceid: window.SaPageInfo?.page_param?.traceid,
          button_type: 'one_tap_pay',
        },
      })
    },
    // 一键购支付会话和交互结束
    onOneClickPayTransComplete(type) {
      // 支付失败，加车
      if (type === 'fail') {
        this.changeAddBag(null, '', 'one_tap_pay')
      }
    },
    // 跳转文章页
    jumpToArticle() {
      let articleId = '1925'
      if (IS_EUR_DATA_CENTER) {
        articleId = '1819'
      }
      if (['pwus', 'rwmus'].includes(SiteUID)) {
        articleId = '1898'
      }
      this.$router.push(`${langPath}/product/article/${articleId}`)
    },
    onScrollRecommend() {
      this.setGoodsDetailScroll()
    },
    template,

    /**
     * 渲染函数
     * TODO-perf vue2.7 + jsx 暂行的解决方案
     */
    // 人气氛围信息
    // * padding + absolute的方式占位
    // renderAtmosphereSwiper() {
    //   const { showAtmosphere, AtmosphereSwiperHeight } = this
    //   return (
    //     <div class="top-content">
    //       {showAtmosphere && (
    //         <AtmosphereSwiper style={{ height: `${AtmosphereSwiperHeight}rem` }} />
    //       )}
    //     </div>
    //   )
    // },
    // 定制吸底 售罄 > 定制 > 到手价 > 人气氛围 = 免邮 （加载定制上面有定制隐藏到手价，人气氛围保留款已经没了）
    renderTopContent() {
      if (this.renderSoldOutTip()) return null

      const { showAtmosphere, isCustomization, language, isReview, getEstimatedInfo, promotionInfo, estimatedInfo, showFreeShipping, pageCommonInfo, onScrollRecommend, fsAbt, estimated_info } = this
      const renderDom = () => {

        // 定制
        if (isCustomization && !isReview) {
          return <div class="customization-tip" vOn:click={this.jumpToArticle}>
            <div class="customization-tip__text">{ language?.SHEIN_KEY_PWA_26709 }</div>
            <Icon name="sui_icon_more_right_16px_2" size="16px" />
          </div>
        }

        // 到手价未满足门槛 new
        let { EstimatedNothreShowType, isSatisfied, thresholdValue, needPrice, hideNothreWithColldisplayrest } = getEstimatedInfo || {}
        if (['New1', 'New2'].includes(EstimatedNothreShowType) &&
          !isSatisfied &&
          !!thresholdValue?.amountWithSymbol &&
          !!needPrice &&
          !!estimatedInfo.estimatedCompProps &&
          !!estimatedInfo.estimatedCompProps.config &&
          estimatedInfo.hasEstimated &&
          !hideNothreWithColldisplayrest
        ) return <EstimatedNoThresholdNewWarper
          get-estimated-info={getEstimatedInfo}
          promotion-info={promotionInfo}
          language={language}
          detail={this.detail}
          has-link={true}
          vOn:onScrollRecommend={onScrollRecommend}
          fsAbt={fsAbt}
        />
        
        // 人气氛围 + 免邮
        if (showFreeShipping && showAtmosphere) return <div class="freeshippingWithAtmosphere">
          <div 
            class="freeshipping"
            {...{ directives: [{ name: 'expose', value: { id: '1-6-1-154', data: { freeshipping_location: 3 } } }] }}
          >
            <Icon name="sui_icon_free_shipping_12px" size="14px" is-rotate={pageCommonInfo.GB_cssRight_rp} color="#198055" />
            <p>{language.SHEIN_KEY_PWA_25388}</p>
          </div>
          <div class="freeshippingWithAtmosphere__vertical"></div>
          <AtmosphereSwiper style={{ height: '0.64rem', flex: '258' }} />
        </div>

        if (showFreeShipping) return <div
          class="freeshipping"
          style="transform: translateY(6px);"
          {...{ directives: [{ name: 'expose', value: { id: '1-6-1-154', data: { freeshipping_location: 3 } } }] }}
        >
          <Icon name="sui_icon_free_shipping_12px" size="14px" is-rotate={pageCommonInfo.GB_cssRight_rp} color="#198055" />
          <p>{language.SHEIN_KEY_PWA_25387}</p>
        </div>

        if (showAtmosphere) return <AtmosphereSwiper />

        return null
      }

      let domResult = renderDom()
      
      return domResult ? <div
        class="top-content"
      >
        {domResult}
      </div> : null
    },
    // 保留款
    // renderRetentionBelt() {
    //   const { showRetentionBelt, retentionPositionConfig, language } = this
    //   if (!showRetentionBelt || !retentionPositionConfig) return null
    //   return (
    //     <div class="belt-wrap">
    //       <RetentionBelt
    //         language={language}
    //         text={retentionPositionConfig.text}
    //         position={retentionPositionConfig.position}
    //       />
    //     </div>
    //   )
    // },
    // 售罄浮动标识
    renderSoldOutTip() {
      const {
        equalSoldOut,
        unListed,
        soldOutTip,
        findSimilarFloatInfo,
        mainSaleText,
        language,
        skuInfo,
        relationProducts,
        detail,
        quickAddState,
        fsAbt,
      } = this
      if (!equalSoldOut) return null
      if (unListed) return null

      const floatLayerShow = findSimilarFloatInfo.show
      if (!soldOutTip && !floatLayerShow) return null

      return (
        <p
          aria-hidden={true}
          class="soldout-warning goods-detial"
        >
          {soldOutTip}
          <ClientOnly>
            {!!floatLayerShow && (
              <FindSimilarFloatLayer
                update={floatLayerShow}
                loading={findSimilarFloatInfo.loading}
                products={findSimilarFloatInfo.products}
                from={findSimilarFloatInfo.from}
                filterSize={findSimilarFloatInfo.filterSize}
                hasMoreProducts={findSimilarFloatInfo.hasMoreProducts}
                mainSaleText={mainSaleText}
                language={language}
                skuInfo={skuInfo}
                relationProducts={relationProducts}
                detail={detail}
                detailQuickAddState={quickAddState}
                fsAbt={fsAbt}
              />
            )}
          </ClientOnly>
        </p>
      )
    },
    // 订阅按钮
    renderNotifyBtn() {
      const { clickNotifyMe, language } = this
      return (
        <button
          {...{ directives: [{ name: 'ada', value: { level: 0, pos: 0 } }] }}
          class="mshe-btn-black mshe-btn-block"
          onClick={clickNotifyMe}
        >
          {language.SHEIN_KEY_PWA_16012}
        </button>
      )
    },
    // 加车按钮
    renderAddCartBtn() {
      const {
        equalSoldOut,
        language,
        unListed,
        soldOutTipShow,
        isSoldOut,
        mallStock,
        soldOutBtnUseSpecificStyle,
        handleShowSimilar,
        subscriptionStatus,
        renderNotifyBtn,
        renderNormalBtn,
      } = this

      // 售罄状态按钮集合
      if (equalSoldOut) {
        return (
          <SoldOutBtn
            language={language}
            unlisted={unListed}
            showFindSimilar={soldOutTipShow}
            isSoldOut={isSoldOut}
            mallStock={mallStock}
            halfWidthStyle={soldOutBtnUseSpecificStyle}
            inverseStyle={soldOutBtnUseSpecificStyle}
            onShowSimilar={handleShowSimilar}
          />
        )
      }

      // 订阅按钮
      if (subscriptionStatus) {
        return renderNotifyBtn()
      }

      // 常规按钮
      return renderNormalBtn()
    },
    // 常规加车按钮
    renderNormalBtn() {
      const {
        showPriceModuleAbt,
        showPrice,
        supportOneClickPay,
        showOneClickPayBtn,
        changeAddBag,
        priceUseDiscountStyle,
        quickShowSa,
        oneClickPayBillInfo,
        detail,
        quantity,
        extraOneClickPayParams,
        testBeforeOCP,
        language,
        oneClickPayAnalysisData,
        onOneClickPaySuccess,
        onOneClickPayFail,
        onOneClickPayTransComplete,
        isCustomization,
      } = this

      return (
        <NormalBtnBox
          ref="NormalBtnBox"
          showPriceModuleAbt={showPriceModuleAbt}
          showPrice={showPrice}
          priceExposeAbt={quickShowSa}
          halfWidthStyle={showOneClickPayBtn}
          onAddCart={changeAddBag}
          useDiscountStyle={priceUseDiscountStyle}
          isCustomization={isCustomization}
        >
          {supportOneClickPay ? (
            <template slot="rightSideContent">
              <OneClickPayBtn
                loadImmediately={true}
                show={showOneClickPayBtn}
                scene={'ProductDetail'}
                billInfo={oneClickPayBillInfo}
                goodsData={detail}
                quantity={quantity}
                extra-pay-params={extraOneClickPayParams}
                before-pay={testBeforeOCP}
                language={language}
                analysis-data={oneClickPayAnalysisData}
                onPaySuccess={onOneClickPaySuccess}
                onPayFail={onOneClickPayFail}
                onTransComplete={onOneClickPayTransComplete}
              />
            </template>
          ) : null}
        </NormalBtnBox>
      )
    },
    // 加车反馈信息弹出框
    renderAddPop() {
      const {
        addPopIsReady,
        language,
        addToBagFailMsg,
        promotionLimitType,
        originImage,
        closeLimitTips,
        goToBag,
        addToBagFail,
        addToBagSuc,
        addToBagLoading,
        addLoadingAttachTop
      } = this
      if (!addPopIsReady) return null

      return (
        <AddPop
          language={language}
          failMsg={addToBagFailMsg}
          promotionLimitType={promotionLimitType}
          isRw={IS_RW}
          originImage={originImage}
          closeLimitTips={closeLimitTips}
          goToBag={goToBag}
          addToBagSuc={addToBagSuc}
          addToBagFail={addToBagFail}
          addToBagLoading={addToBagLoading}
          addLoadingAttachTop={addLoadingAttachTop}
        />
      )
    }
  },

  render() {
    const {
      barDomClass,
      // renderRetentionBelt,
      renderSoldOutTip,
      isReview,
      renderAddCartBtn,
      renderAddPop,
      renderTopContent,
      onHandleSucTip,
      showStoreBtn,
      language,
      goodsReady,
      changeCardShareIconAbt,
    } = this

    let topContent = renderTopContent()
    
    return (
      <div class="goods-addToCartBar">
        {/** 外框栏盒子 */}
        <div
          class={{
            'product-intro__add-cart fixed animated-medium': true,
            'j-btn-add-to-bag-wrapper j-da-event-box': true,
            'no-border-style': !!topContent,
            ...barDomClass,
          }}
          code="goodsDetail"
          data-active-from="main"
          style={{
            paddingTop: `0.16rem`,
          }}
        >
          {/** 按钮上方内容 */}
          {topContent}

          {/* 保留款 TODO del */}
          {/* <ClientOnly>{renderRetentionBelt()}</ClientOnly> */}

          {/* 售罄浮动标识 */}
          {renderSoldOutTip()}
       
          { showStoreBtn && goodsReady ? <StoreBtn language={language} /> : null}
          {/* 收藏按钮 */}
          {
            !changeCardShareIconAbt && 
            <WishBtn isReview={isReview} loc="buttonbar" />
          }
          {
            changeCardShareIconAbt && 
            <div
              class="add-cart__cart-btn j-add-cart__cart-btn"
            >
              <CommonCart 
                placement="top-start" 
                offset-y={10} 
                theme-vars={{ iconSize: 32 }}
              />
            </div>
          }

          {/* 加车按钮 */}
          {renderAddCartBtn()}
        </div>

        {/* 加车反馈信息弹出框 */}
        <ClientOnly>{renderAddPop()}</ClientOnly>
        {/* 加车商品动画 */}
        <ClientOnly>
          <AddPopAnimation 
            showLowStockAddCartTips={this.showLowStockAddCartTips}
            currentMall={this.currentMall}
            language={this.language}
            originImage={this.originImage} 
            showFloatCartIcon={this.showFloatCartIcon} 
            showTopCartIcon={this.showTopCartIcon}
            cartConfig={this.cartConfig}
            onSucTip={onHandleSucTip}
          />
        </ClientOnly>
      </div>
    )
  },
}
</script>

<style lang="less">
// * 基类名 product-intro__add-cart
 /* stylelint-disable selector-max-specificity, no-invalid-double-slash-comments */
body.no-fullscreen {
  .product-intro__add-cart.fixed {
    .add-cart__store-btn,
    .add-cart__wish-btn,
    .mshe-btn-black {
      transition: none;
      margin-bottom: env(safe-area-inset-bottom);
    }
  }
}
body.ipx-fullscreen {
  .product-intro__add-cart.fixed {
    .add-cart__store-btn,
    .add-cart__wish-btn,
    .mshe-btn-black {
      transition: none;
      margin-bottom: 0.906rem;
    }
  }
}
@keyframes goodsdt-like-anim-click {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.6;
  }
}
@keyframes goodsdt-like-anim-loading {
  0% {
    transform: scale(0.9);
    opacity: 0.6;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.6;
  }
}
@keyframes goodsdt-like-anim-complete {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.product-intro__add-cart {
  .flexbox();
  .space-between();
  .zindex-translatez(
    @zindex-fixedregister,
    translate3d(0, 0, @tranlatez-fixedregister)
  );
  position: fixed;
  width: 10rem;
  padding: 0.16rem 0.32rem 0.16rem 0.32rem;
  left: 0 /*rtl:ignore*/;
  right: 0 /*rtl:ignore*/;
  bottom: 0;
  margin: 0 auto;
  background: #fff;
  border-top: 1px solid #e5e5e5;

  &.no-border-style {
    border: none;
  }

  &.beauty-color-add {
    .zindex-translatez(
      @zindex-transform1,
      translate3d(0, 0, @tranlatez-trans-mask2)
    );
  }

  // 展示价格时的覆写样式
  &.show-price {
    // * 同步 ./SoldOutBtn.vue 类名
    .add-cart__sold-out-btn,
    // * 同步 ./NormalBtnBox.vue 类名
    .add-cart__normal-btn-box,
    .add-cart__normal-btn {
      flex: 1;
    }
    // * 同步 ./WishBtnUI.vue 类名
    .add-cart__wish-btn {
      flex: 0 0 0.85333rem;
    }
  }

  .top-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translate(0, -100%);
  }

  .customization-tip {
    width: 100%;
    padding: 0.11rem 0.32rem;
    background: #FAFAFA;
    color: #767676;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__text {
      // line-height: 1;
      // font-size: 0.26667rem;
      font-size: var(--belt-text-font-size, 10px);
      font-weight: 400;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-right: 0.32rem;
    }
  }

  .belt-wrap {
    position: absolute;
    width: 100%;
    left: 0;
    top: -56/75rem;
    .zindex-translatez(
      @zindex-fixedregister,
      translate3d(0, 0, @tranlatez-fixedregister)
    );
  }

  .freeshipping {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.95);
    height: .64rem;
    gap: .08rem;
    color: #198055;
    border-top: 1px solid #E5E5E5;
  }

  .freeshippingWithAtmosphere {
    display: flex;
    align-items: center;
    border-top: 1px solid #E5E5E5;
    background: rgba(255, 255, 255, 0.95);
    width: 10rem;
    max-width: 10rem;
    .freeshipping {
      flex: 115;
      border-top: none;
      background: none;
    }
  }
  .freeshippingWithAtmosphere__vertical {
    width: 1px;
    height: .4267rem;
    background: rgba(0, 0, 0, 0.3);
  }
}

@keyframes add-board-keyframes {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 0.8;
    top: -1.1733rem;
  }
}
.add-cart__cart-btn{
  width: 0.85333rem;
  height: 1.07rem;
  text-align: center;
  margin-right: 0.32rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
