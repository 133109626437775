<script lang="jsx">
/**
 * @component 加车栏下的店铺按钮
 */
import { Icon } from '@shein-aidc/icon-vue2'
import moreMixin from 'public/src/pages/goods_detail/middlect/components/DetailSignBoard/mixins/more.js'
import { mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { getLocalStorage, setLocalStorage, setSessionStorage } from '@shein/common-function'
import { getCategoryName } from 'public/src/pages/goods_detail/utils/productDetail.js'

export default {
  name: 'StoreBtn',
  components: {
    Icon,
  },
  mixins: [moreMixin],
  props: {
    language: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters('newProductDetail', ['fsAbt']),
    ...mapGetters('newProductDetail/common', [ 'detail', 'localStoreInfo', 'showStoreNewArrival', 'parentCats' ]),
    // 店铺按钮是否显示上新标签
    showNewArrival() {
      return this.showStoreNewArrival === 'icon'
    },
    isShowNewFollowIcon(){
      return this.fsAbt?.storeiconchange?.param?.storeiconchange === 'new'
    },
    exposeConfig() {
      const { name, storeCode, storeBusinessType } = this.localStoreInfo
      return {
        itemDAEventExpose: '1-6-1-186',
        itemDAEventClick: '1-6-1-187',
        sa: {
          src_module: 'DetailStoreIcon',
          src_identifier: [
            `on=store`,
            `cn=${name || ''}`,
            `hz=0`,
            `ps=1`,
            `jc=thirdPartyStoreHome_${storeCode || ''}`
          ].join('`'),
          store_tp: storeBusinessType === 2 ? 'brand' : 'other',
          store_code: storeCode || '',
          button_label: this.showNewArrival ? 'icon_new' : '-'
        }
      }
        
    }

  },
  methods: {
    viewStore() {
      const { type, id, link, storeCode } = this.localStoreInfo
      const { detail, exposeConfig, showNewArrival } = this
      daEventCenter.triggerNotice({
        daId: exposeConfig.itemDAEventClick,
        extraData: { ...exposeConfig.sa }
      })
      const storageKey = `storenew_hide_${storeCode}`
      let val = getLocalStorage(storageKey)
      if(!val && showNewArrival){
        // 上新标签缓存3天
        setLocalStorage({ key: storageKey, value: true, expire: 1000 * 60 * 60 * 24 * 3 }) 
      }
      let sort = ''
      let tabId = ''
      let adp = detail.goods_id
      let isStoreTabSticky = ''
      if(showNewArrival) {
        sort = '9'
        tabId = 'items'
        adp = ''
        isStoreTabSticky = '1'
      } else { // 店铺上新时原跳转逻辑不变
        const detailToStoreData = {
          goods_id: detail.goods_id,
          goods_img: detail.goods_thumb || detail.goods_img,
          cat_id: detail.cat_id,
          cat_name: getCategoryName(this.parentCats)
        }
        setSessionStorage({ key: 'detailToStoreData', value: detailToStoreData })
      }
      const detailToStoreData = {
        goods_id: detail.goods_id,
        goods_img: detail.goods_thumb || detail.goods_img,
        cat_id: detail.cat_id,
        cat_name: getCategoryName(this.parentCats)
      }
      setSessionStorage({ key: 'detailToStoreData', value: detailToStoreData })
      this.boardMoreHandle({
        isStoreTabSticky,
        sort,
        tabId,
        type,
        id,
        link,
        goods_id: detail.goods_id,
        cat_id: detail.cat_id,
        adp,
        analysis: { src_identifier: exposeConfig.sa.src_identifier, src_module: exposeConfig.sa.src_module }
      })
    },
  },
  render() {
    const {
      exposeConfig,
      viewStore,
      language,
      showNewArrival,
      isShowNewFollowIcon
    } = this
    return (
      <div 
        class='add-cart__store-btn'
        v-expose={{
          id: exposeConfig.itemDAEventExpose,
          data: {
            ...exposeConfig.sa,
          }
        }}
        onClick={viewStore}>
        { showNewArrival ? <span class='add-cart__store-btn-new'>{ language.SHEIN_KEY_PWA_30036 || 'NEW' }</span> : null }
        <Icon name={isShowNewFollowIcon ? 'sui_icon_store2_32px' : 'sui_icon_store_32px'} size="32px" />
      </div>
    )
  }
}
</script>
<style lang="less">
.add-cart__store-btn{
  width: 0.85333rem;
  height: 1.07rem;
  line-height: 1.07rem;
  flex-shrink: 0;
  text-align: center;
  margin-right: 0.32rem;
  position: relative;
}
.add-cart__store-btn-new{
  height: 0.37333rem;
  line-height: 0.37333rem;
  padding: 0 0.05333rem;
  position: absolute;
  left: 0.34666rem;
  top: -0.02666rem;
  border-radius: 0.08rem 0.08rem 0.08rem 0;
  border: 1px solid #FFF;
  background: #3CBD45;
  font-size: 0.26667rem;
  color: #fff;
  z-index: 1;
}
</style>
