<script lang="jsx">
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import Size from 'public/src/pages/goods_detail_v2/innerComponents/top/Size/index.vue'

import { getGoodsUrl } from 'public/src/pages/goods_detail/utils/common.js'
import { skuInfoAdator } from './utils.js'

export default {
  name: 'NewSizeBox',
  computed: {
    // TODO: 旧vuex
    ...mapState('newProductDetail/SizeBox', [
      'globalPerfectFitShow',
    ]),
    ...mapState('newProductDetail/common', [
      'addToBagForm',
      'externalSizeInfoIndex',
      'externalSkuCode',
    ]),
    ...mapGetters('newProductDetail/common', [
      'allDataReady',
    ]),

    ...mapGetters('productDetail/SizeBox', [
      'config_v2',
      'local_size_language',
      'size_constant',
      'data_map_compose',
      'is_base_size',
      'hide_one_size_module',
      'size_tips_info',
      'mall_info_list',
      'show_mall_list',
      'size_recommend_info',
      'delivery_floor_quickship_title',
      'real_time_size_info',
    ]),
    ...mapGetters('productDetail/common', [
      'language_v2',
      'skc_sale_attr',
      'cache_sku_list',
      'current_country',
      'current_unit',
      'size_attr_info',
      'goods_id',
      'is_on_sale',
      'will_sold_out_tips_info',
      'stock_tip_show_location',
      'sku_info',
      'sale_attr_groups',
      'product_relation_id',
      'is_skc_sold_out',
      'goods_sn',
      'mall_code',
      'is_hit_compliance_mode',
      'default_country_code',
    ]),
    productChange() {
      return {
        product_relation_id: this.product_relation_id,
        goods_id: this.goods_id,
      }
    },
  },
  watch: {
    productChange(val, oldVal) {
      const isNewSpu = val?.product_relation_id !== oldVal?.product_relation_id
      const isNewGood = val?.goods_id !== oldVal?.goods_id
      const isNewSkc = isNewGood && !isNewSpu
      if (isNewSkc || isNewSpu) {
        this.$nextTick(() => {
          this.$refs?.['SIZE_BOX']?.resetSizeBox?.({
            isNewSpu,
            isNewSkc,
          })
        })
      }
    },
    allDataReady(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs?.['SIZE_BOX']?.allDataReadyCallBack?.()
        })
      }
    },
  },
  mounted() {
    this.updateLocalCountry()
    this.updateLocalUnit()
    setTimeout(() => {
      this.updateTopContentReady(true)
    }, 100)
  },
  methods: {
    // TODO: 旧vuex
    ...mapMutations('newProductDetail/common', [
      'updateCurrentLocalCountry',
      'updateQuickShip',
      'updateExternalSizeInfoIndex',
      'updateSkuInfo',
      'updateAddToBagForm',
      'updateTopContentReady',
      'updateFromSwitchColor',
      'updateQuickAddState',
      'updateCurrentLocalUnit',
    ]),
    ...mapMutations('newProductDetail/SizeBox', [
      'updateGlobalPerfectFitShow',
      'updateSwitchMallCode',
      'updateRealMallStock',
    ]),

    ...mapActions('productDetail/SizeBox', [
      'open_size_guide_pop',
      'open_size_recommend_pop',
      'open_bra_size_recommend_pop',
    ]),
    updateLocalCountry() {
      this.$nextTick(() => {
        const country = window.localStorage.getItem('last_select_country')
        if (!country) return
        window.localStorage.setItem('cache_last_select_country', country || '')
        // TODO: 旧vuex
        this.updateCurrentLocalCountry(country === 'default' ? '' : country)
      })
    },
    updateLocalUnit() {
      this.$nextTick(() => {
        const unit = window.localStorage.getItem('selectedUnit')
        if (!unit) return
        this.updateCurrentLocalUnit(unit)
      })
    },
    openSizeGuideHandle(scene = '', fromScene = '') {
      this.open_size_guide_pop({ fromScene }) 
      if (scene !== 'SizeSlideTips') return
      daEventCenter.triggerNotice({
        daId: '1-8-1-31',
        extraData: {},
      })
    },
    mallClickHandle(mallInfo) {
      const { mallCode } = mallInfo
      this.updateSwitchMallCode(mallCode)
      this.updateRealMallStock(null)
    },
    handleCompose({ attrs, skuInfo, externalSizeInfoIndex, curSelectAttr, mallStock } = {}) {
      this.updateRealMallStock(mallStock)
      this.updateSkuInfo(skuInfoAdator(skuInfo, { 
        mallInfoList: this.mall_info_list, 
        isOnSale: this.is_on_sale,
        goodsSn: this.goods_sn,
      }))
      this.updateAddToBagForm({
        ...(this.addToBagForm || {}),
        attrs,
      })
      this.updateExternalSizeInfoIndex(externalSizeInfoIndex)
      if (typeof window !== 'undefined') {
        window.vBus?.$emit?.('changeWakeupData', {
          sku_code: skuInfo?.sku_code || '',
          selected_attr_value_list: attrs.map((_) => String(_.attr_value_id)) || [],
        })
      }
      if (curSelectAttr?.attr_value_name) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-33',
          target: {
            dataset: {
              attr_id: curSelectAttr.attr_id,
              attr_value_id: curSelectAttr.attr_value_id || '',
              from: 'detailPage',
              attr_value: curSelectAttr.attr_value_name,
              sku_code: skuInfo?.sku_code,
              location: 'page',
            },
          },
        })
      }
    },
    handleSizeRecommend() {
      const { ruleType } = this.size_recommend_info || {}
      if (ruleType == '1') {
        this.open_bra_size_recommend_pop()
        return
      }
      this.open_size_recommend_pop()
    },
    handleOpenQuickCart(item = {}, config = {}) {
      const {
        hideSizeGroupeSize = false,
        hideRelationGoods = false,
        index,
        analysisConfig = {},
        openPage = false,
      } = config || {}
      const { goods_id, goods_url_name, cat_id } = item
      if (openPage) {
        const { langPath } = gbCommonInfo
        _gb_app_.$router.push(
          `${langPath}${getGoodsUrl(goods_url_name, goods_id, cat_id)}`
        )
      }
      const params = {
        addSource: 'detailPage',
        isShowAttrPlusSize: false,
        showBestDealLabel: true,
        showFollowBeltByOrigin: true,
        showEstimatedPrice: !this.is_hit_compliance_mode,
        hideRelationGoods,
        hideSizeGroupeSize,
        clickCallBack: {
          handleChangeLocalCountry: (value) => {
            this.updateCurrentLocalCountry(value)
          },
          // 加车成功后的回调
          complete: () => {
            setTimeout(() => {
              window.vBus &&
                window.vBus.$emit('triggerAddCompletedFloatCart', {
                  animation: false
                })
            }, 2000)
          }
        },
        analysisConfig,
      }
      if (index) params.index = index
      this.$quickAdd.open({
        goods_id,
        ...params,
      })
    },
    handleClickSizeGroupItem(item) {
      if (this.goodsId === item.goodsId) return // 当前商品不做操作
      if(item?.openQuickCart) return this.handleOpenQuickCart(
        {
          goods_id: item.goodsId,
        },
        {
          hideSizeGroupeSize: true,
          hideRelationGoods: true,
          analysisConfig: {
            sa: {
              activity_from: 'sizegroup',
              location: 'page'
            },
            style: 'detail'
          },
        }
      )
      const mallCode = this.mall_code
      const { langPath  } = gbCommonInfo || {}

      // TODO MYTEST 这里需要注意 暂时先用SwitchColor 后续当前需求优化后再改
      this.updateFromSwitchColor(true)

      // this.updateAttriBute(''),
      // this.updateMainAttribute(this.colorBoxInfo.mainAttribute)
      let search = location.search.replace(/&?(attr_ids|main_attr|mallCode|isFromSwitchColor)=(\w|_)+/g, '')
      // search = search + (search.indexOf('?') > -1 ? '&main_attr=' + this.colorBoxInfo.mainAttribute : '?main_attr=' + this.colorBoxInfo.mainAttribute)
      search = search + (search.indexOf('?') > -1 ? '&mallCode=' + mallCode : '?mallCode=' + mallCode)
      search = search + (search.indexOf('?') > -1 ? '&isFromSwitchColor=1' : '?isFromSwitchColor=1')
      let url = `${langPath}${getGoodsUrl(item.goodsName, item.goodsId)}` + search
      this.$router.replace(url)
    },
    handleClickToBuy() {
      this.updateQuickAddState(true)
    },
  },
  render() {
    const customerStyle = {
      bold: true,
      title: 'Size',
    }
    return <Size
      ref="SIZE_BOX"
      class="goods-size_dom"
      customerStyle={customerStyle}
      deliveryFloorQuickshipTitle={this.delivery_floor_quickship_title}
      config={this.config_v2}
      sizeConstant={this.size_constant}
      skcSaleAttr={this.skc_sale_attr}
      skuList={this.cache_sku_list}
      realTimeSizeInfo={this.real_time_size_info}
      skuInfo={this.sku_info}
      sizeAttrInfo={this.size_attr_info}
      country={this.current_country}
      sizeUnit={this.current_unit}
      isOnSale={this.is_on_sale}
      isBaseSize={this.is_base_size}
      dataMapCompose={this.data_map_compose}
      goodsId={this.goods_id}
      mallCode={this.mall_code}
      language={this.language_v2}
      localSizeLanguage={this.local_size_language}
      willSoldOutTipsInfo={this.will_sold_out_tips_info}
      stockTipShowLocation={this.stock_tip_show_location}
      hideSizeModule={this.hide_one_size_module}
      saleAttrGroups={this.sale_attr_groups}
      globalPerfectFitShow={this.globalPerfectFitShow}
      mallClick={this.mallClickHandle}
      sizeTipsInfo={this.size_tips_info}
      showMallList={this.show_mall_list}
      mallInfoList={this.mall_info_list}
      defaultCountryCode={this.default_country_code}
      sizeRecommendInfo={this.size_recommend_info}
      externalSizeInfoIndex={this.externalSizeInfoIndex}
      externalSkuCode={this.externalSkuCode}
      handleUpdateGlobalPerfectFitShow={this.updateGlobalPerfectFitShow}
      handleUpdateQuickShip={this.updateQuickShip}
      handleCompose={this.handleCompose}
      handleSizeRecommend={this.handleSizeRecommend}
      handleClickSizeGroupItem={this.handleClickSizeGroupItem}
      handleOpenQuickCart={this.handleOpenQuickCart}
      handleClickToBuy={this.handleClickToBuy}
      handleLocalChange={this.updateCurrentLocalCountry}
      handleOpenSizeGuide={this.openSizeGuideHandle}
    />
  },
}
</script>
