<script lang="jsx">
import { template } from '@shein/common-function'
import { parsePercentText } from 'public/src/pages/goods_detail/utils/ar.js'

const getNewUserCoupon = (listeners) => {
  // 点击新人券
  listeners['get-coupon'] && listeners['get-coupon']()
}

export default {
  name: 'NewUserCoupon',
  functional: true,
  props: {
    newUserCoupon: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    newUserDiscount: {
      type: String,
      default: '',
    },
    newUserCouponRule: {
      type: Object,
      default: () => ({}),
    }
  },
  render(h, { props, listeners }) {
    const { language, newUserCoupon, newUserDiscount, newUserCouponRule } = props
    return (
      <div class="goods-newUserCounpon__new-user-content"
        vOn:click={() => getNewUserCoupon(listeners)}
        {...{ directives: [{ name: 'expose', value: { id: '1-6-1-160' } }] }}
      >
        <div class="goods-newUserCounpon__new-user-off">{parsePercentText(template(newUserDiscount, language.SHEIN_KEY_PWA_15816))}</div>
        <div class="goods-newUserCounpon__new-user-divide"></div>
        <div class="goods-newUserCounpon__new-user-right">
          <p class="goods-newUserCounpon__new-user-tip">{newUserCoupon?.satisfied == 1 && newUserCoupon?.isForEstimated ? language.SHEIN_KEY_PWA_31228 : template(newUserCouponRule?.min?.amountWithSymbol, language.SHEIN_KEY_PWA_31229)}</p>
          {/* <i class="suiiconfont sui_icon_more_right_12px_2 goods-newUserCounpon__right-icon"></i> */}
        </div>
      </div>
    )
  },
}
</script>

<style lang="less">
.goods-newUserCounpon {
  &__new-user-content {
    display: flex;
    align-items: center;
    color: #FF3F3F;
    height: 30px;
    border-radius: 0.0533rem;
    // background-image: linear-gradient(90deg, rgba(254, 220, 223, 0.40) 0%, rgba(254, 237, 183, 0.40) 100%);
    background-image: linear-gradient(271deg, rgba(255, 232, 226, 0.90) 0.96%, rgba(255, 185, 169, 0.50) 99.24%);
    padding: 0 0.2133rem;
    margin-bottom: 0.2133rem;
    border: 0.5px solid rgba(255, 209, 198, 0.80);
    box-sizing: content-box;
    margin-top: 0;
  }
  &__new-user-off{
    font-size: 0.32rem;
    font-weight: 700;
    flex: 0 0 auto;
  }
  &__new-user-divide{
    width: 0.16rem;
    height: 32px;
    background-image: url('/pwa_dist/images/coupon/new-user-coupon-e3527822b2.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex: 0 0 auto;
    margin: 0 0.2133rem;
  }
  &__new-user-right{
    font-size: 0.2667rem;
    line-height: 30px;
    overflow: hidden;
    flex: 1;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    font-weight: 700;
  }
  &__new-user-tip{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  // &__right-icon{
  //   font-size: 12px;
  //   transform: rotate(0deg) /* rtl:rotate(180deg) */;
  // }
}
</style>
