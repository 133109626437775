<script lang="jsx">
/**
 * @component 售罄或库存为空状态下的按钮
 */

export default {
  name: 'SoldOutBtn',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    // 未售
    unlisted: {
      type: Boolean,
      default: false,
    },
    // 找相似状态
    showFindSimilar: {
      type: Boolean,
      default: false,
    },
    // 下架（售罄）状态
    isSoldOut: {
      type: Boolean,
      default: false,
    },
    // 商品库存
    mallStock: {
      type: Number,
      default: 0,
    },
    // 半宽样式
    halfWidthStyle: {
      type: Boolean,
      default: false,
    },
    // 反色样式
    inverseStyle: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, listeners }) {
    const {
      language = {},
      unlisted,
      showFindSimilar,
      mallStock,
      isSoldOut,
      halfWidthStyle,
      inverseStyle,
    } = props

    const commonClass = {
      'add-cart__sold-out-btn': true,
      'mshe-btn-black': true,
      'half-width': halfWidthStyle,
      'inverse': inverseStyle,
    }
    const disabledClass = {
      ...commonClass,
      'disabled': true,
    }

    /**
     * 优先逻辑：未售/未发布 => 找相似 => 库存0 => 已售罄
     */

    // 未售
    // * button text: COMING SOON
    if (unlisted) {
      return (
        <button
          {...{ directives: [{ name: 'ada', value: { level: 0, pos: 0 } }] }}
          class={disabledClass}
          aria-hidden
        >
          { language.SHEIN_KEY_PWA_16006 }
        </button>
      )
    }

    // 找相似，相似浮动栏在加购栏之上，按钮显示售罄文本
    // * button text: SOLD OUT
    if (showFindSimilar) {
      return (
        <button
          class={disabledClass}
        >
          { language.SHEIN_KEY_PWA_25026 }
        </button>
      )
    }

    // 已售罄，可点击找相似
    // * button text: Find Similar
    if (isSoldOut) {
      const handleShowSimilar = (args) => {
        listeners.showSimilar?.(args)
      }
      return (
        <button
          {...{ directives: [{ name: 'ada', value: { level: 0, pos: 0 } }] }}
          class={commonClass}
          aria-hidden
          onClick={handleShowSimilar}
        >
          { language.SHEIN_KEY_PWA_16789 }
        </button>
      )
    }

    // 库存0
    // * button text: ADD TO CART
    if (mallStock === 0) {
      return (
        <button
          class={disabledClass}
        >
          { language.SHEIN_KEY_PWA_15019 }
        </button>
      )
    }

    return null
  }
}
</script>

<style lang="less">
.add-cart__sold-out-btn {
  /* rw:begin */
  font-family: "Adieu";
  &.mshe-btn-black {
    width: 8.16rem;
    height: 1.07rem;
    line-height: 1.07rem;
    margin-top: 0;

    &.disabled {
      border: 1px solid transparent;
    }
    &.half-width {
      width: 3.96rem;
      margin-top: 0;
      vertical-align: top;
    }
    &.inverse {
      color: #222;
      background-color: #fff;
      border: 1px solid #ccc;
    }
  }
  &.mshe-btn-sec {
    height: 1.066667rem;
  }
}
</style>
