import { computed } from 'vue'

export default function useSizeAttrInfo(props, reactiveInfo, callBack = {}) {
  const { 
    bubbleInfo, 
    sizeInfoIndex, 
    isInit, 
    skuInfo, 
    currentUnit,
    isShowBaseCode,
    sizeComposeInfo,
    language,
    goodsId,
    sizeConfig,
    goodsMainAttPicInfo,
    goodsDesc,
  } = reactiveInfo || {}
  const sizeInfoStrFilterField = [
    'size',
    'attr_id',
    'attr_name',
    'attr_value_id',
    'attr_value_name',
    'attr_value_name_en',
  ]
  const { calcSize } = callBack || {}
  const sizeTarget = computed(() => {
    const sizeInfoDesAttrId = bubbleInfo.value?.sizeInfoDesAttrId
    return sizeInfoIndex.value?.[sizeInfoDesAttrId]?.attr_value_id
  })
  const sizeInfoDesIndex = computed(() => {
    if (!isInit.value) return -1
    const { sizeInfoDesAttrList } = bubbleInfo.value || {}
    return sizeInfoDesAttrList?.findIndex?.((_) => _.attr_value_id == sizeTarget.value)
  })

  const sizeInfoStr = computed(() => {
    if (!skuInfo.value?.sku_code) {
      return []
    }
    let info = []
    const result = []
    if (sizeInfoDesIndex.value != -1) {
      info = bubbleInfo.value?.[currentUnit.value] || []
    }
    info.forEach((_, i) => {
      const item = _.list[sizeInfoDesIndex.value]
      result.push({
        name: _.name,
        list: [],
      })
      for (const k in item) {
        if (sizeInfoStrFilterField.includes(k)) continue
        const val = item[k].replace(/(cm|inch)/g, '').trim()
        if (val && val !== '/') {
          result[i].list.push({ k: k.trim(), val: item[k].trim() })
        }
      }
    })
    // 基码支持排序
    if (isShowBaseCode.value) {
      let baseSizeSortMap = sizeComposeInfo.value?.sizeInfoDes.basicAttribute.base_attr_sort || null
      if (baseSizeSortMap) {
        for (let item of result) {
          item.list = item.list.sort((a, b) => baseSizeSortMap[a.k] - baseSizeSortMap[b.k])
        }
      }
    }
    return result.filter((_) => _.list.length)
  })

  const sizeInfoTitle = computed(() => {
    return bubbleInfo.value?.title || ''
  })

  const showDesc = computed(() => {
    // if (!skuInfo.value?.sku_code) return false
    // 图片优先级高于描述
    if(attrPicInfos.value.some((_) => _.attr_value_id == sizeTarget.value)) return false
    return !!(sizeInfoDesIndex.value != -1 && sizeInfoStr.value.length)
  })

  const attrPicInfos = computed(() => {
    const result = []
    // 主销售属性图片
    if (goodsMainAttPicInfo.value || goodsDesc.value) {
      result.push({
        isMainAttr: true,
      })
    }
    const maxNum = goodsMainAttPicInfo?.value ? 2 : (goodsDesc.value ? 3 : 2)
    // 次级销售属性图片
    if (sizeConfig.value?.isShowAttrPicInfo) {
      const { skcSaleAttr } = sizeComposeInfo.value || {}
      skcSaleAttr?.some?.((item, index) => {
        const { attr_value_id, attr_image } = sizeInfoIndex.value[item?.attr_id] || {}
        if (attr_value_id && attr_image) {
          result.push({
            textDetail: `${language.value.SHEIN_KEY_PWA_24732}: `,
            attr_value: calcSize(sizeInfoIndex.value[item?.attr_id]),
            attr_value_id,
            attr_image,
            analysisData: {
              location: 'detailPage',
              goodsId: goodsId.value || '',
              main_attr: index + 2,
            },
          })
        }
        return maxNum
      })
    }
    return result
  })

  return { showDesc, sizeInfoStr, sizeInfoTitle, attrPicInfos }
}
