<script lang="jsx">
import ClientOnly from 'vue-client-only'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default {
  name: 'CarouselsPagination',
  components: {
    ClientOnly,
  },
  props: {
    outfitBlockShow: {
      type: Boolean,
      default: false
    },
    totalIndex: {
      type: Number,
      default: 0
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    outfitsData: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    isRtl: {
      type: Boolean,
      default: false
    },
    goodsId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      pageReady: false,
      indexHeight: 0,
      activeIndexWidth: {}
    }
  },
  watch: {
    // 页码变化后需要重写计算背景尺寸
    currentIndex(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.pageReady = false
      }
    }, 
    // 商品变化后需要重写计算背景尺寸
    goodsId(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.pageReady = false
      }
    },
    pageReady(newVal) {
      if (newVal) {
        // 计算背景色位置跟尺寸
        let containerNumber = this.$refs['pagesNumber']
        let containerPic = this.$refs['pagesPic']
        this.indexHeight = containerNumber?.clientHeight
        this.activeIndexWidth = {
          0: containerNumber?.clientWidth,
          1: containerPic?.clientWidth,
        }
      }
    }
  },
  render() {
    const {
      outfitBlockShow,
      totalIndex,
      currentIndex,
      outfitsData,
      language,
      isRtl,
    } = this


    const isActiveOutfits = outfitBlockShow && currentIndex > totalIndex

    // 页码
    const paginationText = () => {
      const currentPic = (outfitBlockShow && currentIndex > totalIndex) ? totalIndex : currentIndex
      if (!totalIndex) return ''
      if (outfitBlockShow) {
        return `${currentPic}/${totalIndex}`
      }
      return `${currentPic} / ${totalIndex}`
    }

    // outfit tab 点击上报
    const handleOutfitTabClick = (isClickTab) => {
      daEventCenter.triggerNotice({
        daId: '1-6-1-70',
        extraData: {
          entry_mode: isClickTab ? 2 : 1,
        },
      })
    }

    // outfit label 曝光上报
    const handleOutfitLabelExpose = () => {
      const outfitsAbt = outfitsData.outfitsAbt
      const { child = [], posKey } = outfitsAbt || {}
      const { bid = '', eid = '' } = child?.[0] || {}
      const abTest = bid && eid ? `${posKey}\`${eid}\`${bid}` : ''
      if (!outfitsData?.labels?.length) return

      outfitsData.labels.forEach((anchor, index) => {
        if (!anchor.expose) {
          anchor.expose = true
          const { label, rec_mark, label_id } = anchor
          daEventCenter.triggerNotice({
            daId: '1-6-1-71',
            extraData: {
              abtest: abTest,
              tab_list: `${index + 1}\`${label_id}\`\`${label}`,
              rec_mark,
            },
          })
        }
      })
    }

    // 处理数据埋点
    const _toggleActive = (i, isClickTab) => {
      // 切换页码
      this.$emit('toggleActive', i, isClickTab)
      if (i !== 1) {
        handleOutfitTabClick(isClickTab)
        this.$nextTick(() => {
          handleOutfitLabelExpose()
        })
      }
    }

    const showOutfitPoint = () => {
      if (typeof window !== 'undefined') {
        const outfitPoint = localStorage && localStorage?.getItem('outfitPoint')
        return outfitBlockShow && outfitPoint != 1
      }
    }

    const activeBgStyles = () => {
      const activeIndex = isActiveOutfits ? 1 : 0
      return { 
        [isRtl ? 'right' : 'left']: `${!isActiveOutfits ? '2' : this.activeIndexWidth[0]}px`,
        width: `${this.activeIndexWidth[activeIndex]}px`,
        height: `${this.indexHeight}px`
      }
    }

    return (
      <div class="carousels-pagination">
        { !outfitBlockShow ? (
          <span
            class={{ 'carousels-pagination__pages': !!paginationText() }}
            aria-hidden="true"
          >
            { paginationText() }
          </span>
        ) : (
          <div
            ref={() => {
              setTimeout(() => {
                this.pageReady = true
              }, 0)
            }}
            class="carousels-pagination__pages-outfit"
          >
            <span
              ref="pagesNumber"
              class={`carousels-pagination__pages-number ${!isActiveOutfits && 'active'}`}
              vOn:click={() => { _toggleActive(1, true) }}
            >
              { language?.SHEIN_KEY_PWA_21479 } { paginationText() }
            </span>
            <span
              ref="pagesPic"
              class={`carousels-pagination__pages-pic ${isActiveOutfits && 'active'}`}
              vOn:click={() => { _toggleActive(totalIndex + 1, true) }}
            >
              { language?.SHEIN_KEY_PWA_21163 }
            </span>
            <div 
              class="carousels-pagination__active-bg" 
              style={activeBgStyles()}
            />
          </div>
        ) }
        <ClientOnly>
          { showOutfitPoint() && (
            <div>
              <span class="carousels-pagination__pagination-point"></span>
              <span class="carousels-pagination__pagination-outpoint"></span>
            </div>
          )}
        </ClientOnly>
      </div>  
    )
  }
}

</script>

<style lang="less" scoped>
.carousels-pagination {
  .left(50%);
  position: absolute;
  top: 0;
  transform: translateX(-50%) translateY(calc(-0.32rem - 100%));
  z-index: @zindex-hack;
  &__pages {
    padding: .05rem .2rem;
    border-radius: .3rem;
    color: #fff;
    z-index: @zindex-list-wrapper;
    background-color: rgba(0, 0, 1, .3);
    /*sh:begin*/
    padding: 0 .1rem;
  }
  &__pages-outfit {
    .flexbox();
    .border-dpr(border, 4px, transparent);
    border-radius: .32rem;
    align-items: center;
    background: hsla(0, 0%, 100%, .8);
  }
  &__pages-number {
    box-sizing: content-box;
    padding: .02667rem .16rem;
    z-index: @zindex-list-wrapper;
  }
  &__pages-pic {
    box-sizing: content-box;
    padding: .02667rem .213333rem;
    z-index: @zindex-list-wrapper;
  }
  .active {
    color: rgb(255, 255, 255);
    // border-radius: .29rem;
    // background: #222;
  }
  &__active-bg {
    position: absolute;
    height: .53rem;
    border-radius: .29rem;
    background: #222;
    transition: all 0.3s ease;
    z-index: @zindex-select;
    top: 50%;
    transform: translateY(-50%);
  }
  &__pagination-point {
    position: absolute;
    width: 0.12rem;
    height: 0.12rem;
    top: -0.06rem;
    right: 0.16rem;
    border-radius: 50%;
    background: red;
  }
  &__pagination-outpoint {
    position: absolute;
    width: 0.24rem;
    height: 0.24rem;
    top: -0.12rem;
    right: 0.1rem;
    background: rgba(255, 0, 0, 0.6);
    border-radius: 50%;
    transform-origin: center;
    animation: outpoint-scale 3s infinite;
    transform: scale(1);
  }
  @keyframes outpoint-scale {
    0%,
    50% {
      opacity: 0;
    }
    51% {
      opacity: 0;
      transform: scale(1.2);
    }
    55% {
      opacity: 1;
      transform: scale(1.2);
    }
    75% {
      transform: scale(1.8);
    }
    100% {
      opacity: 0;
      transform: scale(1.8);
    }
  }
}
</style>
