<script lang="jsx">
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import schttp from 'public/src/services/schttp'
import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { isLogin, getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import ClientOnly from 'vue-client-only'

import Carousels from './innerComponents/Carousels'
import MainPicture from 'public/src/pages/goods_detail_v2/innerComponents/top/MainPicture/index.vue'
// import Belt from 'public/src/pages/goods_detail/components/Belt'
import GoodsName from './innerComponents/GoodsName'
import GoodsNameV2 from 'public/src/pages/goods_detail_v2/innerComponents/top/GoodsName/index.vue'
import NewPrices from './innerComponents/Prices'
import PriceContainer from 'public/src/pages/goods_detail_v2/innerComponents/top/PriceContainer/index.vue'
import PriceTips from 'public/src/pages/goods_detail_v2/innerComponents/top/PriceTips/index.vue'

import NewUserCoupon from './innerComponents/Prices/components/NewUserCoupon'
import NewUserCouponFloor from 'public/src/pages/goods_detail_v2/innerComponents/top/NewUserCouponFloor/index.vue'
import PlaceholderGoodsInfo from './innerComponents/PlaceholderGoodsInfo'
import TopOther from './innerComponents/TopOther'
import SkcPrepose from './innerComponents/ColorBox/SkcPrepose.vue'
import ColorBox from './innerComponents/ColorBox'
import MainSaleAttr from 'public/src/pages/goods_detail_v2/innerComponents/top/MainSaleAttr/index.vue'
// import GeeneeArBox from 'public/src/pages/geenee_ar_box/container.vue'
import { PromotionUtils } from 'public/src/pages/common/promotion/utils'
import { getQueryString } from '@shein/common-function'
import { getRankingLink, isHasRankingLink } from 'public/src/pages/goods_detail/utils/productDetail'
// 依赖abt的资源加载 调整为动态
const SerialProducts = () =>
  import(/* webpackChunkName: "SerialProducts" */ './innerComponents/SerialProducts')
const NewSerialProducts = () =>
  import(/* webpackChunkName: "NewSerialProducts" */ 'public/src/pages/goods_detail_v2/innerComponents/top/SerialProducts/index.vue')
  
const PromotionEnter = () =>
  import(/* webpackChunkName: "PromotionEnter" */ './innerComponents/PromotionEnter')

const ExtraVoucherFloor = () =>
  import(/* webpackChunkName: "ExtraVoucherFloor" */ './innerComponents/ExtraVoucherFloor')

const TitleSellPoint = () =>
  import(
    /* webpackChunkName: "TitleSellPoint" */ './innerComponents/TitleSellPoint'
  )

const VipEstimatedTag = () =>
  import(
    /* webpackChunkName: "VipEstimatedTag" */ 'public/src/pages/goods_detail/top/Prices/MemberTag/VipEstimatedTag'
  )
const SheinClubPromotionFloor = () =>
  import(
    /* webpackChunkName: "SheinClubPromotionFloor" */ 'public/src/pages/goods_detail_v2/innerComponents/top/SheinClubPromotionFloor/index.vue'
  )

const ProductItemRankingList = () =>
  import(
    /* webpackChunkName: "ProductItemRankingList" */ 'public/src/pages/components/product/item_v2/components/ProductItemRankingList.vue'
  )

const DesignInfoEnter = () =>
  import(
    /* webpackChunkName: "DesignInfoEnter" */ 'public/src/pages/goods_detail/components/middle/innerComponents/MiddleAttr/components/DesignInfoEnter.vue'
  )
const PromotionDrawer = () =>
  import(
    /* webpackChunkName: "PromotionDrawer" */ 'public/src/pages/goods_detail/components/middle/innerComponents/PromotionEnterLayer/PromotionDrawer.vue'
  )
const GeeneeArBox = () =>
  import(
    /* webpackChunkName: "GeeneeArBox" */ 'public/src/pages/geenee_ar_box/container.vue'
  )

const PriceBelt = () =>
  import(
    /* webpackChunkName: "PriceBelt" */ 'public/src/pages/goods_detail/components/top/innerComponents/PriceBelt/index.vue'
  )

const QuickShipFloor = () =>
  import(
    /* webpackChunkName: "QuickShipFloor" */ 'public/src/pages/goods_detail/components/top/innerComponents/QuickShipFloor/index.vue'
  )

const Belt = () =>
  import(
    /* webpackChunkName: "Belt" */ 'public/src/pages/goods_detail/components/Belt/index.vue'
  )

const QuickShipDrawer = () =>
  import(
    /* webpackChunkName: "QuickShipDrawer" */ 'public/src/pages/goods_detail/components/top/innerComponents/QuickShipFloor/QuickShipDrawer.vue'
  )
  
const PromotionEnterNew = () => import(/* webpackChunkName: "PromotionEnterNew" */ 'public/src/pages/goods_detail_v2/innerComponents/top/PromotionEnter/index.vue')
const PromotionDrawerNew = () => import(/* webpackChunkName: "PromotionDrawerNew" */ 'public/src/pages/goods_detail_v2/innerComponents/drawer/PromotionDrawer/index.vue')

daEventCenter.addSubscriber({ modulecode: '1-6-1' })

export default {
  name: 'TopContent',
  components: {
    GeeneeArBox,
    Carousels,
    Belt,
    PriceBelt,
    SerialProducts,
    GoodsName,
    GoodsNameV2,
    TitleSellPoint,
    PriceContainer,
    PriceTips,
    NewPrices,
    NewUserCoupon,
    PlaceholderGoodsInfo,
    ColorBox,
    TopOther,
    PromotionEnter,
    VipEstimatedTag,
    PromotionDrawer,
    ClientOnly,
    SkcPrepose,
    PromotionEnterNew,
  },
  props: {
    topContentReady: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // goodsName 相关
      isShowMoreIcon: false,
      isTitleEllipsis: true,
      sharePanel: false,
      sharePopupReady: false,
      goodsLink: '',
      isGetAgain: false, // 是否再次领取
      isTouchMovedSellpoint: false,
      quickshipDrawerVisible: false
    }
  },
  computed: {
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapGetters('productDetail/promotion', ['new_user_coupon_info_floor', 'shein_club_promotion_floor_info']),
    
    ...mapGetters('productDetail/MainSaleAttr', [
      'skc_prepose_val',
      'is_show_Main_Attr',
    ]),
    
    ...mapState('newProductDetail/common', ['goodsReady', 'realTimeFirstReady', 'realTimeReady', 'fixedRatio', 'isLoginChange']),
    ...mapState('newProductDetail', ['showTryOnArContent']),
    ...mapState('newProductDetail/PromotionEnter', [
      'viewPromotionStatus'
    ]),
    ...mapGetters('newProductDetail/ShippingEnter', [
      'independfloorInfo',
    ]),
    ...mapState('newProductDetail/ColorBox', ['goodsImgMap']),
    ...mapGetters('newProductDetail', ['isPaidUser', 'isFreeReceiveUser', 'fsAbt']),
    ...mapGetters('newProductDetail/Top', [
      'titleSellPointsList',
      'isEvolushein',
      'topInfoBase',
      // 'abtPriceLocatTop',
      'isShowDesignerInfo',
      'lowestPriceTipsConfig',
      'designerInfo'
    ]),
    ...mapGetters('newProductDetail/common', [
      'isInFashionStore',
      'inFashionStoreImg',
      'detail',
      'goodsId',
      'productImgs',
      'showPlaceholderGoodsInfo',
      'skuCode',
      'promotionInfoDesc',
      'extraVoucherPromotionInfo',
      'newUserCouponInfo',
      'isStoreBusinessBrand',
      'isBrandHasTargetTag',
      'trendsInfo',
      'saleAttrsFoldAb',
      'isQualityStore'
    ]),
    ...mapGetters('newProductDetail/GoodsName', [
      'isBlack',
      'PUBLIC_CDN_rp',
      'badgeInfo',
      'isPreferredSeller',
      'goodsName',
    ]),
    ...mapGetters('newProductDetail/PromotionEnter', [
      'showPromotionEnter',
      'onlyOnePromotion',
      'activityMergeCouponDataList',
      'isShowPromo',
      'curCouponList',
      'getSeriesAndBrand',
      'drawerPaymentInfos'
    ]),
    ...mapGetters('newProductDetail/PriceBelt', [
      'showPriceBelt',
      'isPriceBannerTypeAbtNew'
    ]),
    ...mapGetters('newProductDetail/Price', ['commentOverview', 'estimatedInfo', 'vipInfo', 'priceAbtConfig', 'priceCommon', 'detailSheinClubContent', 'isEstimatedAntiContent']),
    ...mapGetters('newProductDetail/SerialProducts', ['showSerialProducts']),
    ...mapGetters('productDetail/SerialProducts', ['show_serial_products']),
    ...mapGetters('newProductDetail/ColorBox', ['skcPreposeVal', 'colorBoxInfo', 'isShowColors', 'isSkcPrepose']),
    ...mapGetters('newProductDetail/TopOther', [
      'rankingListInfo',
      'showRankingInfoLink',
      'rankingListIcon',
    ]),
    ...mapGetters('productDetail/promotionEnter', [
      'show_promotion_enter',
    ]),
    rankingAnalysisData() {
      const { contentCarrierId, carrierSubType } = this.rankingListInfo || {}
      let content_list = `${carrierSubType}\`${contentCarrierId}`
      let { comId, floorId } = this.rankingListInfo ?? {}
      let spm = ['53', 'GOODS', floorId, comId, '1', '6'].join('`')
      return {
        id: '1-6-1-118',
        data: {
          spm,
          content_list,
          src_module: 'recommend',
          from: 1,
        },
      }
    },
    cIsHasRankLinkStr() {
      const { title, carrierSubType } = this.rankingListInfo ?? {}
      // 用于判断榜单是否有链接，实际跳转链接在点击时计算
      return isHasRankingLink({ title, carrierSubType }) ? '/' : ''
    },
    showTrendsLabel(){
      return this.trendsInfo || this.isInFashionStore
    },
    showTitlesellpoint() {
      return ['plana', 'planb'].includes(this.fsAbt?.titlesellpoint?.p?.titlesellpoint)
    },
    isShowBrand() {
      // 满足品牌集成店 或者 100%正品标签 首屏标签显示brand标签
      return (this.isBrandHasTargetTag || this.isStoreBusinessBrand) && this.showTitlesellpoint
    },
    goodsNameLabels() {
      // 优选卖家 > new > trends > 本次新增的品质店铺shein choice > brand
      let labels = [
        { label: 'isPreferredSeller', value: this.isPreferredSeller, },
        { label: 'isNew', value: Boolean(this.badgeInfo), badgeInfo: this.badgeInfo },
        { label: 'showTrendsLabel', value: this.showTrendsLabel, },
        { label: 'isQualityStore', value: this.isQualityStore },
        { label: 'isEvolushein', value: this.isEvolushein && !this.showTitlesellpoint },
        { label: 'isShowBrand', value: this.isShowBrand },
      ]
      let filterLabels = labels.filter(i => i.value).splice(0, 2)
      return filterLabels || []
    },
    isEstimatedShowTypeNew() {
      let estimatedShowType = this.fsAbt?.EstimatedShowType?.p?.EstimatedShowType || ''
      let estimatedPrice = this.fsAbt?.EstimatedPrice?.p?.E_Price_Cal || ''
      return estimatedShowType === 'New' || ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'].includes(estimatedPrice)
    },
    showGoodsNameComment() {
      // const isSalePriceSPrice = !(this.fsAbt.SalePrice?.p?.S_Price === 'None' || !this.fsAbt.SalePrice?.p?.S_Price)
      const isSalePriceSPrice = !!this.fsAbt.SalePrice?.p?.S_Price && this.fsAbt.SalePrice?.p?.S_Price !== 'None'
      // isPriceBannerTypeAbtNew 表示是shein站点
      return this.commentOverview?.totalComment > 0 && this.commentOverview?.commentOverView?.comment_rank_average > 0 && !this.showTitlesellpoint && (this.isEstimatedShowTypeNew || this.isPriceBannerTypeAbtNew || isSalePriceSPrice)
    },
  },
  watch: {
    goodsReady: {
      handler(val) {
        if (typeof window !== 'undefined') {
          if (val) {
            //切换商品时也需要重新获取优惠卷数据
            this.isTitleEllipsis = true
            this.isTouchMovedSellpoint = false
            this.getRadioLoaded()
            this.quickshipDrawerVisible = false
          }
        }
      },
      immediate: true,
    },
    realTimeReady: {
      handler(val) {
        if (val) {
          // 加载计算goodsName高度
          this.calGoodsNameHeight()
          // 通知 header 商详信息
          this.setGoodsDetailInfoForRoot({
            goodsId: this.goodsId,
            catId: this.detail?.cat_id || '',
          })
        }
      },
      immediate: true,
    },
    // 监听登陆后，abt变化，右侧的入口可能会隐藏，引起宽度发生变化，重新计算高度
    showTitlesellpoint() {
      this.calGoodsNameHeight()
    },
    'lowestPriceTipsConfig.hitLowestPrice': {
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          daEventCenter.triggerNotice({
            daId: '1-6-1-172',
            extraData: { 
              priceday: newVal,
            },
          })
        }
      },
      immediate: true
    },
    isLoginChange(val) {
      if (val) {
        // todo: 更新旧 vuex 接入 hot 可删除
        if (!(typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD)) {
          this.updateEstimatedCouponList({ isOldCouponList: !this.estimatedInfo?.isEstimatedMatch })
        }
        if (this.isGetAgain) {
          this.getNewUserCoupon()
          this.isGetAgain = false
        }
      }
    },
  },
  mounted() {
    window.vBus && window.vBus.$on('addToBagSuccess', this.addToBagSuccess)
    // 色块加载完成后更新top内容加载状态
    this.getRadioLoaded()
    window.vBus && window.vBus.$on('handleHeaderShareForGoodsDetail', this.showShareChannel)
    appEventCenter.$on('close-popover-add-bag', this.switchColor)
  },
  beforeDestroy() {
    window.vBus && window.vBus.$off('addToBagSuccess', this.addToBagSuccess)
    window.vBus && window.vBus.$off('handleHeaderShareForGoodsDetail', this.showShareChannel)
    appEventCenter.$off('close-popover-add-bag', this.switchColor)
  },
  methods: {
    ...mapMutations(['setGoodsDetailInfoForRoot']),
    ...mapMutations('newProductDetail', ['updateShowTryOnArContent']),
    ...mapMutations('newProductDetail/common', ['updateColorsReady', 'updateFromSwitchColor', 'updateAttriBute', 'updateMainAttribute', 'updateIsLoginChange']),
    ...mapMutations('newProductDetail/CommentPopup', ['openViewCommentPop']),
    ...mapMutations('newProductDetail/PromotionEnter', [
      'updateViewPromotionStatus',
    ]),
    ...mapMutations('newProductDetail/ColorBox', ['updateLastClickColor']),
    ...mapActions('newProductDetail', ['showLoginModal', 'updateEstimatedCouponList']),

    // 新数据流
    ...mapActions('productDetail/common', ['switch_color']),
    // --end
    loginHandle() {
      if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD) {
        this.showLoginModal({
          params: {
            from: 'other',
          }
        })
        return
      }
      SHEIN_LOGIN.show({
        show: true,
        from: 'other',
        cb: () => {
          this.updateIsLoginChange(true)
        }
      })
    },
    getNewUserCoupon() {
      const { language } = this.priceCommon || {}
      // 点击领取新人券
      daEventCenter.triggerNotice({
        daId: '1-6-1-159',
        extraData: {
          operation_results: 1
        }
      })
      const { newUserCoupon } = this.newUserCouponInfo
      const isReceived = newUserCoupon?.bind_status === 1
      const isBindCoupon = newUserCoupon?.is_bind === 1
      if (isBindCoupon && !isReceived) {
        if (!isLogin()) {
          // 判断是否登录
          this.isGetAgain = true
          this.loginHandle()
          return
        }
        if (this.isShowPromo) {
          this.updateViewPromotionStatus({ status: true, viewPromotionAnchoringNewUserCoupon: newUserCoupon?.coupon_code  })
        }
        schttp({
          method: 'POST',
          url: '/api/productInfo/bindCoupon/post',
          data: {
            coupon_codes: [newUserCoupon?.coupon_code]
          }
        }).then(res => {
          this.updateEstimatedCouponList() // 更新到手价相关数据
          if (!res) return
          if (res.code == '0') {
            const success = res.info?.successList?.length
            if (res.info?.failureList?.length) { // 绑定失败
              const errorCode = res.info.failureList[0].code
              this.$toast({ content: language[errorCode] })
            }
            if (success) { // 绑定成功
              this.$toast(language.SHEIN_KEY_PWA_20962)
            }
            daEventCenter.triggerNotice({
              daId: '1-6-4-42',
              extraData: {
                coupon_id: newUserCoupon?.coupon_code,
                status: success ? 1 : 0,
                abtest: abtUserAnalysis({ posKeys: 'EstimatedPrice' })?.sa
              }
            })
          }
        })
      } else {
        if (this.isShowPromo) {
          this.updateViewPromotionStatus({ status: true, viewPromotionAnchoringNewUserCoupon: newUserCoupon?.coupon_code })
        }
      }
    },
    switchColor (item) {
      // TODO: 如果是新中间层融合走新的action
      if (item && this.MAIN_BFF_APOLLO?.v2) {
        this.switch_color({
          ...item,
          jumpCallBack: (url) => {
            this.$router.replace(url)
          }
        })
        return
      }
      // --end
      const { colorsInfo, detail, LAZY_IMG, langPath_rp, mallCode, goodsImgs, mainAttribute } = this.colorBoxInfo
      if(!item.fromSizeGroup && !colorsInfo.length) return
      if (detail.goods_id == item.goods_id) return
      this.updateLastClickColor(item.goods_id)
      // TODO MYTEST 这里需要注意
      this.updateFromSwitchColor(true)
      let goods_imgs = goodsImgs
      if (this.goodsImgMap[item.goods_id]) {
        goods_imgs.main_image = this.goodsImgMap[item.goods_id].main_image
        goods_imgs.detail_image = this.goodsImgMap[item.goods_id].detail_image
      } else {
        if (goods_imgs?.main_image) {
          goods_imgs.main_image.bfCache = ''
          goods_imgs.main_image.origin_image = LAZY_IMG
        }
        goods_imgs?.detail_image?.forEach?.(item => {
          item.origin_image = LAZY_IMG
        })
      }
      this.updateAttriBute(''),
      this.updateMainAttribute(mainAttribute)
      if (typeof gbAddBagTraceFrom === 'function') {
        try {
          colorsInfo.forEach(goods => {
            const from = gbAddBagTraceFrom('getProductFrom', { goods_id: goods.goods_id })
            if (from != 'other') gbAddBagTraceFrom('setProductFrom', { goods_id: item.goods_id, from: from })
          })
        } catch (err) { console.log(err) }
      }
      let search = location.search.replace(/&?(attr_ids|main_attr|mallCode|isFromSwitchColor)=(\w|_)+/g, '')
      search = search + (search.indexOf('?') > -1 ? '&main_attr=' + mainAttribute : '?main_attr=' + mainAttribute)
      search = search + (search.indexOf('?') > -1 ? '&mallCode=' + mallCode : '?mallCode=' + mallCode)
      search = search + (search.indexOf('?') > -1 ? '&isFromSwitchColor=1' : '?isFromSwitchColor=1')
      let url = `${langPath_rp}/${getGoodsUrl(item.goods_url_name, item.goods_id, item.cat_id)}` + search
      this.$router.replace(url)
    },
    /** Top */
    addToBagSuccess() {
      const { language } = this.topInfoBase
      const activeElem = document.activeElement
      const elem = document.querySelector('#add-to-bag-success') || document.createElement('div')
      elem.setAttribute('id', 'add-to-bag-success')
      elem.setAttribute('aria-label', language?.SHEIN_KEY_PWA_15021)
      elem.setAttribute('tabindex', '0')
      elem.setAttribute('type', 'text')
      elem.setAttribute('role', 'text')
      elem.style.cssText = 'position: absolute;visibility: hidden;'
      try {
        document.body.append(elem)
      } catch (e) {
        document.body.appendChild(elem)
      }
      setTimeout(() => {
        elem.style.top = document.scrollingElement.scrollTop + 100 + 'px'
        elem.focus()
        setTimeout(() => {
          activeElem.focus()
          setTimeout(() => {
            try {
              document.body.removeChild(elem)
            } catch (e) {
              console.log()
            }
          }, 2000)
        }, 1000)
      }, 3000)
    },
    /** Top 结束 */

    /** goodsName 开始 */
    calGoodsNameHeight() {
      if (this.MAIN_BFF_APOLLO?.v3) return
      this.isShowMoreIcon = false
      this.$nextTick(() => {
        let titleDom = this.$refs.goodsDetailTitle
        let descHeight = titleDom?.offsetHeight
        // 超两行省略出现下拉按钮（含标签lineheight:16， 纯文本lineheight:14）
        this.isShowMoreIcon = descHeight > 35
      })
    },
    toogleGoodsNameEllipsis() {
      this.isTitleEllipsis = !this.isTitleEllipsis
    },
    touchMoveSellpoint() {
      // 商品标签sellpoint滑动上报
      if(!this.isTouchMovedSellpoint){
        this.isTouchMovedSellpoint = true
        daEventCenter.triggerNotice({
          daId: '1-6-1-226',
        })
      }
    },
    async showShareChannel() {
      // 命中新组件走新组件内部的分享逻辑
      if (this.MAIN_BFF_APOLLO?.v3) return
      if (this._isLock) {
        return
      }
      this._isLock = true
      this.sharePopupReady = true
      const { langPath_rp, host_rp, IS_RW, SiteUID, detail } = this.topInfoBase
      let shareUrl = ''
      let goodsLink =
        host_rp + langPath_rp + getGoodsUrl(detail.goods_url_name, detail.goods_id, detail.cat_id)
      if (!IS_RW && isLogin()) {
        let json = await schttp({
          url: '/api/marketing/shareUrl/get',
          method: 'POST',
          data: {
            shareUrl: goodsLink,
            pageType: 1,
          },
        })
        if (json?.info?.url?.indexOf('url_from') > -1) {
          this.goodsLink = shareUrl = encodeURIComponent(
            decodeURIComponent(json.info.url) + '&share_from=' + SiteUID
          )
        }
      }
      if (!shareUrl) {
        this.goodsLink = goodsLink + encodeURIComponent('?share_from=' + SiteUID)
      }
      this.sharePanel = true
      this._isLock = false
    },
    closeSharePopup() {
      this.sharePanel = false
    },
    /** goodsName 结束 */

    /**
     * 进入促销页 | 打开促销弹窗
     */
    openPromotion() {
      if (!this.isShowPromo) return
      const promotionItem = this.promotionInfoDesc?.[0] || {}
      const { rules, typeId, rangeList, scId  } = promotionItem
      const isPerSave = typeId == 14 && [2, 4].includes(Number(rules?.[0]?.type))
      const isMultiPromotion = rangeList?.length && !isPerSave
      if(this.onlyOnePromotion && (typeId == 10 || scId) && !isMultiPromotion){
        PromotionUtils.jumpToAdd(promotionItem, 'goodsDetail')
        return
      }
      this.updateViewPromotionStatus({ status: true })
      if (this.curCouponList.length) {
        daEventCenter.triggerNotice({
          daId: '1-6-4-41',
        })
      }
    },
    openExtraVoucherFloorPromotion() {
      this.updateViewPromotionStatus({ status: true, viewPromotionAnchoringExtraVoucher: true })
      daEventCenter.triggerNotice({
        daId: '1-6-4-94',
        extraData: {
          location: 1
        }
      })
    },
    /** promotionEnter 结束 */

    /** 加载完成后更新colorReady */
    getRadioLoaded() {
      try {
        const radislist = document.querySelectorAll('.S-radio-color__inner') || []
        if (!radislist.length) {
          this.updateColorsReady(true)
          return
        }
        const imgs = []
        radislist.forEach((item) => {
          const imgBgSrc = item.getAttribute('style')
          imgBgSrc.replace(/url\(\"?\/\/(.+?)\"?\)/, (_, p1) => {
            imgs.push(`//${p1}`)
          })
        })

        if (!imgs.length) {
          this.updateColorsReady(true)
          return
        }

        const len = imgs.length
        let count = 0
        new Promise((resolve) => {
          imgs.forEach((item) => {
            const img = new Image()
            img.src = item
            const add = () => {
              count++
              if (count === len) {
                resolve()
              }
            }

            img.addEventListener('load', () => {
              add()
            })

            img.addEventListener('error', () => {
              add()
            })
          })
        }).then(() => {
          this.updateColorsReady(true)
        })
      } catch (err) {
        this.updateColorsReady(true)
        console.log(err)
      }
    },
    goToDesignerPage() {
      if (!this.designerInfo.sheinx_design_id) return
      const { langPath } = gbCommonInfo
      daEventCenter.triggerNotice({ daId: '1-6-4-70' })
      const href = `${langPath}/designer/${this.designerInfo.sheinx_design_id}`
      markPoint('toNextPageClick', 'public')
      this.$router.push(href)
    },
    getRankingLink () {
      const { topInfoBase, rankingListInfo, fsAbt } = this
      if (typeof window === 'undefined') return ''
      let ici = getQueryString({ key: 'ici' }) || ''
      let { contentCarrierId, itemInfoId, carrierTempId, title, carrierSubType, cateIds, composeGoodsId } = rankingListInfo ?? {}
      let { activityName } = rankingListInfo?.templateInfo ?? {}
      return getRankingLink({
        contentCarrierId,
        itemInfoId: itemInfoId, // 新数据无
        carrierTempId, // 新数据无
        goods_id: topInfoBase?.detail?.goods_id,
        title,
        carrierSubType,
        cateIds,
        composeGoodsId, // 新数据无
        activityName, // 新数据无
        tab_page_id: window.getSaPageInfo?.tab_page_id || '',
        ici,
        abtInfo: fsAbt,
      })
    },
    clickRankingList() {
      daEventCenter.triggerNotice({
        daId: '1-6-1-119',
        extraData: this.rankingAnalysisData.data,
      })

      // 强制获取最新的链接，tab_page_id有可能有变化
      const newLink = this.getRankingLink()
      if (newLink) location.href = newLink
    },
    clickQuickShipFloor() {
      this.quickshipDrawerVisible = true
      daEventCenter.triggerNotice({
        daId: '1-6-1-236',
        extraData: {
          keyinformation: this.independfloorInfo.keyinformation
        }
      })
    }
  },
  render() {
    const {
      // common
      showPlaceholderGoodsInfo,
      goodsReady,
      // abtPriceLocatTop,
      topInfoBase = {},
      showSerialProducts,
      show_serial_products,
      // goodsName
      isBlack,
      inFashionStoreImg,
      goodsName,
      isShowMoreIcon,
      titleSellPointsList,
      // designerInfo
      isShowDesignerInfo,
      designerInfo,
      // promotionEnter
      showPromotionEnter,
      promotionInfoDesc,
      activityMergeCouponDataList,
      isShowPromo,
      curCouponList,
      getSeriesAndBrand,
      viewPromotionStatus,
      independfloorInfo,
      skuCode,
      showPriceBelt,
      topContentReady, // TODO 新数据流
      skcPreposeVal, // 判断skc是否前置
      drawerPaymentInfos,
      extraVoucherPromotionInfo,
    } = this
    const { PUBLIC_CDN_rp, language, GB_cssRight } = topInfoBase
    const {
      showVipEstimatedTag,
      vipEstimatedText,
      vipEstimatedClass,
      isEstimatedMatch
    } = this.estimatedInfo || {}
    const { paidVipData } = this.vipInfo || {}
    const { newUserCoupon, newUserDiscount, newUserCouponRule } = this.newUserCouponInfo || {}
    const showNewuserCoupon = this.priceAbtConfig.NewCoupon === 'New1' && isEstimatedMatch && newUserCoupon

    const getRankingAnalysis = {
      directives: [
        {
          name: 'expose',
          value: {
            id: '1-6-1-118',
            data: this.rankingAnalysisData.data
          }
        }
      ]
    }

    const renderMainSaleAttrDefault = () => {
      let isSupportCropImage = false
      if (typeof gbCommonInfo !== 'undefined') {
        isSupportCropImage = gbCommonInfo?.RESOURCE_SDK?.isSupportCropImage
      }
      if (this.MAIN_BFF_APOLLO?.v2) {
        return this.is_show_Main_Attr ? <MainSaleAttr isSupportCropImage={isSupportCropImage} /> : null
      }
      if (!this.saleAttrsFoldAb && (this.isShowColors && !this.isSkcPrepose)) {
        return <ColorBox fixed-ratio={this.fixedRatio} switchColor={this.switchColor} isSupportCropImage={isSupportCropImage}/>
      }
    }

    const renderMainSaleAttrPreposeA = () => {
      if (this.MAIN_BFF_APOLLO?.v2) {
        return this.skc_prepose_val === 'A' ? <MainSaleAttr /> : null
      }
      return skcPreposeVal === 'A' ? <SkcPrepose switchColor={this.switchColor}/> : null
    }

    const renderMainSaleAttrPreposeB = () => {
      if (this.MAIN_BFF_APOLLO?.v2) {
        return this.skc_prepose_val === 'B' ? <MainSaleAttr /> : null
      }
      return skcPreposeVal === 'B' ? <SkcPrepose switchColor={this.switchColor}/> : null
    }

    const renderGoodsName = () => {
      if (this.MAIN_BFF_APOLLO?.v3) {
        return <GoodsNameV2 />
      }
      return <GoodsName
        goods-id={this.goodsId}
        is-show-more-icon={isShowMoreIcon}
        goods-name-data={{
          PUBLIC_CDN_rp,
          isBlack,
          language,
          goodsName,
          inFashionStoreImg,
        }}
        goods-name-labels={this.goodsNameLabels}
        share-panel-data={{
          sharePanel: this.sharePanel,
          sharePopupReady: this.sharePopupReady,
          goodsLink: this.goodsLink,
          productImgs: this.productImgs,
        }}
        is-title-ellipsis={this.isTitleEllipsis}
        comment-overview={this.commentOverview}
        show-price-belt={this.showPriceBelt}
        show-goods-name-comment={this.showGoodsNameComment}
        is-estimated-show-type-new={this.isEstimatedShowTypeNew}
        is-price-banner-type-abt-new={this.isPriceBannerTypeAbtNew}
        fs-abt={this.fsAbt}
        trends-info={this.trendsInfo}
        vOn:toogleGoodsNameEllipsis={this.toogleGoodsNameEllipsis}
        vOn:showShareChannel={this.showShareChannel}
        vOn:closeSharePopup={this.closeSharePopup}
        vOn:handleClickComment={this.openViewCommentPop}
      /> 
    }
    const renderTitleSellerPoint = () => {
      if (this.MAIN_BFF_APOLLO?.v3) {
        // 迁移至GoodsNameV2
        return null
      }
      return titleSellPointsList.length > 1 ?
        <TitleSellPoint 
          sellPointsList={titleSellPointsList}
          language={language}
          vOn:touchMoveSellpoint={this.touchMoveSellpoint}
        /> : null
    }
    const renderPromotionEnter = () => {
      if (this.MAIN_BFF_APOLLO?.v3) {
        const { show_promotion_enter } = this
        if (!show_promotion_enter) return
        return <PromotionEnterNew />
      }

      if (!showPromotionEnter) return
      return (
        <PromotionEnter
          is-show-promo={isShowPromo}
          activityMergeCouponDataList={activityMergeCouponDataList}
          promotionOtherInfo={{
            promotionInfoDesc,
            getSeriesAndBrand,
            curCouponList,
            GB_cssRight,
            drawerPaymentInfos
          }}
          vOn:openPromotion={this.openPromotion}
        />
      )
    }

    const renderPromotionDrawer = () => {
      if (this.MAIN_BFF_APOLLO?.v3) {
        if (!viewPromotionStatus) return null
        return <PromotionDrawerNew class="bff-new"
          show={viewPromotionStatus}
          language={language}
          coupon-language={language}
        />
      }

      if (!viewPromotionStatus) return null
      return <PromotionDrawer
        show={viewPromotionStatus}
        language={language}
        coupon-language={language}
        promotion-info-desc={promotionInfoDesc}
      />
    }

    // 商品主图
    const renderMainPicture = () => {
      if (this.MAIN_BFF_APOLLO?.v4) {
        return <MainPicture topContentReady={topContentReady}/>
      }
      return <Carousels topContentReady={topContentReady}/>
    }

    return (
      <div
        class="goods-detail-top"
        data-floor-tab="goods"
      >
        <div class="goods-detail-top__CarouselsBeltBox">
          
          {/* 商品主图入口 */}
          { renderMainPicture() }

          {this.topContentReady ? <Belt /> : null}
          { !showPlaceholderGoodsInfo && renderMainSaleAttrPreposeA() }
          {showPriceBelt ? (this.MAIN_BFF_APOLLO?.v3 ? <PriceContainer /> : <PriceBelt />) : null}
          { !showPlaceholderGoodsInfo && renderMainSaleAttrPreposeB() }
          {this?.showTryOnArContent ?
            <div class="goods-detail-top__arContent">
              <GeeneeArBox
                skuCode={skuCode}
                vOn:close={() => {
                  this.updateShowTryOnArContent(false)
                }}
                vOn:careBearsStarted={() => {
                  daEventCenter.triggerNotice({ daId: '1-6-1-165' })
                }}
                vOn:careBearsFinished={() => {
                  daEventCenter.triggerNotice({ daId: '1-6-1-166' })
                }}
                vOn:careBearsChangeSku={(skuCode) => {
                  console.log(12333333, skuCode)
                }}
              />
            </div>
            : null}
        </div>
        <div class={[
          'goods-detail-top__info-container',
          { 'pdt-0': showPriceBelt }
        ]}>
          {showPlaceholderGoodsInfo ? (
            <PlaceholderGoodsInfo />
          ) : (
            <div>
              {/* 套装数据全部来自client storage 完全放置前端渲染就可以 */}
              {
                this.MAIN_BFF_APOLLO?.v4 ? (
                  <ClientOnly>{goodsReady && show_serial_products && <NewSerialProducts />}</ClientOnly>
                ) : (
                  <ClientOnly>{goodsReady && showSerialProducts && <SerialProducts />}</ClientOnly>
                )
              }

              {!this.MAIN_BFF_APOLLO?.v3 ? <NewPrices /> : null}
              {this.MAIN_BFF_APOLLO?.v3 && !showPriceBelt ? <PriceContainer /> : null}
              {this.MAIN_BFF_APOLLO?.v3 ? <PriceTips /> : null}

              {/* 促销入口 */}
              {renderPromotionEnter()}

              {!this.MAIN_BFF_APOLLO?.v3 && showNewuserCoupon ? (
                <NewUserCoupon
                  language={language}
                  newUserCoupon={newUserCoupon}
                  newUserDiscount={newUserDiscount}
                  newUserCouponRule={newUserCouponRule}
                  vOn:get-coupon={this.getNewUserCoupon}
                ></NewUserCoupon>
              ) : null}
              {this.MAIN_BFF_APOLLO?.v3 && this.new_user_coupon_info_floor ? <NewUserCouponFloor new-user-coupon-info-floor={this.new_user_coupon_info_floor} vOn:clickNewUserCouponFloor={this.getNewUserCoupon}/> : null}


              <ClientOnly>
                {extraVoucherPromotionInfo && extraVoucherPromotionInfo.isExtraVoucherFloorType ? (
                  <ExtraVoucherFloor
                    extraVoucherPromotionInfo={extraVoucherPromotionInfo}
                    nativeOnClick={this.openExtraVoucherFloorPromotion}
                  />
                ) : null}
              </ClientOnly>
              {/* 付费会员 */}
              <ClientOnly>
                {!this.MAIN_BFF_APOLLO?.v3 && !!showVipEstimatedTag ? (
                  <VipEstimatedTag
                    location="page"
                    text={vipEstimatedText}
                    language={language}
                    paid-vip-data={paidVipData}
                    is-paid-user={this.isPaidUser}
                    is-free-receive-user={this.isFreeReceiveUser}
                    public-cdn={PUBLIC_CDN_rp}
                    detail={this.detail}
                    vip-estimated-class={vipEstimatedClass}
                    is-estimated-anti-content={this.isEstimatedAntiContent}
                    detail-shein-club-content={this.detailSheinClubContent}
                  />
                ) : null}
                {this.MAIN_BFF_APOLLO?.v3 && !!this.shein_club_promotion_floor_info ? <SheinClubPromotionFloor shein-club-promotion-floor-info={this.shein_club_promotion_floor_info} /> : null}
              </ClientOnly>
              { renderGoodsName() }
              { renderTitleSellerPoint() }
              <ClientOnly>
                {
                  independfloorInfo.show && <QuickShipFloor language={language} independfloorInfo={independfloorInfo} v-on:clickFloor={ this.clickQuickShipFloor }/>
                }
              </ClientOnly>
              <ClientOnly>
                {
                  independfloorInfo.show && this.quickshipDrawerVisible &&
                    <QuickShipDrawer 
                      independfloorInfo={independfloorInfo}
                      detail={this.detail}
                      language={language}
                      v-on:updateVisible={(val) => { this.quickshipDrawerVisible = val }}
                    />
                }
              </ClientOnly>
              {isShowDesignerInfo && (
                <DesignInfoEnter
                  designer-info={designerInfo}
                  position-style="underName"
                  vOn:goToDesignerPage={this.goToDesignerPage}
                />
              )}
              {/* <!-- 榜单 --> */}
              {(this.rankingListInfo && this.showRankingInfoLink) && (
                <div {...getRankingAnalysis}>
                  <ProductItemRankingList
                    ranking-list={this.rankingListInfo}
                    size="big"
                    style-type='B'
                    icon-link={this.rankingListIcon}
                    link={this.cIsHasRankLinkStr}
                    block-click={true}
                    vOn:clickLink={this.clickRankingList}
                  />
                </div>
              )}
              {/* <!-- 色块组件(jsx) --> */}
              { renderMainSaleAttrDefault() }
              <ClientOnly>
                <TopOther />
              </ClientOnly>
            </div>
          )}
        </div>

        {/* <!-- todo后面改造到goods_detail 页面级去，用全局state控制， 不要放在top里 --> */}
        <ClientOnly>
          {renderPromotionDrawer()}
        </ClientOnly>
        <div class="goods-detail__recommend-drawer"></div>
      </div>
    )
  },
}
</script>

<style lang="less">
.goods-detail-top {
  padding: 0 0.32rem;
  background-color: #fff;

  .goods-detail-top__info-container {
    // padding-top: 0.2133rem;
    margin-top: 0.2703rem;
  }

  .goods-detail-top__CarouselsBeltBox {
    position: relative;
    background-color: #fff;
  }

  .goods-detail-top__arContent {
    z-index: @zindex-modal-mask;
    position: absolute;
    top: 0;
    left: -0.32rem;
    width: calc(100% + 0.64rem);
    height: 100%;
  }

  .pdt-0 {
    padding-top: 0;
  }
}
</style>
