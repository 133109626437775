var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"goods-qty__com j-qty-com"},[_c('div',{staticClass:"calc"},[_c('span',{class:['iconfont', 'icon-minus', _vm.addToBagForm.quantity <= 1 ? 'disabled' : ''],on:{"click":_vm.handleReduce}}),_vm._v(" "),_c('input',{attrs:{"type":"text","disabled":_vm.addCartUpperLimit === 0,"readonly":""},domProps:{"value":_vm.value}}),_vm._v(" "),_c('span',{class:['iconfont', 'icon-normal-plus', _vm.addCartUpperLimit <= _vm.value ? 'disabled' : ''],on:{"click":_vm.handleAdd}})]),_vm._v(" "),_c('div',{staticClass:"goods-qty__tips"},[(_vm.buyMultipleText)?_c('p',{staticClass:"goods-qty__buyMultipleText"},[_c('Icon',{staticStyle:{"margin-right":"2px"},attrs:{"name":"sui_icon_activity_orange_12px","size":"12px"}}),_vm._v("\n      "+_vm._s(_vm.buyMultipleText)+"\n    ")],1):_vm._e(),_vm._v(" "),(_vm.buyMultipleText && _vm.stockRule)?_c('div',{staticClass:"goods-qty__tips-gap"}):_vm._e(),_vm._v(" "),(_vm.stockRule)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({ 
        id: '1-6-1-235',
        data: {
          goods_level: _vm.currentMall?.sku_large_ship !== undefined ? 'sku' : 'skc',
          label_type: _vm.currentMall?.is_absolute_low_inventory ? 1 : 2,
          location: 'qty',
        }
      }),expression:"{ \n        id: '1-6-1-235',\n        data: {\n          goods_level: currentMall?.sku_large_ship !== undefined ? 'sku' : 'skc',\n          label_type: currentMall?.is_absolute_low_inventory ? 1 : 2,\n          location: 'qty',\n        }\n      }"}],staticClass:"goods-qty__tips-stock",class:[_vm.stock === 0 ? 'grey' : 'orange', _vm.saleAttrsFoldAb ? 'fold' : '']},[_c('Icon',{staticStyle:{"margin-right":"2px"},attrs:{"name":"sui_icon_hourglass_12px_2","size":"12px"}}),_vm._v("\n      "+_vm._s(_vm.stockTip)+"\n    ")],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }