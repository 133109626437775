<script lang="jsx">
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { template } from '@shein/common-function'
import EstimatedTag from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/EstimatedTag/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import CommentOverview from './components/CommentOverview'
import ClientOnly from 'vue-client-only'
import PriceLeft from './components/PriceLeft'
import NewEstimatedPrice from './components/NewEstimatedPrice'
import LowestPriceTips from './components/LowestPriceTips'
import schttp from 'public/src/services/schttp'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import AddOnItem from 'public/src/pages/common/add-on/index.js'

daEventCenter.addSubscriber({ modulecode: '1-6-1' })
export default {
  name: 'NewPrice',
  components: {
    EstimatedTag,
    CommentOverview,
    ClientOnly,
    PriceLeft,
    NewEstimatedPrice,
    LowestPriceTips,
    AfterPayInfo: () =>
      import(/* webpackChunkName: "price-bottom-extend" */ './components/AfterPayInfo'),
    FlashSaleDiff: () =>
      import(/* webpackChunkName: "price-bottom-extend" */ './components/FlashSaleDiff'),
    ExclusivePrice: () =>
      import(/* webpackChunkName: "price-bottom-extend" */ './components/ExclusivePrice'),
    UnderPriceEntry: () =>
      import(/* webpackChunkName: "price-bottom-extend" */ './components/UnderPriceEntry'),
    // PaidVip: () =>
    //   import(
    //     /* webpackChunkName: "price-bottom-extend" */ 'public/src/pages/components/product/PaidVip'
    //   ),
    AppExclusiveDiscount: () =>
      import(
        /* webpackChunkName: "price-bottom-extend" */ 'public/src/pages/components/product/AppExclusiveDiscount'
      )
  },
  props: {
    isBeltType: {
      type: Boolean,
      default: false
    },
    isBeltTypeBrand: {
      type: Boolean,
      default: false
    },
    isFloorType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showVipExplaination: false,
      showInclusiveTips: false,

      commentStyle: {
        width: '',
        top: 0,
        display: 'none'
      },
      showAfterPayList: false, // 展示分期付款列表
      loginStatus: false,
      exposeStatus: {
        lowest: false
      }
    }
  },
  computed: {
    ...mapState('newProductDetail/PromotionEnter', [
      'viewPromotionIsQuickAdd'
    ]),
    ...mapState('productDetail', ['sheinClubUserInfo']),
    ...mapState('newProductDetail/common', ['addToBagForm', 'quickShip', 'isLoginChange', 'realTimeReady']),
    ...mapGetters('newProductDetail', ['isPaidUser', 'isFreeReceiveUser', 'fsAbt']),
    ...mapGetters('newProductDetail/common', ['goodsId', 'mallCode', 'complianceMode', 'complianceModeDe']),
    ...mapGetters('newProductDetail/Top', ['bestDealConfig']),
    ...mapGetters('newProductDetail/Price', [
      'isEur',
      'appExclusiveData',
      'iconListData',
      'salePrice',
      'commentOverview',
      'exclusiveInfo',
      'priceCommon',
      'priceFlashInfo',
      'priceAbtConfig',
      'estimatedInfo',
      'afterPayInfoList',
      // 'addToBagForm',
      'discountInfo',
      'isInversionDe',
      'vipInfo',
      'getPrice',
      'sheinClubInfo',
      'taxIncludedConfig',
      'isInversion'
    ]),
    ...mapGetters('newProductDetail/common', [
      'priceDetect',
      'showFrom',
      'getEstimatedInfo',
      'promotionInfo',
      'detail',
      'price',
    ]),
    ...mapGetters('newProductDetail/PriceBelt', [
      'showPriceBelt',
      'isPriceBannerTypeAbtNew'
    ]),
    showCommentOverview() {
      const { S_Price } = this.fsAbt?.SalePrice?.p || {}
      const isNotSalePriceAb = S_Price === 'None' || !S_Price
      const EstimatedShowType = this.estimatedInfo?.getEstimatedInfo?.isAb || this.priceAbtConfig?.EstimatedShowType === 'New'
      return !!isNotSalePriceAb && !this.isPriceBannerTypeAbtNew && !this.isFloorType && !EstimatedShowType && !!this.commentOverview?.totalComment
    },
    optimalCoupon() {
      let couponList = this.getEstimatedInfo?.coupons
      let bestCoupon = couponList?.find(item => {
        return item.coupon_code === this.getEstimatedInfo?.optimalCoupon?.coupon_code
      })
      return bestCoupon
        ? {
          ...this.getEstimatedInfo?.optimalCoupon,
          ...bestCoupon,
          rule: this.getEstimatedInfo?.optimalCoupon?.rule
        }
        : {}
    },
    optimalCouponCode() {
      return this.optimalCoupon?.coupon_code
    },
  },
  watch: {
    appExclusiveData: {
      handler(val) {
        if (typeof window !== 'undefined') {
          this.controlBranch(val)
        }
      },
      immediate: true
    },
    goodsId: {
      handler() {
        if (typeof window !== 'undefined') {
          this.$nextTick(() => {
            this.exposeLowestTip()
            this.exposeTax()
          })
        }
      },
      immediate: true
    },
    isLoginChange() {
      this.loginStatus = true

      // todo: 更新旧 vuex 接入 hot 可删除
      if (!(typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD)) {
        this.updateEstimatedCouponList({ isOldCouponList: !this.estimatedInfo?.isEstimatedMatch })
      }

      this.$store.commit('newProductDetail/updateIsLogin', true)
    },
    commentOverview(val) {
      if (val.totalComment) {
        this.setCommentStyle()
      }
    }
  },
  async mounted() {
    this.loginStatus = isLoginFn()
    this.handleInclusiveShow()
    this.setCommentStyle()
    this.$store.commit('newProductDetail/updateIsLogin', this.loginStatus)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handlesCloseTip)
    document.removeEventListener('touchstart', this.handlesCloseTip)
  },
  methods: {
    template,
    ...mapActions('newProductDetail', [
      'updateEstimatedCouponList'
    ]),
    ...mapActions('newProductDetail', ['showLoginModal']),
    ...mapMutations('newProductDetail/PromotionEnter', [
      'updateViewPromotionStatus',
    ]),
    ...mapMutations('newProductDetail/CommentPopup', ['openViewCommentPop']),
    ...mapMutations('newProductDetail/common', [
      'updateBuyMultipleQuickAddState',
    ]), // 新的
    async asyncOldComponentsData() {
      const [primeUserStatus, primeUserDetail] = await Promise.all([
        this.getPrimeUserInfo(),
        this.getPrimeUserDetail()
      ])
      this.updateIsPaidUser = primeUserStatus
      this.analysisData = {
        prime_level: primeUserDetail?.info?.product_info?.product_cycle_type || 0,
        total_saving: (primeUserDetail?.info?.discount_summary_info?.discount_price || 0).toFixed(
          2
        ),
        location: this.location,
        from: 1
      }
      this.getSheinClubUserInfo({
        isSheinClubUser: primeUserStatus,
        prime_level: primeUserDetail?.info?.product_info?.product_cycle_type || 0,
        total_saving: (primeUserDetail?.info?.discount_summary_info?.discount_price || 0).toFixed(2)
      })
    },
    async getPrimeUserInfo() {
      const isLogin = this.loginStatus
      if (!isLogin) {
        sessionStorage.removeItem('_IS_SHEIN_CLUB_USER')
        sessionStorage.removeItem('_GET_SHEIN_CLUB_USER_MEMBER_ID')
      }
      if (this.isSheinClubUser) return false
      let status = false
      if (isLogin) {
        const cacheIsSheinClubUser = sessionStorage.getItem('_IS_SHEIN_CLUB_USER')
        const cacheMemberId = sessionStorage.getItem('_GET_SHEIN_CLUB_USER_MEMBER_ID')
        const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'detail/price' })
        if (cacheIsSheinClubUser && cacheMemberId === memberId) {
          status = Number(cacheIsSheinClubUser) === 1
        } else {
          const res = await schttp({
            url: '/api/prime/postQueryMemberInfo/get',
            method: 'POST'
          })
          status = Number(res?.info?.is_paid) === 1
          sessionStorage.setItem('_IS_SHEIN_CLUB_USER', res?.info?.is_paid)
          sessionStorage.setItem('_GET_SHEIN_CLUB_USER_MEMBER_ID', memberId)
        }
      }
      return status
    },
    async getPrimeUserDetail() {
      if (!isLoginFn()) return {}
      const res = await schttp({
        url: '/api/prime/postPaidDetail/get?money=USD',
        method: 'POST'
      })
      return res
    },
    getSheinClubUserInfo(params) {
      this.updateSheinClubUserInfo(params)
    },
    inclusiveClick() {
      this.showInclusiveTips = !this.showInclusiveTips
    },
    handleInclusiveShow() {
      if (!localStorage.getItem('product_detail_inclusive_tip')) {
        this.showInclusiveTips = true
        localStorage.setItem('product_detail_inclusive_tip', '1')
        setTimeout(() => {
          this.showInclusiveTips = false
        }, 3000)
      }
      document.addEventListener('scroll', this.handlesCloseTip)
      document.addEventListener('touchstart', this.handlesCloseTip, false)
    },
    handlesCloseTip() {
      this.showInclusiveTips = false
    },
    clickEstimatedTag() {
      const clickjumpath = this.fsAbt?.collestrategy?.p?.clickjumpath || ''
      const { isSatisfied, isSatisfiedBuyMultiple, isAb, isAbCoexist } = this.getEstimatedInfo || {}

      if (this.fsAbt.Nestprice?.p?.Nestprice === 'addcartpopup' && isSatisfiedBuyMultiple) {
        return this.updateBuyMultipleQuickAddState({ show: true, from: 1 }) // 打开快速加车加购N件
      }

      if (clickjumpath !== 'gatherpopup' || isSatisfied || isSatisfiedBuyMultiple || isAb || isAbCoexist) {
        this.updateViewPromotionStatus({ status: true })
        return
      }

      // this.updateViewPromotionStatus({ status: true })
      // 平台券-券适用范围为全部商品的应付价折扣的平台券
      // 唤起凑单弹窗
      if (this.viewPromotionIsQuickAdd) appEventCenter.$emit('quickAddOpenAddOn')
      let self = this
      const isTopGoods = this.fsAbt?.Gatheringorders?.p?.Gatheringorders === 'Topping'
      const isOpenRecommend = this.fsAbt?.EstimatedNothreShowType?.p?.recommendtype == '1'
      const queryInfo = {
        addOnType: 2, // 问产品拿
        sceneId: 152, // 推荐场景id
        cateIds: [this.detail?.cat_id]
      }
      if(isTopGoods) {
        // 置顶主skc
        queryInfo.adp = [this.detail?.goods_id]
      }else{
        queryInfo.goodsIds = [this.detail?.goods_id]
      }
      if(isOpenRecommend) {
        // 基于商详商品推出凑单商品
        queryInfo.mainGoodsIds = [this.detail?.goods_id]
      }
      // @@seb TODO ...mapActions('productDetail/common', ['add_on_item_open']),
      AddOnItem.open({
        props: {
          type: 'coupon',
          coupon: this.optimalCouponCode, // **券码** 必传！！！
          queryInfo,
          saInfo: {
            // 主要用于埋点
            activity_from: 'goods_detail_coupon_add',
            state: 'goods_detail_coupon_add'
          },
          config: {
            isClickToDetail: true,
          },
        },
        on: {
          close () {
            if (self.viewPromotionIsQuickAdd) self.isCloseAddOnItem = true
          }
        }
      })
    },
    setCommentStyle() {
      if (this.showCommentOverview) {
        this.$nextTick(() => {
          const mainPriceDom = document?.querySelector?.('.goods-price__prices-dom')
          const goodsPriceDom = document?.querySelector?.('.goods-price-dom')
          const commentOverviewDom = document?.querySelector?.('.goods-price__comment-overview')

          let containerOffsetTop = goodsPriceDom ? goodsPriceDom.getBoundingClientRect().top : 0
          let mainPriceOffsetTop = mainPriceDom ? mainPriceDom.getBoundingClientRect().top : 0
          let commentWith = commentOverviewDom ? commentOverviewDom.getBoundingClientRect().width : 0
          this.commentStyle.display = 'block'
          this.commentStyle.width = commentWith + 'px'
          this.commentStyle.top = mainPriceOffsetTop - containerOffsetTop + 1 + 'px'
        })
      }
    },
    showVipExplainationModal(isShow) {
      this.showVipExplaination = isShow
    },
    controlBranch(hasAppExclusiveDiscount) {
      if (typeof window !== 'undefined') {
        window.JOURNEY_BRANCH = window.JOURNEY_BRANCH || {}
        let params = {}
        if (hasAppExclusiveDiscount) {
          params = { hideScroll: true }
          window.vBus && window.vBus.$emit('hideBranch')
        } else {
          params = { hideScroll: false }
          window.vBus && window.vBus.$emit('showBranch')
        }

        window.JOURNEY_BRANCH.pageConfig = {
          ...(window.JOURNEY_BRANCH.pageConfig || {}),
          ...params
        }
      }
    },
    handleClickComment() {
      this.openViewCommentPop?.()
    },
    handleToogleAfterPayList(show) {
      this.showAfterPayList = show
    },
    getPricesAnalysis(tipsType) {
      const { goods_id, goods_sn, productRelationID } = this.detail
      const { mallCode, price, quickShip } = this
      const goods_list = `${goods_id}\`${goods_sn}\`${productRelationID}\`1\`1\`1\`\`\`pri_${price?.salePrice?.usdAmount}|pri_${price?.retailPrice?.usdAmount}\`${quickShip == 1 ? 'quickship_1' : ''}\`\`mall_${mallCode}\``
      return {
        pricestatus: tipsType,
        location: 'detail',
        goods_list
      }
    },
    lowestPriceTipsContent() {
      if (this.isBeltType || this.isFloorType) return { html: null, show: false }
      const { text, type } = this.bestDealConfig
      const showTaxIncludedText = this.taxIncludedConfig?.show && this.taxIncludedConfig?.type == 'text'
      const showLowest = text && !!~type.indexOf('Text')
      if (!showLowest && !showTaxIncludedText) return { html: null, show: false }
      
      return {
        html: (
          <div class="productPriceContainerOld__lowest-price">
            { showLowest ? <LowestPriceTips text={text} /> : null }
            {/* 最低税费提示 */}
            { showTaxIncludedText ? <span class="tax-included"> 
              { showLowest ? <span class="split">·</span> : null } 
              { this.taxIncludedConfig?.content } 
            </span> : null }
          </div>
        ),
        show: true
      }
    },
    exposeLowestTip() {
      const { text, type, tipsType } = this.bestDealConfig
      const showTaxIncludedText = this.taxIncludedConfig?.show && this.taxIncludedConfig?.type == 'text'
      const showLowest = text && !!~type.indexOf('Text')
      if (!this.exposeStatus?.lowest) {
        this.exposeStatus.lowest = true
        this.$nextTick(() => {
          showLowest && daEventCenter.triggerNotice({
            daId: '1-6-1-169',
            extraData: this.getPricesAnalysis(tipsType)
          })
          showTaxIncludedText && daEventCenter.triggerNotice({
            daId: '1-6-1-170'
          })
        })
      }
    },
    exposeTax() {
      const { show, type } = this.taxIncludedConfig
      let showPopoverEl = (show && type == 'popover')
      showPopoverEl && daEventCenter.triggerNotice({ daId: '1-6-1-170' })
    }
  },
  render() {
    const { lowestPriceTipsContent, showCommentOverview } = this
    // const { sheinClubPromotionInfo, paidVipData, showPaidVip } = this.vipInfo || {}
    const {
      IS_RW,
      language,
      is_include_critical_css,
      lang_rp,
      // showSheinClub,
      // PUBLIC_CDN_rp,
      WEB_CLIENT
    } = this.priceCommon || {}
    const { showFlashSaleDiff, detailPrice } = this.priceFlashInfo || {}
    const {
      hasEstimated
    } = this.estimatedInfo || {}
    const showUnderPriceEntry = this.priceAbtConfig.UnderPriceShow && !!this.getPrice?.suitRulePrice && !this.isInversion && !this.isInversionDe
    const isNewEstimatedShowType = !!this.getEstimatedInfo?.discountPercent
    let priceBannerTypeShowMainPrice = !(!this.isBeltType && !this.isFloorType && this.showPriceBelt)
    const { html: lowestPriceTipsContentHtml, show: showLowestPriceTipsContent } = lowestPriceTipsContent()
    const analysis = {
      tax: this.getPricesAnalysis(4)
    }
    const isOriginPrice = !this.isBeltType && !this.isBeltTypeBrand && !this.isFloorType

    let priceContainerMarginBottom = '.2133rem'
    // 旧到手价标签底部间距4px，其他 8px
    let { isSatisfied, EstimatedNothreShowType } = this.getEstimatedInfo || {}
    if (
      hasEstimated &&
      !isNewEstimatedShowType &&
      (isSatisfied || (!isSatisfied && !['New1', 'New2'].includes(EstimatedNothreShowType)))
    ) {
      priceContainerMarginBottom = '.1067rem'
    }
    console.log(this, 'this==99888')

    return (
      <div id={isOriginPrice ? 'priceContainer' : ''} class="productPriceContainerOld">
        {priceBannerTypeShowMainPrice ? <div class="productPriceContainerOld__tp" style={{ 'margin-bottom': priceContainerMarginBottom }}>
          <div class="productPriceContainerOld__wrapper">
            {isNewEstimatedShowType && hasEstimated ? (
              <NewEstimatedPrice
                class="goods-price-dom"
                price-common={this.priceCommon}
                price-flash-info={this.priceFlashInfo}
                show-from={this.showFrom}
                estimated-info={this.estimatedInfo}
                get-estimated-info={this.getEstimatedInfo}
                promotion-info={this.promotionInfo}
                discount-info={this.discountInfo}
                icon-list-data={this.iconListData}
                goods-id={this.goodsId}
                is-eur={this.isEur}
                show-inclusive-tips={this.showInclusiveTips}
                fs-abt={this.fsAbt}
                is-belt-type={this.isBeltType}
                is-belt-type-brand={this.isBeltTypeBrand}
                tax-included-config={this.taxIncludedConfig}
                is-floor-type={this.isFloorType}
                vOn:inclusiveClick={this.inclusiveClick}
                vOn:clickLink={this.clickEstimatedTag}
              />
            ) : (
              <div class="price-detail">
                <div class="goods-price goods-price-dom">
                  <PriceLeft
                    shein-club-info={this.sheinClubInfo}
                    price-common={this.priceCommon}
                    price-flash-info={this.priceFlashInfo}
                    show-from={this.showFrom}
                    estimated-info={this.estimatedInfo}
                    discount-info={this.discountInfo}
                    icon-list-data={this.iconListData}
                    sale-price={this.salePrice}
                    exclusive-info={this.exclusiveInfo}
                    price-abt-config={this.priceAbtConfig}
                    vip-info={this.vipInfo}
                    goods-id={this.goodsId}
                    is-eur={this.isEur}
                    show-vip-explaination={this.showVipExplaination}
                    show-inclusive-tips={this.showInclusiveTips}
                    tax-included-config={this.taxIncludedConfig}
                    vOn:inclusiveClick={this.inclusiveClick}
                    vOn:clickLink={this.clickEstimatedTag}
                    vOn:showExplainationModal={this.showVipExplainationModal}
                    get-estimated-info={this.getEstimatedInfo}
                    promotion-info={this.promotionInfo}
                    is-belt-type={this.isBeltType}
                    is-floor-type={this.isFloorType}
                    is-belt-type-brand={this.isBeltTypeBrand}
                    analysis={analysis}
                    compliance-mode-de={this.complianceModeDe}
                    realtime-ready={this.realtimeReady}
                  />
                  <ClientOnly>
                    {!!showCommentOverview ? (
                      <div
                        class="goods-price__right"
                        style={{
                          width: this.commentStyle.width
                        }}
                      >
                        <div
                          class="goods-price__comment-overview"
                          style={{
                            display: this.commentStyle.display,
                            top: this.commentStyle.top
                          }}
                        >
                          <CommentOverview
                            total-comment={this.commentOverview.totalComment}
                            comment-info={this.commentOverview.commentOverView}
                            language={language}
                            vOn:click-comment={this.handleClickComment}
                          />
                        </div>
                      </div>
                    ) : null}
                  </ClientOnly>
                </div>
              </div>
            )}
          </div>
        </div> : null
        }
        <ClientOnly>
          {/* 闪购差价 */}
          {!!showFlashSaleDiff && !this.getEstimatedInfo?.value && !this.showPriceBelt ? (
            <FlashSaleDiff
              language={language}
              lang-rp={lang_rp}
              price={detailPrice}
            />
          ) : null}
        </ClientOnly>
        {/* 价格降低 */}
        {showLowestPriceTipsContent ? lowestPriceTipsContentHtml : null}
        <ClientOnly>
          {!!this.afterPayInfoList?.length && !this.isBeltType && !this.isFloorType ? (
            <AfterPayInfo
              isRw={IS_RW}
              language={language}
              afterPayInfoList={this.afterPayInfoList}
              addToBagForm={this.addToBagForm}
              showList={this.showAfterPayList}
              vOn:toogleList={this.handleToogleAfterPayList}
            />
          ) : null}
        </ClientOnly>
        {/* 放置会员标签等信息 调换位置时注意 */}
        {
          !this.isFloorType ? <div class="productPriceContainerOld__bottom">
            <ClientOnly>
              {/* s3专属促销价格 */}
              {this.exclusiveInfo.showLocation === 'extend' && !this.complianceModeDe && !this.complianceMode ? (
                <ExclusivePrice
                  isRw={IS_RW}
                  amount-with-symbol={this.exclusiveInfo.amountWithSymbol}
                  language={language}
                  is-include-critical-css={is_include_critical_css}
                  show-vip-explaination={this.showVipExplaination}
                  vOn:showVipExplainationModal={this.showVipExplainationModal}
                />
              ) : null}
              {/* {showPaidVip ? (
                <PaidVip
                  data={sheinClubPromotionInfo}
                  paid-vip-data={paidVipData}
                  language={language}
                  price-detect={this.priceDetect}
                  public-cdn={PUBLIC_CDN_rp}
                  is-paid-user={this.isPaidUser}
                  is-free-receive-user={this.isFreeReceiveUser}
                  show-club-type={this.showClubType}
                  show-shein-club={showSheinClub}
                  source="goodsDetail"
                  vOn:getSheinClubUserInfo={this.getSheinClubUserInfo}
                />
              ) : null} */}
              {/* 一口价 */}
              {!!showUnderPriceEntry ? (
                <UnderPriceEntry
                  router={this.$router}
                  get-price={this.getPrice}
                  language={language}
                />
              ) : null}
              {/* app 专属折扣 */}
              {!!this.appExclusiveData ? (
                <AppExclusiveDiscount
                  data={this.appExclusiveData}
                  language={language}
                  web-client={WEB_CLIENT}
                  is-include-critical-css={is_include_critical_css}
                />
              ) : null}
            </ClientOnly>
          </div> : null
        }
      </div>
    )
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.productPriceContainerOld {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  &__tp {
    max-width: 100%;
    margin-bottom: 0.2162rem;
    .flexbox();
    .align-center();
    .space-between();
  }

  &__lowest-price {
    display: inline-flex;
    align-items: center;
    margin-bottom: 4/37.5rem;
    line-height: 1;
    .tax-included {
      .font-dpr(20px);
      color: @sui_color_gray_dark2;
    }
    .split {
      padding: 0 8/75rem;
    }
  }

  // &__bottom {
  // margin-top: 0.2133rem;
  // }

  &__wrapper {
    max-width: 100%;
    flex: 1;
    white-space: normal;
    .flexbox();
    .align-center();
  }

  .pdb-0 {
    padding-bottom: 0;
  }
}

.price-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.goods-price {
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: relative;
  max-width: 100%;
  &__right {
    display: flex;
    flex-direction: row-reverse;
    min-width: 2.08rem;
  }

  &__comment {
    position: absolute;
    right: 0;
  }

  &__estimated-tags-padding {
    display: flex;
    padding: 0.1067rem 0;
    max-width: 100%;
  }
}
</style>
