<template>
  <div
    ref="sizeRecommendRef" 
    class="recommendEntrance-size"
  >
    <!-- 第三方size推荐 -->
    <div 
      v-if="sizeRecommendType == 2"
      class="j-sa-check-size j-btn-check-my-size-wrapper"
    ></div>
    <template v-else>
      <a
        v-if="sizeRecommendType === 1"
        class="link-check-my-size"
        :class="{ 'link-check-my-size-planA': sizeGuidPlanA }"
        @click="selfSizeRecommendClick"
      >
        <p v-html="recommendSizeTextDom"></p>
        <span class="icon suiiconfont sui_icon_more_right_12px_2"></span>
      </a>
      <!-- Sizeguide新入口 -->
      <a
        v-if="showSizeGuideEntry"
        v-expose="{ id: '1-6-1-137' }"
        v-tap="{ id: '1-6-1-138' }"
        state="sizeGuideSizeEntry"
        class="link-check-my-size j-push-history-hash"
        :class="{ 'link-check-my-size-planA': sizeGuidPlanA }"
        @click="handleSizeGuide"
      >
        <p>
          {{ language.SHEIN_KEY_PWA_15005 }}
        </p>
        <span class="icon suiiconfont sui_icon_more_right_12px_2"></span>
      </a>
    </template>
  </div>
</template>

<script setup>
import { defineProps, watch, computed, ref, onMounted, onBeforeUnmount } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const props = defineProps({
  sizeRecommendInfo: {
    type: Object,
    default: () => {},
  },
  sizeAttrInfo: {
    type: Object,
    default: () => ({}),
  },
  language: {
    type: Object,
    default: () => {},
  },
  goodsId: {
    type: String,
    default: '',
  },
  hasOneSize: {
    type: Boolean,
    default: false,
  },
  isFromPopup: {
    type: Boolean,
    default: false,
  },
  handleCalcSize: {
    type: Function,
    default: () => {},
  },
  handleOpenSizeGuideFn: {
    type: Function,
    default: () => {},
  },
  handleSizeRecommend: {
    type: Function,
    default: () => {},
  },
})
const sizeRecommendRef = ref(null)
const loadPdpError = ref(false)
const timer = ref('')
const sizeRecommendType = computed(() => {
  if (loadPdpError.value || props.hasOneSize) return 0
  return props.sizeRecommendInfo?.sizeRecommendType || 0
})
const sizeGuidPlanA = computed(() => props.sizeRecommendInfo?.sizeGuidPlanA || false)
const showSizeGuide = computed(() => props.sizeRecommendInfo?.showSizeGuide || false)
const showSizeGuideEntry = computed(() => showSizeGuide.value && (sizeRecommendType.value == 0 || sizeGuidPlanA.value))
const ruleType = computed(() => props.sizeRecommendInfo?.ruleType || '')
const recommendSizeTextDom = computed(() => {
  const { currentMySize, currentMyBraSize } = props.sizeRecommendInfo
  const recommendText = (ruleType.value == '0' ? currentMySize : currentMyBraSize) || ''
  const attrItem = props.sizeAttrInfo?.attr_value_list?.find?.(item => item?.attr_value_name == recommendText)
  let text = recommendText && attrItem ? (props.handleCalcSize?.(attrItem)?.renderText || recommendText) : ''
  if (text) {
    if (sizeGuidPlanA.value) {
      return `<b>“${text}”</b> ${props.language?.SHEIN_KEY_PWA_18535 || ''}`
    }
    return `${props.language?.SHEIN_KEY_PWA_18535 || ''} <b>${text}</b>`
  }
  return props.language?.SHEIN_KEY_PWA_18113 || ''
})
const exposeSizeRecommend = (method) => {
  if(!method) return
  daEventCenter.triggerNotice({
    daId: '1-6-1-43',
    extraData: {
      goods_id: props.goodsId || '',
      method,
    }
  })
}
const reportSizeBi = () => {
  const method = sizeRecommendType.value == 1 ? 'self' : 'third_party'
  daEventCenter.triggerNotice({
    daId: '1-6-1-44',
    extraData: {
      goods_id: props.goodsId || '',
      method,
    }
  })
}
const traceCheckSize = () => {
  const touchEleClass = '.fitanalytics__button'
  const touchstartHandler = () => timer = Date.now()
  const touchendHandler = () => {
    if (Date.now() - timer <= 300) {
      reportSizeBi()
    }
    timer = -1
  }
  // 无法监听到第三方的点击事件
  let timer = -1
  $(document).on('touchstart', touchEleClass, touchstartHandler)
  $(document).on('touchend', touchEleClass, touchendHandler)
  onBeforeUnmount(() => {
    clearTimeout(timer.value)
    $(document).off('touchstart', touchEleClass, touchstartHandler)
    $(document).off('touchend', touchEleClass, touchendHandler)
  })
}
const handleSizeGuide = () => {
  props.handleOpenSizeGuideFn?.('sizeGuideSizeEntry', props.isFromPopup ? 'cart_title' : 'detail_title')
}
const selfSizeRecommendClick = () => {
  props.handleSizeRecommend?.()
  reportSizeBi()
}

watch(sizeRecommendType, async (val) => {
  if (typeof window === 'undefined') return
  if (val === 1) {
    exposeSizeRecommend('self')
  }
  if (val === 2) {
    try {
      const { message = '' } = await window.TPM?.runImmediate({
        marketing: 'Fitanalytics',
        method: '_loadPdp',
      })
      if (message == 'success') {
        clearTimeout(timer.value)
        timer.value = setTimeout(() => {
          if (document?.getElementsByClassName('fitanalytics__button') && document?.getElementsByClassName('fitanalytics__button')[0]?.style?.display !== 'none') {
            exposeSizeRecommend('third_party')
          }else if(showSizeGuide.value) {
            const config = { attributes: true, childList: true, subtree: true }
            const observer = new MutationObserver(() => {
              if(document?.getElementsByClassName('fitanalytics__button') && document?.getElementsByClassName('fitanalytics__button')[0]?.style?.display !== 'none') {
                const itanalyticsBtnDom = document.getElementsByClassName('fitanalytics__button') ?? []
                itanalyticsBtnDom?.length && (itanalyticsBtnDom[0].style.display = 'none')
                observer.disconnect()
              }
            })
            const targetElement = sizeRecommendRef.value
            observer.observe(targetElement, config)
            loadPdpError.value = true
          }
        }, 3000)
      }else {
        loadPdpError.value = true
      }
    }catch(e) {
      loadPdpError.value = true
    }   
  }
}, { immediate: true })

onMounted(() => {
  traceCheckSize()
})
</script>

<style lang="less">
.recommendEntrance-size{
  display: flex;
  flex:1;
  justify-content: flex-end;
  overflow: hidden;
}
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.link-check-my-size {
  display: inline-flex;
  align-items: center;
  cursor: auto;
  width: auto;
  margin-left: .13rem;
  margin-bottom: 0.1.7rem;
  text-decoration-line: none;
  line-height: normal;
  overflow: hidden;
  p {
    max-width: 3.22667rem;
    .text-overflow();
    font-size: 12px;
    color: @sui_color_gray_dark3;
  }
  .icon {
    margin-left: 8/75rem;
  }
  &-planA{
    padding: .05333333rem .16rem;
    border-radius: .53333333rem;
    background-color: #f6f6f6;
    p{
      color: var(---sui_color_gray_dark1, #000);
    }
    .sui_icon_more_right_12px_2{
      color: var(---sui_color_gray_dark1, #000);
    }
    .icon {
    margin-left: .053333333rem;
  }
  }
}
.link-check-mysize__img {
  width: 0.454rem;
  height: 0.454rem;
  margin-right: 0.04rem;
  vertical-align: bottom;
  cursor: pointer;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

/* 修复 ios 第三方尺码层级不生效 */
html.uclw_widget_open {
    #uclw_wrapper {
        transform: translate3d(0, 0, 999px);
    }
}
</style>
