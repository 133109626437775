import { SIMetric } from 'public/src/pages/common/monitor/index.js'

export const INTERCEPT_TYPE = {
  '0': 'pass', // 通过拦截
  '1': 'no_good_check', // 未勾选商品行
  '2': 'no_login', // 未登录
  '3': 'has_out_of_stock', // 库存接口校验未通过
  '4': 'flash_limit', // 闪购限购（常规+直播）
  '5': 'time_limit', // 限时折扣 web不存在该场景
  '6': 'discount_limit', // 打折限购
  '7': 'one_price_limit', // 一口价超限
  '8': 'low_price', // 低客单拦截（强+弱)
  '9': 'coupon_add_to_item', // 优惠券凑单拦截
  '10': 'good_quantity_out_of_stock', // 商品行购买数量超出库存 app场景，web不存在该场景
  '11': 'loading', // 弱化loading项目，上次购物车操作接口没返回时，点击chekout按钮拦截 app场景，web不存在该场景
}
  
const CORESITE = {
  'pwus': 'US',
  'pwar': 'ME',
  'pwaren': 'ME',
  'mbr': 'BR',
  'pwmx': 'MX',
  'pwfr': 'FR',
  'pwuk': 'UK',
  'mjp': 'JP'
}

/**
 * 获取大站点
 */
export const getCoreSite = () => {
  return CORESITE[gbCommonInfo?.SiteUID || ''] || 'OTHER'
}
  
export const CART_ROUTE_TYPE = {
  '1': 'floating_ball', // 悬浮购物车
  '2': 'bottom_navbar', // 底导购物车
  '3': 'corner_icon', // 角标
  '4': 'mini_cart', // 迷你购物车
  '5': 'other', // 其他
}
  
/**
 * 购物车入口点击
 * @param {String} type 购物车路由类型
 * 1: 悬浮购物车
 * 2: 底导购物车
 * 3: 角标
 * 4: 迷你购物车
 * 5: 其他
 */
export const web_cart_entry_click = (type) =>{
  window.sessionStorage.setItem('cart_route_type', type)
  SIMetric.metricCount({
    metric_name: 'web_cart_entry_click', // 指标名称
    tags: { // 指标维度
      core_site: getCoreSite(),
      type: CART_ROUTE_TYPE[type]
    },
    message: 'web cart entry click',
  })
}
  
/**
 * 进购车页计数
 */
export const web_cart_route_enter = () =>{
  let type = window.sessionStorage.getItem('cart_route_type') || ''
  if (type) {
    window.sessionStorage.removeItem('cart_route_type')
  } else {
    type = '5'
  }
  SIMetric.metricCount({
    metric_name: 'web_cart_route_enter', // 指标名称
    tags: { // 指标维度
      core_site: getCoreSite(),
      type: CART_ROUTE_TYPE[type]
    },
    message: 'web cart route entry'
  })
}
  
/**
 * 购物车页勾选商品点击计数
 * @param {String} select 勾选商品行 1: 勾选 ｜ 0: 取消勾选
 * @param {String} type 拦截类型  all: 全选 ｜ section: 组选 ｜ item: 单选
 */
export const web_modify_check_click = (select, type) =>{
  SIMetric.metricCount({
    metric_name: 'web_modify_check_click', // 指标名称
    tags: { // 指标维度
      core_site: getCoreSite(),
      select,
      type
    },
    message: 'web modify check click'
  })
}
  
/**
 * 购物车页商品勾选请求计数
 * @param {String} select 勾选商品行 1: 勾选 ｜ 0: 取消勾选
 * @param {String} type 拦截类型  all: 全选 ｜ section: 组选 ｜ item: 单选
 * @param {String} success 是否成功 1: 后端返回勾选有效 ｜ 0: 其他
 */
export const web_modify_check_response = (select, type, success) =>{
  SIMetric.metricCount({
    metric_name: 'web_modify_check_response', // 指标名称
    tags: { // 指标维度
      core_site: getCoreSite(),
      select,
      type,
      success
    },
    message: 'web modify check response'
  })
}
  
/**
 * 下单按钮checkout点击数
 */
export const web_checkout_button_click = () =>{
  SIMetric.metricCount({
    metric_name: 'web_checkout_button_click', // 指标名称
    tags: { // 指标维度
      core_site: getCoreSite(),
    }
  })
}
  
/**
 * 下单跳转结果计数
 * @param {String} type 跳转类型
 */
export const web_checkout_button_jump = (type) =>{
  SIMetric.metricCount({
    metric_name: 'web_checkout_button_jump', // 指标名称
    tags: { // 指标维度
      result: INTERCEPT_TYPE[type] || '',
      core_site: getCoreSite(),
    }
  })
}
  
