<script lang="jsx">
/**
 * @props
 *  @param index
 *  @param label
 */
export default {
  name: 'SizeSelectRelatedLabel',
  functional: true,
  props: {
    index: {
      type: Number,
      default: 0,
    },
    label: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    handleCalcSize: {
      type: Function,
      default: () => {},
    },
    handlerOpenAdd: {
      type: Function,
      default: () => {},
    },
  },
  render(h, { props }) {
    const { index, label, config, handleCalcSize } = props
    const { 
      goods_id, 
      size_gather_tag,
      attr_value_name_end,
      attr_value_name_start,
    } = label || {}
    const key = `${goods_id}_${size_gather_tag}`
    const { 
      renderTextDom: startRenderTextDom,
      renderText: startRenderText,
    } = handleCalcSize({ attr_value_id: `${key}_start`, attr_value_name: attr_value_name_start }, { isFromRelation: true }) || {}
    const { 
      renderTextDom: endRenderTextDom,
      renderText: endRenderText, 
    } = handleCalcSize({ attr_value_id: `${key}_end`, attr_value_name: attr_value_name_end }, { isFromRelation: true }) || {}
    const domArr = [startRenderTextDom, endRenderTextDom].filter(i => !!i)
    const textArr = [startRenderText, endRenderText].filter(i => !!i)
    const iconClass = `iconfont icon-price_px_- ${config?.from != 'detailPage' ? 'to-right' : ''}`
    const iconDom = `<i class="${iconClass}" aria-hidden="true"></i>`

    return (
      <li
        {...{ directives: [{ name: 'ada', value: { level: 1, pos: index } }, { name: 'expose', value: { id: '1-6-1-5' } }] }}
        class="goods-size__relate-good goods-size__sizes-item"
        da-event-click="1-6-1-6"
        role="text"
        aria-label={textArr.join(' - ')}
        vOn:click={() => props.handlerOpenAdd(label)}
      >
        <span domPropsInnerHTML={domArr.join(' - ') + iconDom}></span>
        {/* <i
          class={['iconfont icon-price_px_-', { 'to-right': config?.from != 'detailPage' }]}
          aria-hidden="true"
        ></i> */}
      </li>
    )
  },
}
</script>
