<script lang="jsx">
/**
 * @component 加车栏下的收藏按钮
 * 处理事件，UI下放至下级组件
 */

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { template, getQueryString } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
import WishBtnUI from './WishBtnUI'
import { getGroupList } from 'public/src/pages/goods_detail/utils/wish.js'

const WISH_STORAGE_KEY = 'goodslist_like_goodsids'
const { IS_RW = false } = typeof gbCommonInfo === 'undefined' ? {} : gbCommonInfo

export default {
  name: 'WishBtn',
  components: {
    WishBtnUI,
  },
  props: {
    isReview: {
      type: Boolean,
      default: () => false,
    },
    loc: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: () => '32px',
    }
  },
  data() {
    return {
      // 开始收藏动画状态
      isStartAnimation: false,
      // 结束收藏动画状态
      isEndAnimation: false,
      // 添加至分组按钮是否展示
      showAddBoardBtn: false,
      // 收藏推荐弹窗曝光次数
      wishDrawerExposeCount: 0,
      IS_RW: IS_RW
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['quickShip', 'skuInfo', 'saveStatus', 'initExposeStatus']), // 新数据流
    ...mapGetters('newProductDetail/common', ['goodsId', 'mallCode', 'detail', 'language', 'pageCommonInfo', 'isAddWishAfterRec', 'isShowAddBoardBtn', 'trendsInfo']),
    ...mapGetters('newProductDetail', ['productSaveStatus']),
    goodsSn() {
      const { detail } = this
      return detail?.goods_sn || ''
    }
  },
  watch: {
    goodsId() {
      this.showAddBoardBtn = false
      // 切换商品更新收藏状态
      this.updateSaveStatus(this.productSaveStatus)
    },
    /** 同步收藏状态 */
    productSaveStatus: {
      immediate: true,
      handler(val) {
        this.updateSaveStatus(val)
      },
    }
  },
  methods: {
    ...mapActions('newProductDetail', ['openDetailRecommendDrawer', 'showLoginModal']),
    ...mapActions('newProductDetail/common', [
      'updateExposeStatus',
    ]),
    // ...mapMutations('productDetail', ['assignState']),
    ...mapMutations('newProductDetail/common', ['updateSaveStatus', 'updateIsLoginChange']),
    ...mapMutations('wishlist', ['assignWSState']),
    // 收藏按钮点击
    async handleBtnClick() {
      const {
        saveStatus,
        addWish,
        cancelWish,
      } = this
      // if (isStartAnimation) return

      this.isEndAnimation = false
      this.isStartAnimation = true

      if (saveStatus) {
        // 取消收藏
        cancelWish()
      } else {
        // 收藏
        addWish()
      }
      appEventCenter.$emit('wishReloadProductList')
    },
    // 获取收藏请求参数
    getAddWishParams() {
      const {
        goodsId,
        mallCode,
        skuInfo,
      } = this
      const params = { goods_id: goodsId, mallCode }
      const { sku_code, skc_name, sku_sale_attr } = skuInfo
      if (skuInfo && Object.keys(skuInfo).length) {
        if (sku_code) Object.assign(params, { sku_code })
        if (skc_name) Object.assign(params, { skc_name })
        // 有尺码就传
        const target = sku_sale_attr?.find(item => item.attr_id == '87')
        if (target)
          Object.assign(params, { attrValueId: target.attr_value_id })
      }

      return params
    },
    // 收藏
    async addWish() {
      const {
        loc,
        goodsId,
        language,
        mallCode,
        quickShip,
        goodsSn,
        detail,
        isReview,
        initExposeStatus,
        trendsInfo
      } = this
      if (!isLogin()) {
        if (typeof window !== 'undefined' && window?.gbCommonInfo?.OPEN_DETAIL_LOGINED_RELOAD) {
          this.showLoginModal({
            params: {
              from: 'wishlist',
              ga: 'wishlist',
            },
            cb: () => {
              this.addWish()
            }
          })
        } else {
          SHEIN_LOGIN.show({
            show: true,
            from: 'wishlist',
            ga: 'wishlist',
            cb: () => {
              this.updateIsLoginChange(true)
              this.addWish()
            }
          })
        }
        return
      }

      let data
      try {
        data = await schttp({
          url: '/api/user/wishlist/create',
          method: 'POST',
          data: this.getAddWishParams()
        })
      } catch (e) {
        this.isStartAnimation = false
        this.$toast(language.SHEIN_KEY_PWA_20051 || 'error', 1000)
        return
      }

      this.isStartAnimation = false
      const extraData = {
        result: data,
        code: 'goodsDetail',
        mall_code: mallCode || '',
        postData: {
          loc,
          quickShip,
          action: 1, // 选中发1, 取消发0
          goods_id: goodsId,
          sku: goodsSn,
          detail,
          index: 0
        },
        from: 'main',
        review_location: isReview ? 'page_review' : '',
        ...(trendsInfo ? {
          passParams: {
            trend_tag: `${trendsInfo.trend_word_id}-${trendsInfo.product_select_id}`
          }
        } : {})
      }
      // 从预搜页找相似跳转过来的情况须多加几个参数
      const isFromPreSearchSimilarItems = !!getQueryString({ key: 'preSearchSimilarItems' })
      const srcTabPageId = getQueryString({ key: 'src_tab_page_id' }) || ''
      if (isFromPreSearchSimilarItems && srcTabPageId) {
        Object.assign(
          extraData, {
            src_module: 'presearch_similar',
            src_identifier: '-',
            src_tab_page_id: srcTabPageId
          }
        )
      }
      // 【埋点统一】收藏埋点的统一
      daEventCenter.triggerNotice({
        daId: '1-8-1-2',
        extraData
      })
      if (data.data && data.data.code == 400427) {
        // 超出收藏限制
        this.$toast(
          template(
            data.data.info.limit,
            language.SHEIN_KEY_PWA_18556
          ),
          3000
        )
      } else {
        // 触发熔断
        if (
          (data.data && data.data.originCode === -4) ||
            !(data.data && data.data.code == 0 && data.data.info.result)
        ) {
          this.$toast(
            language.SHEIN_KEY_PWA_20051 || data.data?.msg || 'error',
            1000
          )
          return
        }

        // GBDetailAnalysisEvent.save({
        //   label: this.detail.goods_sn,
        //   value: data.data && data.data.code == 0,
        //   goodsId: this.detail.goods_id
        // })

        this.isEndAnimation = true
        this.updateSaveStatus(true)
        this.updateExposeStatus({ addWishStatus: 1, addBagStatus: initExposeStatus?.addBagStatus || 0 })
        this.changeStorageLikeGoodsId(true)
        this.assignWSState({
          needUpdateData: true // 需要更新收藏列表
        })

        this.showAddBoardBtn = false

        const groupList = await getGroupList()
        const isShowAddBoardBtn = this.isShowAddBoardBtn || !!groupList.length
        if (this.isAddWishAfterRec) {
          const status = await this.openDetailRecommendDrawer({ 
            name: 'wish',
            extendConfig: {
              showAddBoardBtn: isShowAddBoardBtn,
            }
          })
          if (status) return
        }

        this.showAddBoardBtn = isShowAddBoardBtn

        if (this.showAddBoardBtn) {
          daEventCenter.triggerNotice({
            daId: '1-10-2-2',
            extraData: {
              board_count: groupList.length ? 1 : 0
            }
          })
          setTimeout(() => {
            this.showAddBoardBtn = false
          }, 5000)
        }
      }
    },
    // 取消收藏
    async cancelWish() {
      const {
        loc,
        goodsId,
        goodsSn,
        mallCode,
        quickShip,
        isReview,
        language,
        trendsInfo,
      } = this

      let json
      try {
        json = await schttp({ url: `/api/user/wishlist/delete?goods_id=${goodsId}` })
      } catch (e) {
        this.isStartAnimation = false
        this.$toast(language.SHEIN_KEY_PWA_20051 || 'error', 1000)
        return
      }
      this.isStartAnimation = false
      this.showAddBoardBtn = false
      daEventCenter.triggerNotice({
        daId: '1-8-1-2',
        extraData: {
          result: json,
          code: 'goodsDetail',
          mall_code: mallCode || '',
          postData: {
            loc,
            quickShip,
            action: 0, // 选中发1, 取消发0
            goods_id: goodsId,
            sku: goodsSn
          },
          from: 'main',
          review_location: isReview ? 'page_review' : '',
          ...(trendsInfo ? {
            passParams: {
              trend_tag: `${trendsInfo.trend_word_id}-${trendsInfo.product_select_id}`
            }
          } : {})
        }
      })
      if (json.data && json.data.code == 0 && json.data.info.result) {
        this.isEndAnimation = true
        this.updateSaveStatus(false)
        this.assignWSState({
          needUpdateData: true // 需要更新收藏列表
        })

        this.changeStorageLikeGoodsId(false)
      } else {
        this.$toast(
          language.SHEIN_KEY_PWA_20051 || json.data?.msg || 'error',
          1000
        )
      }
    },
    /**
     * 变更 localStorage 收藏记录（临时）
     */
    changeStorageLikeGoodsId(add) {
      const { goodsId: currentGoodsId } = this
      try {
        const goodsId = JSON.parse(
          window.localStorage.getItem(WISH_STORAGE_KEY)
        )

        if (add) {
          // 添加
          if (goodsId && goodsId.length) {
            window.localStorage.setItem(
              WISH_STORAGE_KEY,
              JSON.stringify([...goodsId, Number(currentGoodsId)])
            )
          } else {
            window.localStorage.setItem(
              WISH_STORAGE_KEY,
              JSON.stringify(Array.of(Number(currentGoodsId)))
            )
          }
        } else {
          // 移除
          if (!goodsId) return
          const goodsIdIndex = goodsId.findIndex(
            id => Number(id) === Number(currentGoodsId)
          )
          if (goodsIdIndex !== -1) {
            goodsId.splice(goodsIdIndex, 1)
            window.localStorage.setItem(
              WISH_STORAGE_KEY,
              JSON.stringify(goodsId)
            )
          }
        }
      } catch (err) {
        console.warn(
          'Change wish status localStorage relatives fail',
          err,
        )
      }
    },
    // 添加至分组
    addBoard() {
      let groupList = []
      try {
        groupList = JSON.parse(window.localStorage.getItem('groupList')) || []
      } catch (err) {
        console.warn(
          'parse addBoard localStorage relatives fail',
          err,
        )
      }

      daEventCenter.triggerNotice({
        daId: '1-10-2-3',
        extraData: {
          eventCategory: '商品详情页',
          board_count: groupList.length ? 1 : 0
        }
      })
      this.$_WD({
        type: 6,
        params: {
          goodsIds: [this.detail.goods_id]
        }
      })
    },
  },
  render() {
    const {
      language,
      saveStatus,
      isStartAnimation,
      isEndAnimation,
      showAddBoardBtn,
      handleBtnClick,
      addBoard,
      IS_RW,
      size,
      loc
    } = this

    return (
      <WishBtnUI
        language={language}
        wishStatus={saveStatus}
        isStartAnimation={isStartAnimation}
        isEndAnimation={isEndAnimation}
        showAddBoardBtn={showAddBoardBtn}
        isRw={IS_RW}
        size={size}
        loc={loc}
        onBtnClick={handleBtnClick}
        onAddBoard={addBoard}
      />
    )
  }
}
</script>
