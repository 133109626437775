<script lang="jsx">
import { throttle } from 'lodash'

export default {
  name: 'TitleSellPoint',
  functional: true,
  props: {
    sellPointsList: {
      type: Array,
      default: () => []
    },
  },
  render(h, { props, listeners }) {
    const { sellPointsList } = props
    const onTouchMove = throttle(() =>{
      listeners && listeners['touchMoveSellpoint']()
    }, 1000)
    const gettAnalysisData = {
      directives: [
        {
          name: 'expose',
          value: {
            id: '1-6-1-222',
            data: { key: 'sellPointsList', value: sellPointsList },
            code: 'expose—title-label-events',
          }
        }
      ]
    }
    return (
      <div class='title-sellpoint'>
        <div 
          {...gettAnalysisData}
          class='title-sellpoint__wrapper'
          onscroll={onTouchMove}
        >
          {
            sellPointsList.map(item => {
              const { key, colorClass, value } = item
              return (
                <span class={['title-sellpoint__item', `title-sellpoint__${colorClass}`]}>
                  {
                    key === 'rankAverage' && <i class="sui_icon_star_5_12px_2 suiiconfont"></i>
                  }
                  {value || ''}
                </span>
              )
            })
          }
        </div>
      </div>
    )
  },
}
</script>

<style lang="less">
.title-sellpoint{
  &__wrapper{
    padding-bottom: 0.32rem;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  &__item{
    height: 0.45333rem;
    line-height: 0.45333rem;
    display: inline-block;
    padding: 0 0.10667rem;
    border-radius: 0.05333rem;
    border: 1px solid #CCC;
    background: #FFF;
    font-size: 11px;
    margin-right: 0.16rem;
    flex-shrink: 0;
  }
  [class^='sui_icon_star'] {
    color: @sui_color_honor;
    margin-right: 1px;
  }
  &__golden{
    color: #A86104;
  }
  &__green{
    color: #198055;
  }
  &__blue{
    color: #243F6A;
  }
}
</style>
