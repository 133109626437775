<script lang="jsx">
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ColorTitle from 'public/src/pages/goods_detail_v2/innerComponents/top/MainSaleAttr/ColorTitle.vue'
import ColorV2 from './ColorV2.vue'
import { throttle } from '@shein/common-function'

export default {
  name: 'MainSaleAttr',
  components: {
    ColorTitle,
  },
  props: {
    // TODO 看起来是废弃了
    // Apollo 控制补图
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return { 
      hasLeftCover: true,
    }
  },
  computed: {
    ...mapState('productDetail/MainSaleAttr', ['last_click_color']),
    ...mapGetters('productDetail/MainSaleAttr', [
      'main_sale_attr_init', 
      'colors_info', 
      'color_type',
      'is_skc_prepose',
      'hot_goods_sn_list',
    ]),
    ...mapGetters('productDetail/common', [
      'language_v2',
      'goods_id',
      'common_page_info',
    ]),

    // TODO: 旧数据源，后续迁移
    ...mapState('newProductDetail/common', ['loading', 'fixedRatio']),
  },
  watch: {
    goods_id: {
      handler() {
        if (this.is_skc_prepose) {
          this.$nextTick(() => {
            this.colorIntoView()
          })
        }
      }
    },
  },
  mounted() {
    const { main_sale_attr_init, hot_goods_sn_list } = this
    const { isShowFindMyShadeEntry } = main_sale_attr_init || {}
    this.$nextTick(() => {
      // find my shade曝光
      if(isShowFindMyShadeEntry) {
        daEventCenter.triggerNotice({
          daId: '1-6-4-32'
        })
      }
      // hotGoodsList 曝光
      if (hot_goods_sn_list?.length) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-20',
          extraData: {
            goods_sn: hot_goods_sn_list.join('_')
          }
        })
      }
      // 色块页面曝光
      daEventCenter.triggerNotice({
        daId: '1-6-1-31',
        extraData: {
          location: 'page',
          image_tp: 'small',
          is_front: this.is_skc_prepose ? 1 : 0
        },
      }) 
      
      if (this.is_skc_prepose) {
        this.colorIntoView()
        const scrollContainer = document.getElementsByClassName('goods-color__list-box')?.[0]
        const handleScroll = throttle({
          func: () => {
            if (scrollContainer?.scrollWidth - scrollContainer?.clientWidth - scrollContainer?.scrollLeft <= 10) {
              this.hasLeftCover = false
            }else {
              this.hasLeftCover = true
            }
          },
          wait: 50
        })
        scrollContainer?.addEventListener('scroll', handleScroll)
      }
    })
    // appEventCenter.$on('close-popover-add-bag', this.switchColorFn)
  },
  beforeDestroy() {
    // appEventCenter.$off('close-popover-add-bag', this.switchColorFn)
  },
  methods: {
    ...mapActions('productDetail/common', ['switch_color']),
    // TODO: 旧数据流
    ...mapMutations('newProductDetail/common', ['updateQuickAddState', 'updateQuickAddLargeImgMode']),
    switchColorFn(color) {
      this.switch_color({
        ...color,
        jumpCallBack: (url) => {
          this.$router.replace(url)
        }
      })
    },
    clickShowFindMyShade() {
      import('public/src/pages/goods_detail/components/find_shade/index.js').then(res => {
        res?.default?.show(this.main_sale_attr_init?.findMyShadeParams || {})
      })
    },
    onLargeImageSwitch() {
      const { supportLargeImageList } = this.main_sale_attr_init || {}
      if (!supportLargeImageList) return
      daEventCenter.triggerNotice({ daId: '1-6-1-101' })

      // TODO: 兼容旧数据流更新 大图模式 + 加车弹窗
      this.updateQuickAddState(true)
      this.updateQuickAddLargeImgMode(true)
    },
    colorIntoView() {
      if (typeof window === 'undefined') return
      var element = document.getElementsByClassName('goods-color__prepose-item color-active')[0]
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth', // 平滑滚动
          block: 'nearest', // 最接近的边缘
          inline: 'start'
        })
      }
    },
  },
  render() {
    const { 
      isShowColorMoreIcon, 
      colorBlockText, 
      supportLargeImageList,
      isShowFindMyShadeEntry,
      mainSaleAttrDescText,
      mainAttrPicInfo,
    } = this.main_sale_attr_init || {}

    const renderTitle = () => {
      if (this.is_skc_prepose) return null
      return <ColorTitle
        isShowColorMoreIcon={isShowColorMoreIcon}
        language={this.language_v2}
        colorBlockText={colorBlockText}
        supportLargeImageList={supportLargeImageList}
        isShowFindMyShadeEntry={isShowFindMyShadeEntry}
        largeImageSwitch={this.onLargeImageSwitch}
        clickShowFindMyShade={this.clickShowFindMyShade}
      />
    }

    const renderColorSelectBox = () => {
      return <ColorV2
        language={this.language_v2}
        mainGoodsId={this.goods_id}
        mainSaleAttrDescText={mainSaleAttrDescText}
        mainAttrPicInfo={mainAttrPicInfo}
        colorsInfo={this.colors_info}
        eventCategory={'商品详情页'}
        from={'page'}
        colorType={this.color_type}
        isSkcPrepose={this.is_skc_prepose}
        lastClickColor={this.last_click_color}
        loading={this.loading}
        fixedRatio={this.fixedRatio}
        isSupportCropImage={this.isSupportCropImage}
        switchColor={this.switchColorFn}
        lazyImg={this.common_page_info?.LAZY_IMG || ''}
      />
    }

    const renderPrePoseLarge = () => {
      // 销售属性前置展示大图入口
      if (!supportLargeImageList || !this.is_skc_prepose) return null
      return <div 
        class="goods-color-prepose__right-arrow"
        {...{ directives: [
          { name: 'expose', value: { id: '1-6-1-79' } }, 
          { name: 'tap', value: { id: '1-6-1-80' } }] 
        }}
      >
        {this.hasLeftCover && <div class="goods-color-prepose__left-cover"></div>}
        <div class="goods-color-prepose__right-cover">
          <i class="suiiconfont sui_icon_more_right_16px" vOn:click={this.onLargeImageSwitch}></i>
        </div>
      </div>
    }

    const classText = this.is_skc_prepose ? 'goods-color-prepose' : 'goods-color'

    return <div class={[classText, 'NEW_BFF_DETAIL_COMPONENT']}>
      { renderTitle() }
      { renderColorSelectBox() }
      { renderPrePoseLarge() }
    </div>
  }
}
</script>

<style lang="less">
.goods-color {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected-color {
      display: inline-flex;
      align-items: center;
      // line-height: 1;
      line-height: normal;
      margin-bottom: 4px;
    }

    .color-block, i {
      display: inline-block;
      font-style: normal;
      font-weight: 700;
      .font-dpr(28px);

      /* rw:begin */
      font-family: Adieu;
    }
  }

  &__shade {
    display: inline-block;
    color: #1860A7;
    .font-dpr(24px);
    text-decoration: none;
  }
}

.goods-color-prepose {
  position: relative;
  padding-right: 0.533rem;
  margin-bottom: 0.266rem;
  .goods-color__list-box {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__left-cover{
    background: linear-gradient(270deg, #FFF -3.13%, rgba(255, 255, 255, 0.00) 103.13%);
    width: 0.64rem;
    height: 100%
  }
  &__right-cover{
    width: 0.533rem;
    background-color: #FFF;
    height: 1.06rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #959595
  }
  &__right-arrow {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .goods-color__imgs{
    padding: .266rem 0 .213rem;
  }

  .goods-color__soldout{
    border: 1px dashed @sui_color_gray_weak1;
  }
}
</style>
