<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue' 
import LocateLabels from './LocateLabels'
import CropImageContainerJsx from 'public/src/pages/components/CropImageContainer/CropImageContainerJsx.vue'
import { transformImg } from '@shein/common-function'

export default {
  name: 'CarouselsProductItem',
  functional: true,
  components: {
    SwiperSlideItem,
    LocateLabels,
    CropImageContainerJsx,
    CarouselsPicInterest: () => import(/* webpackChunkName: "carousels_pic_interest" */ './CarouselsPicInterest.vue'),
  },
  props: {
    isSsr: {
      type: Boolean,
      default: false
    },
    isSingle: {
      type: Boolean,
      default: false
    },
    isRw: {
      type: Boolean,
      default: false
    },
    lazyImg: {
      type: String,
      default: ''
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    fixedRatio: {
      type: String,
      default: '3-4'
    },
    productImgs: {
      type: Array,
      default: () => []
    },
    locateLabelsInfo: {
      type: Object,
      default: () => {}
    },
    isPicCut: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => {}
    },
    showInterestCarousels: {
      type: Boolean,
      default: false
    },
    isDoublePic: {
      type: Boolean,
      default: false
    },
    goodsReady: {
      type: Boolean,
      default: false
    },
  },
  render(h, { props, listeners }) {
    const {
      isSsr, 
      productImgs,
      locateLabelsInfo,
      fixedRatio,
      isPicCut,
      isRw,
      language,
      showInterestCarousels,
      isDoublePic,
      isSingle,
      goodsReady,
      lazyImg,
      isSupportCropImage
    } = props
    const _handleGoodsImgClick = (index, item) => {
      if(item.isPicInterest) return
      listeners?.clickGoodsImg(index)
      // 主图点击埋点上报
      daEventCenter.triggerNotice({
        daId: '1-6-1-27',
        extraData: { pic: item }
      })
    }

    const pictureCutStyle = () => {
      if(isSingle){
        return {
          width: '10rem',
        }
      }
      let widthPercent = 0.75
      let height = 9.08
      // 1:1 裁切/补图容器高度固定10rem
      if (isPicCut || fixedRatio === '1-1') {
        height = 10
      }
      const rationArr = fixedRatio && typeof fixedRatio == 'string' && fixedRatio?.split('-')
      widthPercent = (rationArr[0] / rationArr[1])?.toFixed(2)
      return {
        width: `${widthPercent * height}rem`
      }
    }

    return productImgs.map((item, index) => {
      const { thumbnail, image_url, bfCache } = item
      const key = thumbnail || image_url || bfCache || index
      
      let dataSrc = item?.origin_image || item?.image_url
      dataSrc = dataSrc ? transformImg({ img: dataSrc }) : lazyImg

      if (isSsr) {
        dataSrc =  dataSrc.replace(/(_square)|(_squfix)/ig, '') // 1-1 这种比例防止ssr直出补图样式与客户端不一致
      }

      const isFsIndex = index < 1
      const itemImgClass = () => {
        const result = []
        if (isPicCut) result.push('cut-image')
        if (isFsIndex) { // 前二图采用手动补图逻辑
          result.push('fsp-element')
        } else {
          result.push('lazyload')
        }
        return result
      }

      return (
        <SwiperSlideItem
          key={key + index} 
          index={index}
          class='swiper-slide slide-singleImageBoxWithFrame goods-img'
          style={pictureCutStyle()}
          vOn:click={() => { _handleGoodsImgClick(index, item) }}
        >
          <CropImageContainerJsx
            fixedRatio={fixedRatio}
            isSupportCropImage={isSupportCropImage}
            imgSrc={dataSrc}
            imgProps={{
              src: isSsr && isFsIndex ? dataSrc : item.bfCache || lazyImg,
              class: itemImgClass(),
              dataDesignWidth: '750'
            }}
          />
          {/* 主图角标 */}
          { (!isRw && locateLabelsInfo && dataSrc) && (
            <LocateLabels 
              labelsInfo={locateLabelsInfo}
            />
          )}
          {
            showInterestCarousels && goodsReady && item.isPicInterest &&
            (
              <CarouselsPicInterest
                is-single={isSingle}
                language={language}
                fixed-ratio={fixedRatio}
                is-pic-cut={isPicCut}
                total-index={productImgs?.length}
                from='productItem'
                is-double-pic={isDoublePic}
              />
            )
          }
        </SwiperSlideItem>
      )
    })
  }
}

</script>
