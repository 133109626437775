<script lang="jsx">

export default {
  name: 'ColorV2AddTag',
  functional: true,
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    promotionTagInfos: {
      type: Object,
      default: () => ({})
    },
    labelExpose: {
      type: Function,
      default: () => {}
    },
    publicCdn: {
      type: String,
      default: ''
    },
    isLargeImage: {
      type: Boolean,
      default: false
    },
  },
  render(h, { props }) {
    const { item, promotionTagInfos, labelExpose, publicCdn, isLargeImage } = props
    const { goods_id, hot_color, isEco } = item || {}
    /** 色块tag信息 */
    const renderColorTag = () => {
      if (promotionTagInfos && promotionTagInfos[goods_id] && promotionTagInfos[goods_id].showVisible) {
        if (promotionTagInfos[goods_id].tagType === 1 && promotionTagInfos[goods_id].unit_discount) {
          return (
            <div
              class={!isLargeImage ? 'goods-color__tag-discount' : 'tag-discount'}
              {...{ expose: [{ name: 'expose', value: labelExpose(goods_id) }] }}
            >
            -{ promotionTagInfos[goods_id].unit_discount }%
            </div>
          )
        } else if (promotionTagInfos[goods_id].tagType === 2) {
          return (
            <div
              class={!isLargeImage ? 'suiiconfont sui_icon_activity_12px goods-color__tag-promotion' : 'suiiconfont sui_icon_activity_12px tag-promotion'}
              {...{ expose: [{ name: 'expose', value: labelExpose(goods_id) }] }}
            ></div>
          )
        }
        return null
      } else {
        if (isEco === '1') {
          return (
            <img
              class={!isLargeImage ? 'goods-color__evolu' : 'evolu'}
              src='https://img.ltwebstatic.com/images3_ccc/2024/05/07/d7/color_evolu_pwa.png'
            />
          )
        } else if (hot_color === '1') {
          return (
            <img
              class={!isLargeImage ? 'goods-color__hot' : 'hot'}
              src={(publicCdn || '') + '/pwa_dist/images/color_hot-e5b559d042.png'}
            />
          )
        }
        return null
      }
    }

    return renderColorTag()
  }
}

</script>
