import { ref, getCurrentInstance, computed } from 'vue'
import { SizeCalculator } from 'public/src/pages/components/sizes/sizeComputed.js'
import { template } from '@shein/common-function'
export default function useSizeInfoIndex(props, reactiveInfo, callBack) {
  const vm = getCurrentInstance()
  const { 
    sizeComposeInfo,
    language,
    baseInfo,
    mallCode,
    config,
    currentUnit,
    showHightSize,
  } = reactiveInfo || {}
  const { handleCompose, mallClick } = callBack || {}
  const skuInfo = ref({})
  const sizeInfoIndex = ref({})
  const sizeInfoIndexBak = ref('{}')
  const sizeCalculator = ref(null)
  const isInit = ref(false)
  const bubbleInfo = ref({})
  const isShowBaseCode = ref(false)

  const openIdMap = computed(() => {
    const { dataMapCompose, skuMapCompose } = sizeComposeInfo.value || {}
    const { dataIdMap } = dataMapCompose || {}
    const { skuIdMap } = skuMapCompose || {}
    return !!(typeof gbCommonInfo !== 'undefined' && gbCommonInfo?.OPEN_DETAIL_SIZE_IDMAP && dataIdMap && skuIdMap)
  })

  const calcSize = (label) => {
    const { attr_value_name, attr_std_value } = label || {}
    if (!attr_value_name) return ''
    let str = '',
        correspond = sizeComposeInfo.value.localSizeMap?.[attr_value_name] || ''
    if (attr_std_value) {
      str += `${attr_std_value} / `
    }
    str += attr_value_name
    if (correspond) {
      return config.value.isNewSizeLocal ? `${correspond} (${str})` : `${str} (${correspond})`
    }
    // 童装身高码
    const heightSize = sizeComposeInfo.value.heightSizeMap?.[currentUnit.value]?.[attr_value_name]
    if (showHightSize.value && str.indexOf('/') === -1 && heightSize) {
      str += `${heightSize}`
    }
    return str
  }

  const chooseCheck = (attrId, attrName, attrValueName, mallCode, attrValueId = '') => {
    const { dataMapOrder, dataMap, dataIdMap } = sizeComposeInfo.value?.dataMapCompose || {}
    let str = ''
    let idStr = ''
    for (let i = 0; i < dataMapOrder.length; i++) {
      const id = dataMapOrder[i]
      if (id == attrId) {
        str += attrName + '__' + attrValueName + ','
        idStr += attrName + '__' + attrValueId + ','
        continue
      }
      const { attr_name, attr_value_name, attr_value_id } = sizeInfoIndex.value?.[id] || {}
      if (attr_value_name) {
        str += attr_name + '__' + attr_value_name + ','
        idStr += attr_name + '__' + attr_value_id + ','
      } else {
        str += ','
        idStr += ','
      }
    }
    if (mallCode) {
      str += mallCode
      idStr += mallCode
    }

    if (openIdMap.value) {
      return dataIdMap[idStr]
    }

    return dataMap[str]
  }

  const mallCheck = (str, isIdMap = false) => {
    const { dataMap, dataIdMap } = sizeComposeInfo.value?.dataMapCompose || {}
    const mallList = baseInfo.value?.mall_info || []
    if (chooseCheck(-1, '', '', mallCode.value) === undefined) {
      const optionalMallCode = {}
      let hasStockMall = false
      let mallCodeStr = ''
      // 提取有库存的并且可组合的mallCode
      const targetMapData = isIdMap ? dataIdMap : dataMap
      for (let k in targetMapData) {
        if (k && k.indexOf(str) > -1) {
          const attr = k.split(',')
          if (targetMapData[k] > 0) {
            hasStockMall = true
          }
          optionalMallCode[attr[attr.length - 1] || ''] = targetMapData[k]
        }
      }
      // mallList的优先级是降序的
      for (let i = 0; i < mallList.length; i++) {
        const mall_code = mallList[i].mall_code
        // 优先找有库存的mall
        if (hasStockMall) {
          if (optionalMallCode[mall_code]) {
            mallCodeStr = mall_code
            break
          }
        } else {
          // 所有mall都没库存，选取优先级最高的那个mall
          if (optionalMallCode[mall_code] == 0) {
            mallCodeStr = mall_code
            break
          }
        }
      }
      if (typeof window !== 'undefined') {
        vm?.proxy?.$toast(
          template(
            baseInfo.value.mall[mallCode.value].mall_name,
            language.value.SHEIN_KEY_PWA_21064
          ),
          1500
        )
      }
      const mallStock = chooseCheck(-1, '', '', mallCodeStr)
      // 更新mallCode
      mallClick({ mallCode: mallCodeStr, mallStock })
      return mallStock
    }
    return chooseCheck(-1, '', '', mallCode.value)
  }

  const commonSizeInit = (isSetIndex, { needSetSizeIndex = {}, needSetSkuInfo = '' } = {}) => {
    const { 
      skcSaleAttr, 
      localSizeRules,
      tackInfoDes,
      sizeInfoDes,
      skuList,
      showMultiLayer,
      polyAttrSize,
    } = sizeComposeInfo.value || {}
    const initParams = {
      skcSaleAttr,
      localSizeRules,
      tackInfoDes,
      sizeInfoDes,
      language: language.value,
      skuList,
      sizeInfoIndex: sizeInfoIndex.value,
      showMultiLayer,
      polyAttrSize,
    }
    if (!sizeCalculator.value) {
      sizeCalculator.value = new SizeCalculator(initParams)
    }else if(!isSetIndex){
      sizeCalculator.value.init(initParams)
    }
    const data = sizeCalculator.value.getFormatSizeData({
      needSetSizeIndex,
      needSetSkuInfo,
      sizeInfoIndexBak: sizeInfoIndexBak.value,
      sizeInfoIndex: sizeInfoIndex.value,
      chooseCheck,
    })
    // 处理响应式丢失问题
    sizeInfoIndex.value = { ...(data?.sizeInfoIndex || {}) }
    sizeInfoIndexBak.value = data?.sizeInfoIndexBak || '{}'
    bubbleInfo.value = { ...(data?.bubbleInfo || {}) }
    isShowBaseCode.value = data?.isShowBaseCode || false
    isInit.value = true
  }

  const getRealTimeSkuInfo = (skuInfo) => {
    const { sku_code } = skuInfo || {}
    const { skuAllInfo } = sizeComposeInfo.value?.skuMapCompose || {}
    const realTimeData = skuAllInfo?.[sku_code] || {}
    let mall = {}
    if (realTimeData.mall_price?.length) {
      Object.keys(skuInfo.mall).map(mallCode => {
        let realMallPrice = realTimeData?.mall_price?.find?.(item => item.mall_code == mallCode)
        mall[mallCode] = Object.assign(skuInfo.mall[mallCode], realMallPrice, { promotionInfo: realTimeData.promotionInfo })
      })
    }
    return Object.assign(
      skuInfo,
      realTimeData,
      realTimeData.mall_price?.length ? { mall } : {}
    )
  }

  const sizeEmit = (item = {}, isUserSelect, initParams) => {
    if (!isInit.value) {
      commonSizeInit(false, initParams)
    }
    const { skuList, skcSaleAttr, skcName, skuMapCompose, dataMapCompose } = sizeComposeInfo.value || {}
    const { dataMapOrder } = dataMapCompose || {}
    const { skuMap, skuIdMap } = skuMapCompose || {}
    let str = ''
    let idStr = ''
    let attrs = []  
    let skuInfoObj = {}
    for (let i = 0; i < dataMapOrder.length; i++) {
      const id = dataMapOrder[i]
      const sizeInfo = sizeInfoIndex.value[id] || {}
      const { attr_value_name, attr_name, attr_value_id } = sizeInfo
      attrs.push({ attr_id: id, ...sizeInfo })
      if (attr_value_name) {
        str += attr_name + '__' + attr_value_name + ','
        idStr += attr_name + '__' + attr_value_id + ','
      } else {
        str += ','
        idStr += ','
      }
    }
    // 检测最新选择的销售属性和当前的mall能否组成一个正确的商品
    const mallStock = openIdMap.value ? mallCheck(idStr, true) : mallCheck(str)
    // TODO: 像是没用了
    if (isUserSelect) {
      // 用户的最后一次选择备份一下
      sizeInfoIndexBak.value = JSON.stringify(sizeInfoIndex.value)
    }
    if (skuList?.length == 1 && skcSaleAttr?.length == 0) {
      skuInfoObj = { ...skuList[0], skc_name: skcName }
    }
    if (skuMap?.[str]) {
      skuInfoObj = {
        ...skuMap?.[str],
        skc_name: skcName,
      }
    }
    if (openIdMap.value && skuIdMap?.[idStr]) {
      skuInfoObj = {
        ...skuIdMap?.[idStr],
        skc_name: skcName,
      }
    }
    skuInfoObj?.sku_sale_attr?.forEach?.((item) => {
      if (typeof item !== 'object') return
      // 最终显示在页面的属性name
      item.sku_calc_name = calcSize(item) || ''
    })
    skuInfo.value = getRealTimeSkuInfo(skuInfoObj)
    // compose事件
    handleCompose(JSON.parse(
      JSON.stringify({
        attrs,
        skuInfo: skuInfoObj,
        mallStock,
        externalSizeInfoIndex: sizeInfoIndex.value,
        curSelectAttr: item,
        isUserSelect,
        // isFirst: this.firstCompose,
      })
    ))
  }

  const sizeClick = (item, subItem) => {
    if (!isInit.value) {
      commonSizeInit()
    }
    if (chooseCheck(item.attr_id, item.attr_name, subItem.attr_value_name, '', subItem.attr_value_id) !== undefined) {
      if (sizeInfoIndex.value[item.attr_id]?.attr_value_name == subItem?.attr_value_name) {
        sizeInfoIndex.value[item.attr_id] = {
          attr_name: item.attr_name,
          attr_value_name: '',
          attr_value_id: '',
        }
      } else {
        sizeInfoIndex.value[item.attr_id] = {
          attr_name: item.attr_name,
          ...subItem,
        }
      }
    } else {
      if (!config.value?.isNoSkuClick) return
      for (let attr_id in sizeInfoIndex.value) {
        if (attr_id == item.attr_id) {
          sizeInfoIndex.value[attr_id] = {
            attr_name: item.attr_name,
            ...subItem,
          }
        } else {
          sizeInfoIndex.value[attr_id] = {
            ...sizeInfoIndex.value[attr_id],
            attr_value_name: '',
            attr_value_id: '',
          }
        }
      }
    }
    // TODO: 像是没用了
    // this.sizeTarget = this.sizeInfoIndex?.[this.bubbleInfo?.sizeInfoDesAttrId]?.attr_value_name
    // this.userSelectSize = flag

    // TODO: item变参
    // const emitSizeParam = {
    //   item: { attr_id: item.attr_id, ...subItem }, 
    // }

    sizeEmit({ attr_id: item.attr_id, ...subItem })
  }

  const sizeValue = (size) => {
    if (!isInit.value) {
      commonSizeInit()
    }
    const { polyAttrSize, skcSaleAttr } = sizeComposeInfo.value || {}
    for (let i = 0; i < polyAttrSize.length; i++) {
      if (
        polyAttrSize[i].attr_value_name === size &&
        chooseCheck(
          87,
          sizeInfoIndex.value?.[87]?.attr_name,
          polyAttrSize?.[i]?.attr_value_name,
          '',
          polyAttrSize?.[i]?.attr_value_id,
        ) > 0
      ) {
        const item = skcSaleAttr[skcSaleAttr.length - 1]
        if (item.attr_id == 87) {
          return sizeClick(item, polyAttrSize[i])
        }
      }
    }
  }

  return { calcSize, isInit, sizeValue, sizeInfoIndex, bubbleInfo, isShowBaseCode, commonSizeInit, sizeEmit, chooseCheck }
}
