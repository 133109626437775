<template>
  <div class="colorList-fold">
    <div class="colorList-fold__list">
      <div
        v-for="(item, index) in colorsFoldInfo.colorList"
        :key="index"
        class="colorList-fold__item"
        :class="{
          last: index === colorsFoldInfo.colorList.length - 1,
        }"
      >
        <div class="colorList-fold__item-innerBox">
          <img
            v-if="imgSrc(item)"
            :src="imgSrc(item)"
            class="colorList-fold__item-inner colorList-fold__item-img"
          />
          <div
            v-if="index === colorsFoldInfo.colorList.length - 1 && colorsFoldInfo.colorMoreNum"
            class="colorList-fold__item-more"
          >
            +{{ colorsFoldInfo.colorMoreNum }}
          </div>
        </div>
      </div>
    </div>
    <div class="mask"></div>
  </div>
</template>

<script setup>
import { inject } from 'vue'
import { transformImg } from '@shein/common-function'
defineProps({
  colorsFoldInfo: {
    type: Object,
    default: () => ({}),
  },
})
const cutImg = inject('cutImg')

const baseCutImg = (imgUrl, imgDesignWidth = 0, imgDataExp = {}) => {
  if (!cutImg) {
    return transformImg({ img: imgUrl })
  }
  return cutImg(imgUrl, imgDesignWidth, imgDataExp)
}
const imgSrc = (item) => {
  return baseCutImg(item.goods_thumb, 28, { max: 48 })
}
</script>

<style lang="less" scoped>
.colorList-fold {
  position: relative;
  margin-left: 0.21rem;

  &__list {
    display: flex;
    align-items: center;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 100%;
    background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)
  }

  &__item {
    position: relative;
    padding: 2px;
    border-radius: 50%;
    overflow: hidden;
    border: 0.5px solid var(---sui_color_gray_weak1, #E5E5E5);
    background: #fff;
    margin-right: -8px;

    &.last {
      margin-right: 0;
    }
  }

  &__item-innerBox {
    position: relative;
  }

  &__item-inner {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    overflow: hidden;
  }

  &__item-img {
    object-fit: cover;
  }

  &__item-more {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.30);

    font-size: 11px;
    color: #FFF;
  }
}
</style>
