<template>
  <div>
    <SPopover
      :value="showHint"
      trigger-type="user"
      theme="dark"
      placemen="bottom-end"
      custom-class="hint-box"
      :append-to-body="true"
      :show-close-icon="true"
      :outside-close="false"
      @closed="closeHint"
    >
      <div>
        {{ language.SHEIN_KEY_PWA_31282 }}
      </div>
      <div slot="reference">
        <Icon
          class="attrs-fold__content-more"
          name="sui_icon_more_right_16px"
          size="16px"
          color="#959595"
          :is-rotate="GB_cssRight"
        />
      </div>
    </SPopover>
  </div>
</template>

<script setup>
import { Icon } from '@shein-aidc/icon-vue2'
import { Popover as SPopover } from '@shein/sui-mobile'

defineProps({
  language: {
    type: Object,
    default: () => ({}),
  },
  showHint: {
    type: Boolean,
    default: false,
  },
  closeHint: {
    type: Function,
    default: () => {},
  },
})

let GB_cssRight = false
if (typeof gbCommonInfo !== 'undefined') {
  GB_cssRight = gbCommonInfo.GB_cssRight
}
</script>

<style lang="less">
.hint-box {
  // .S-popover__main-content {
  //   background-color: rgba(0, 0, 0, .8) !important;
  // }
}
</style>
