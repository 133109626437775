var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"attrs-fold"},[_c(_setup.FoldEntry,{attrs:{"compShowConfig":_setup.compShowConfig,"language":_setup.language,"saleAttrsArr":_setup.saleAttrsArr,"sizeStockTips":_setup.sizeStockTips,"soldOutTips":_setup.soldOutTips,"colorsFoldInfo":_setup.colorsFoldInfo,"sizeInfoIndex":_setup.sizeInfoIndex,"calcSize":_setup.calcSize,"showHint":_setup.showHint,"closeHint":_setup.closeHint,"openQuick":_setup.openQuick,"currentMall":_setup.currentMall}}),_vm._v(" "),(_setup.allDataReady)?_c(_setup.ClientOnly,[(_setup.compShowConfig.showAttrInfoComp)?[_vm._l((_setup.attrPicInfos),function(item,index){return [(item.isMainAttr)?_c(_setup.ColorV2Desc,{key:_setup.goodsId || index,style:({ marginBottom: '0.11rem' }),attrs:{"goodsDesc":_setup.goodsDesc,"language":_setup.language,"goodsMainAttPicInfo":_setup.goodsMainAttPicInfo,"maxRetry":30,"from":"page","goodsId":_setup.goodsId}}):_c(_setup.AttrPicInfo,{key:item.attr_value_id || index,style:({ marginBottom: '0.11rem' }),attrs:{"show":!!item,"picInfo":item}})]}),_vm._v(" "),(_setup.showDesc && !_setup.hideOneSizeModule)?_c(_setup.SizeSlideTips,{attrs:{"slide-tips-params":{
          showDesc: _setup.showDesc,
          sizeInfoStr: _setup.sizeInfoStr,
          sizeInfoTitle: _setup.sizeInfoTitle,
          showSizeAttributeEntrance: _setup.sizeConfig.showSizeAttributeEntrance,
          sizeBubblePlan: _setup.sizeConfig?.sizeBubblePlan
        }},on:{"openSizeGuide":_setup.openSizeGuide}}):_vm._e()]:_vm._e(),_vm._v(" "),(_setup.compShowConfig.showTipsComp && _setup.showTipsContent)?_c(_setup.SizeTipsContent,{attrs:{"tips-combo":_setup.tipsCombo,"true-to-size":_setup.trueToSize,"is-show-size-tips":_setup.isShowSizeTips,"isShowSizeTipsText":_setup.isShowSizeTipsText},on:{"openSizeGuideFromSizeTips":_setup.openSizeGuideFromSizeTips,"openReviews":_setup.openReviews}}):_vm._e(),_vm._v(" "),(_setup.compShowConfig.showFeedBackComp && _setup.hasTargetCatId && _setup.feedBackBasicSizes.length)?[_c(_setup.SizeFeedBackEnter,{on:{"onSizeFeedbackBtnClick":_setup.onSizeFeedbackBtnClick}}),_vm._v(" "),_c(_setup.SizeFeedBack,_vm._b({ref:"SIZE_FEED_BACK",attrs:{"feedBackBasicSizes":_setup.feedBackBasicSizes},on:{"localClick":_setup.clickLocalTextSelectHandle}},'SizeFeedBack',_setup.sizeFeedBackBind,false))]:_vm._e(),_vm._v(" "),(_setup.compShowConfig.showMallEntry)?_c(_setup.Mall,_vm._b({attrs:{"choose-check":_setup.chooseCheck},on:{"mallClick":_setup.mallClick}},'Mall',_setup.mallBind,false)):_vm._e(),_vm._v(" "),_c(_setup.QuickShip,_vm._b({directives:[{name:"show",rawName:"v-show",value:(_setup.compShowConfig.showQuickShipEntry),expression:"compShowConfig.showQuickShipEntry"}],on:{"updateQuickShip":_setup.handleUpdateQuickShip}},'QuickShip',_setup.quickShipBind,false))],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }