import { ref, computed } from 'vue'
import { SizeTipsCalculator } from 'public/src/pages/components/sizes/sizeComputed.js'

export default function useSizeTip(props, reactiveInfo) {
  const { 
    tipsCombo,
    constant,
    parentCats,
    language,
    sizeComposeInfo,
  } = reactiveInfo || {}
  const showHightSize = ref(false)
  const isShowSizeTips = ref({})
  const feedBackBasicSizes = ref([])
  const hasTargetCatId = ref(false)

  const commonSizeTipsInit = () => {
    const { skcSaleAttr, polyAttrSize } = sizeComposeInfo.value || {}
    const {
      defaultTrueToSizeText,
      sizeTipsConfig,
      commentOverView,
      showTrueToSizeStrongAbt,
      isReady,
    } = tipsCombo.value || {}
    const data = new SizeTipsCalculator({
      commentOverView,
      sizeTipsConfig,
      defaultTrueToSizeText,
      isReady,
      constant: constant.value,
      parentCats: parentCats.value,
      language: language.value,
      showTrueToSizeStrongAbt,
      skcSaleAttr,
      polyAttrSize,
    }).getSizeTipsConfig()
    showHightSize.value = data.showHightSize
    isShowSizeTips.value = data.isShowSizeTips
    feedBackBasicSizes.value = data.feedBackBasicSizes
    hasTargetCatId.value = data.hasTargetCatId
  }

  const defaultTrueToSizeText = computed(() => {
    return tipsCombo.value?.defaultTrueToSizeText
  })

  const isShowSizeTipsText = computed(() => {
    return !!(!defaultTrueToSizeText.value && isShowSizeTips.value?.isShow)
  })

  const trueToSize = computed(() => {
    const { heelHeight, showHeelHeight } = tipsCombo.value || {}
    if (isShowSizeTipsText.value) {
      return `${isShowSizeTips.value?.sizeTipsText || ''}${
            showHeelHeight ? '; ' + heelHeight : ''
          }`
    }
    if (showHeelHeight) {
      return `${defaultTrueToSizeText.value}${
          constant.value.cssRight || !defaultTrueToSizeText.value ? ' ' : '; '
        }${heelHeight}`
    }
    return defaultTrueToSizeText.value
  })

  // tipsShow + lazyTipsShow
  // TODO: 好像不需要监听showHeelHeight了，关注下
  const showTipsContent = computed(() => {
    const { showHeelHeight } = tipsCombo.value || {}
    return trueToSize.value || showHeelHeight || isShowSizeTipsText.value || isShowSizeTips.value?.showTrueToSizeStrong
  })

  return { hasTargetCatId, feedBackBasicSizes, showHightSize, isShowSizeTips, isShowSizeTipsText, trueToSize, showTipsContent, commonSizeTipsInit }
}
