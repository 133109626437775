<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

const openReview = (listeners) => {
  daEventCenter.triggerNotice({
    daId: '1-6-1-121',
    extraData: {
      from: 1,
    },
  })
  listeners['click-comment'] && listeners['click-comment']()
}

export default {
  name: 'CommentOverview',
  functional: true,
  props: {
    totalComment: {
      type: Number,
      default: 0,
    },
    commentInfo: {
      type: Object,
      default: () => ({}),
    },
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  render(h, { props, listeners }) {
    const { language, commentInfo = {}, totalComment } = props
    const commentRankAverage = commentInfo?.comment_rank_average || 0
    const { comment_fuzzy, comment_num_str } = commentInfo || {}
    const displayCommentNum = comment_fuzzy ? comment_num_str : totalComment > 1000 ? '1000+' : totalComment

    return (
      <div
        {...{
          directives: [
            {
              name: 'ada',
              value: { level: 1, pos: 0 },
            },
            {
              name: 'expose',
              value: {
                id: '1-6-1-120',
                data: {
                  from: 1,
                },
              },
            },
          ],
        }}
        class="product-intro-sf__comment-overview j-comment-overview j-push-history-hash"
        state="comment-all"
        da-event-click="1-6-2-10"
        role="text"
        aria-label={language.SHEIN_KEY_PWA_15008 + ' ' + language.SHEIN_KEY_PWA_17960 + ' ' + displayCommentNum}
        data-review-num={totalComment}
        vOn:click={() => openReview(listeners)}
      >
        {commentRankAverage && (
          <div class="product-intro-sf__star">
            <i class="sui_icon_star_5_12px_2 suiiconfont"></i>
            <p class="product-intro-sf__star-num">{commentRankAverage}</p>
          </div>
        )}
        <div class="product-intro-sf__comment">
          <span aria-hidden="true" num={totalComment}>
            ({displayCommentNum})
          </span>
          <i class="product-intro-sf__more suiiconfont sui_icon_more_right_12px_2"></i>
        </div>
      </div>
    )
  },
}
</script>

<style lang="less" scoped>
.product-intro-sf {
  &__comment-overview {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  &__star {
    display: flex;
    align-items: center;
    font-size: 0.3733rem;
    color: #000;

    [class^='sui_icon_star'] {
      display: block;
      color: @sui_color_honor;
      margin-bottom: 1px;
      margin-right: 2px;
    }
  }

  &__star-num {
    font-style: normal;
    font-weight: 400;
    .font-dpr(22px);
  }

  &__comment {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 0.0533rem;
    .font-dpr(22px);
    // no rem
    height: 16px;
    line-height: 16px;
  }

  &__more {
    margin-right: -0.1067rem;
    margin-top: 1px;
  }
}
</style>
