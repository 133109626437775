<script lang="jsx">
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ClientOnly from 'vue-client-only'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue' 
import CropImageContainerJsx from 'public/src/pages/components/CropImageContainer/CropImageContainerJsx.vue'
import { transformImg } from '@shein/common-function'

export default {
  name: 'CarouselsImage',
  functional: true,
  components: {
    ClientOnly,
    SwiperSlideItem,
    CropImageContainerJsx,
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    locals: {
      type: Object,
      default: () => ({})
    }
  },
  render(h, { props, listeners, slots }) {
    const { item, index, locals } = props
    const { fixedRatio, LAZY_IMG, isSsr } = locals || {}

    const _handleGoodsImgClick = (index, item) => {
      listeners?.clickGoodsImg(index)
      // 主图点击埋点上报
      daEventCenter.triggerNotice({
        daId: '1-6-1-27',
        extraData: { pic: item }
      })
    }

    let dataSrc = item?.origin_image || null
    dataSrc = dataSrc ? transformImg({ img: dataSrc }) : LAZY_IMG

    if (isSsr) {
      dataSrc =  dataSrc.replace(/(_square)|(_squfix)/ig, '') // 1-1 这种比例防止ssr直出补图样式与客户端不一致
    }

    const isFsIndex = index < 1
    const itemImgClass = () => {
      const result = []
      if (isFsIndex) { // 前一图采用手动补图逻辑
        result.push('fsp-element')
      } else {
        result.push('lazyload')
      }
      return result
    }

    return (
      <SwiperSlideItem
        key={item.img_id} 
        index={index}
        class="main-picture__slide-item"
        vOn:click={() => { _handleGoodsImgClick(index, item) }}
      >
        <CropImageContainerJsx
          fixedRatio={fixedRatio}
          imgSrc={dataSrc}
          imgProps={{
            src: isSsr && isFsIndex ? dataSrc : item.bfCache || LAZY_IMG,
            class: itemImgClass(),
            dataDesignWidth: '750'
          }}
        />

        {/* 主图Beauty品类蒙版 */}
        {slots().fistMask || null}
        {/* 主图角标 */}
        {slots().locateLabels || null}
      </SwiperSlideItem>
    )
  }
}

</script>
