<template>
  <div
    v-expose="{ id: '1-6-1-127', data: { type: 'button' } }"
    class="retention-tips__container"
  >
    <div class="retention-tips__text">
      <i class="suiiconfont sui_icon_best_deal_12px icon"></i>
      {{ language.SHEIN_KEY_PWA_24439 }}
    </div>
    <div class="retention-tips__triangle"></div>
  </div>
</template>

<script>
export default {
  name: 'RetentionTips',
  props: {
    language: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less">
.retention-tips {
  &__container {
    position: relative;
    max-width: 100%;
    padding: 0.0533rem 0.10667rem;
    line-height: 0.32rem;
    color: @sui_color_white;
    background: @sui_color_promo;
    font-size: 10px;
    .icon {
      margin-right: 4/75rem;
    }
  }
  &__triangle {
    position: absolute;
    width: 0;
    height: 0;
    border: .1333rem solid transparent;
    border-top-color: @sui_color_welfare_dark;
    bottom: -.1rem;
    right: 2px;
    transform: rotate(135deg);
  }
  &__text {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
