import Vue from 'vue'
import Report from './report.vue'
import ReportDsa from './reportDsa.vue'
let ReportInstance = null
let DsaReportInstance = null
const CommentReportPopover = {
  open: ({ config, props }) => {
    const isDsaSite = ['meur', 'meuqs', 'pwfr', 'pwde', 'pwit', 'mes', 'pwnl', 'mpl', 'mpt', 'mroe', 'pwes', 'mse', 'pwuk'].includes(gbCommonInfo?.SiteUID)
    const isDsaReport = isDsaSite && !config?.isFree && gbCommonInfo?.WEB_CLIENT === 'shein'
    if (ReportInstance && !isDsaReport) return ReportInstance.open(config)
    if (DsaReportInstance && isDsaReport) return DsaReportInstance.open(config)
    const vm = new Vue({
      render(h) {
        return h(isDsaReport ? ReportDsa : Report, {
          props
        })
      }
    }).$mount()
    document.body.appendChild(vm.$el)
    const Instance = vm.$children[0]
    Instance.open(config)
    if (isDsaReport) {
      DsaReportInstance = Instance
    } else {
      ReportInstance = Instance
    }
  },
  close: () => {
    if (ReportInstance) return ReportInstance.closeReport()
    if (DsaReportInstance) return DsaReportInstance.closeReport()
  }
}

export default CommentReportPopover
