<script lang="jsx">

/**
 * 主图角标组件
 */
export default {
  name: 'LocateLabels',
  functional: true,
  props: {
    locateLabelData: {
      type: Array,
      default: () => []
    }
  },
  render(h, { props }) {
    const { locateLabelData } = props
    if (!locateLabelData?.length) return null

    /**
     * 根据角标类型/方位添加样式
     */
    const getLabelsStyle = (label) =>  {
      const { subscriptType, premiumFlagPosition = '', tagColor = '#fff', bgColor = '#000' } = label || {}
      // 根据角标方位类型添加样式
      const inUpper = premiumFlagPosition.indexOf('UPPER') > -1
      const inLower = premiumFlagPosition.indexOf('LOWER') > -1
      const inLeft = premiumFlagPosition.indexOf('LEFT') > -1
      const inRight = premiumFlagPosition.indexOf('RIGHT') > -1

      //  文本类型角标添边距
      const space = subscriptType === 'text' ? '.32rem' : '0'

      const param = {
        top: inUpper ? space : 'auto',
        bottom: inLower ? space : 'auto',
        left: inLeft ? '0' : 'auto',
        right: inRight ? '0' : 'auto',
        
      }

      if (subscriptType == 'image') {
        return param
      } else {
        return {
          ...param,
          color: tagColor,
          background: bgColor
        }
      }
    }

    return locateLabelData.map(label => {
      //  图片类型角标
      if (label.subscriptType === 'image' && !!label?.icon) {

        return (
          <img 
            class="main-picture__labels-images not-fsp-element"
            style={getLabelsStyle(label)}
            key={label?.premiumFlagPosition}
            src={label?.icon} 
          />
        )
      }

      //  文本类型角标
      if (label.subscriptType === 'text' && !!label?.tagName) {
        return (
          <div 
            class="main-picture__labels-text not-fsp-element"
            style={getLabelsStyle(label)}
            key={label?.premiumFlagPosition} 
          >
            {label.tagName}
          </div>
        )
      } 

      return null
    })
  }
}
</script>

<style lang="less" scoped>
@space-text : .32rem;
@space-img  : 0px;

.main-picture {
  &__labels-images {
    position: absolute;
    width: 100%;
    height: auto;
  }

  &__labels-text {
    position: absolute;
    width: auto;
    height: auto;
    padding: .0534rem .16rem;
    line-height: .4533rem /* 17/37.5 */;
    font-size: 14px;
    min-width: .89rem;
  }
}
</style>

