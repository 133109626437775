<script lang="jsx">
export default {
  name: 'PlaceholderGoodsInfo',
  functional: true,
  props: {
    isQuickView: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props }) {
    const { isQuickView } = props
    return (
      <div class={['placeholder-container', isQuickView ? 'quickview' : '']}>
        <div class="placeholder-goods-prices bg-color"></div>
        <div class="placeholder-goods-name bg-color"></div>
        <div class="placeholder-goods-desc bg-color"></div>
        <div>
          <ul class="placeholder-goods-colors">
            <li class="placeholder-goods-coloritem bg-color"></li>
            <li class="placeholder-goods-coloritem bg-color"></li>
            <li class="placeholder-goods-coloritem bg-color"></li>
            <li class="placeholder-goods-coloritem bg-color"></li>
            <li class="placeholder-goods-coloritem bg-color"></li>
          </ul>
        </div>
        <div class="placeholder-goods-country bg-color"></div>
        <ul class="placeholder-goods-size">
          <li class="placeholder-goods-sizeitem bg-color"></li>
          <li class="placeholder-goods-sizeitem bg-color"></li>
          <li class="placeholder-goods-sizeitem bg-color"></li>
          <li class="placeholder-goods-sizeitem bg-color"></li>
          <li class="placeholder-goods-sizeitem bg-color"></li>
        </ul>
      </div>
    )
  }
}
</script>

<style lang="less" scoped>
.placeholder-container {
  width: 100%;
  padding-bottom: 0.2rem;
  opacity: 1;
  animation: 700ms loading ease-in-out infinite;
  .bg-color {
    background: #f4f4f4;
  }

  .placeholder-goods-title {
    width: 1.5rem;
    height: 0.4rem;
    margin-bottom: 0.2rem;
  }
  .placeholder-goods-prices {
    width: 2rem;
    height: 0.6rem;
    margin-bottom: 0.2rem;
  }
  .placeholder-goods-name {
    width: 100%;
    height: 0.5rem;
    margin-bottom: 0.2rem;
  }
  .placeholder-goods-desc {
    width: 2rem;
    height: 0.5rem;
    margin-bottom: 0.2rem;
  }
  .placeholder-goods-colors {
    display: flex;
    margin-bottom: 0.3rem;
  }
  .placeholder-goods-coloritem {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    margin-right: 10px;
  }
  .placeholder-goods-country {
    width: 2rem;
    height: 0.5rem;
    margin-bottom: 0.2rem;
  }
  .placeholder-goods-size {
    display: flex;
    margin-bottom: 0.3rem;
  }
  .placeholder-goods-sizeitem {
    width: 60px;
    height: 28px;
    border-radius: 14px;
    margin-right: 10px;
  }
}

.quickview {
  padding-top: 0.2rem;
  padding-bottom: 0;
}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
</style>
