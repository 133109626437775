<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  name: 'ColorV2Shade',
  functional: true,
  props: {
    language: {
      type: Object,
      default: () => ({})
    },
    clickShowFindMyShade: {
      type: Function,
      default: () => {}
    },
  },
  render(h, { props }) {
    const { language, clickShowFindMyShade } = props
    return <div class='findMyShade' vOn:click={clickShowFindMyShade}>
      {language.SHEIN_KEY_PWA_18952}
      <Icon 
        name="sui_icon_more_right_16px" 
        size="16px" 
        color="#959595"
      />
    </div>
  }
}
</script>

<style lang="less" scoped>
.findMyShade {
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding: .213333rem .32rem;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(---sui_color_gray_dark3, #767676);
    background: var(---sui_color_gray_weak2, #F6F6F6);
    margin-bottom: 8px;

    & > span {
        margin-left: auto;
    }
}
</style>
