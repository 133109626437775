<script lang="jsx">
import { Dialog as SDialog, Button as SButton } from '@shein/sui-mobile'
export default {
  name: 'ExclusiveTag',
  functional: true,
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    language: {
      type: Object,
      default: () => ({})
    },
  },
  render(h, { props, listeners }) {
    const { info, language } = props
    const { amountWithSymbol, IMG_LINK, text, isRw, showVipExplaination, descText } = info

    return (
      <div class="exclusive-tag" {...{ directives: [{ name: 'expose', value: { id: '1-6-1-28', data: { from: 1 } } }] }}>
        <p class="exclusive-tag__currency-price">{amountWithSymbol}</p>
        <div class="exclusive-tag__line"></div>
        <img class="exclusive-tag__img" src={IMG_LINK['s3-crown']} />
        <p class="exclusive-tag__exclusive">{text}</p>
        {!isRw && <i class="suiiconfont sui_icon_doubt_12px_2 exclusive-tag__icon" vOn:click={() => listeners['showModal'](true)}></i>}
        <ClientOnly>
          {showVipExplaination && (
            <SDialog visible={showVipExplaination} class="vip-dialog">
              <div class="vip-dialog__modal-content">
                <h4 class="exclusive-tag__modal-title">{text}</h4>
                <div class="exclusive-tag__modal-desc" domPropsInnerHTML={descText}></div>
              </div>
              <SButton
                type={['primary']}
                width="100%"
                vOn:click={() => listeners['showModal'](false)}
              >
                { language.SHEIN_KEY_PWA_15146 }
              </SButton>
            </SDialog>
          )}
        </ClientOnly>
      </div>
    )
  },
}
</script>

<style lang="less">
/* stylelint-disable  selector-max-specificity */
.exclusive-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 0.48rem;
  padding: 0 0.1067rem;
  background: linear-gradient(0deg, rgba(224, 203, 158, 0.6), rgba(224, 203, 158, 0.6)), #ffffff;
  border-radius: 0.0533rem;
  line-height: 1;
  .exclusive-tag__currency-price {
    font-style: normal;
    font-weight: 700;
    .font-dpr(24px);
    display: flex;
    align-items: center;
    color: #222222;

    /*rw:begin*/
    color: #936d0c;
  }

  .exclusive-tag__line {
    width: 1px;
    height: 0.32rem;
    opacity: 0.2;
    box-sizing: border-box;
    margin: 0 0.1067rem;
    background: #000;
  }

  .exclusive-tag__img {
    width: 0.6933rem;
    height: 0.2667rem;

    /*rw:begin*/
    width: 0.5904rem;
  }

  .exclusive-tag__exclusive {
    font-style: normal;
    font-weight: 400;
    .font-dpr(20px);
    line-height: 0.32rem;
    color: #222222;
    margin-left: 0.0533rem;
  }

  .exclusive-tag__icon {
    display: block;
    color: #ae9866;
    margin-left: 0.08rem;
  }

  .exclusive-tag__modal-title {
    line-height: 0.88rem;
    font-weight: bold;
    font-weight: 700;
  }

  .exclusive-tag__modal-desc {
    color: #a78a45;
    line-height: 0.88rem;
    font-weight: 700;
  }
  .c-modal-wrap {
    .modal-footer {
      .modal-btn {
        border-top: none;
        border-radius: 0;
      }
    }
  }

  .vip-dialog {
    .S-dialog__normal {
      width: 5.87rem;
    }
    .S-dialog__top {
      display: none;
    }
    .S-dialog__body {
      padding: 0;
    }
    .S-button {
      line-height: 1.15;
      font-size: 12px;
      font-weight: normal;
    }

    &__modal-content {
      padding: .6rem .4rem .47rem;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>
