<script lang="jsx">

export default {
  name: 'CropImageContainerJsx',
  functional: true,
  props: {
    imgSrc: {
      required: true,
      type: String,
      default: ''
    },

    imgProps: {
      type: Object,
      default() {
        return {}
      }
    },
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    colorBlockText: {
      type: String,
      default: ''
    },
    saleOut: { // 是否售罄
      type: Boolean,
      default: true
    },
  },
  render(h, { props }) {
    const { imgSrc, imgProps, isSupportCropImage, colorBlockText, saleOut } = props
    // 商品中心原图
    const originImgSrc = () => {
      // 接入 Apollo 控制是否或者商品中心原图(不带后缀)
      if (isSupportCropImage) {
        return imgSrc.replace(/(_square)|(_squfix)/ig, '')
      }
      return imgSrc
    }

    return (
      <div
        class={['skc-prepose-container', { 'sale-out': saleOut }]}
      >
        <div class={['skc-prepose-container__img']}>
          <img
            class={[imgProps?.class]}
            data-src={imgProps?.class?.includes('lazyload') ? originImgSrc() : void 0} // 有lazyload才做懒加载
            src={imgProps?.src || originImgSrc()}
            data-design-width={imgProps?.dataDesignWidth}
            data-exp={imgProps?.dataExp}
          />
          {saleOut && <div
            class="goods-color__soldout"
          >
            <i></i>
          </div>}
        </div> 
        <div class={['skc-prepose-container__text', { 'two-line': colorBlockText.indexOf(' ') > 0 }]}>{colorBlockText}</div>
      </div>
    )
  }
}
</script>

<style lang="less">
.skc-prepose-container {
  position: relative;
  display: flex;
  align-items: center;
  &.sale-out{
    color: #CCCCCC;
    .skc-prepose-container__img{
      border: none;
    }
  }
  &__img{
    position: relative;
    width: 1.06rem;
    height: 1.06rem;
    overflow: hidden;
    .border-dpr(border, 2px, #e5e5e5);
    border-radius: 2px;
    flex: 0 0 auto;
  }
  &__text{
    display: none;
    max-width: 80px;
    .text-overflow();
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: var(--crop-img-fit, contain);
  }
}
</style>
