<script lang="jsx">
import MallSelectContent from './MallSelectContent'
export default {
  name: 'MallEntry',
  props: {
    skuInfo: {
      type: Object,
      default: () => ({}),
    },
    mallList: {
      type: Array,
      default: () => [],
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    mallCode: {
      type: String,
      default: '',
    },
    customerStyle: {
      type: Object,
      default: () => {},
    },
    isOnSale: {
      type: Boolean,
      default: false,
    },
    handleChooseCheck: {
      type: Function,
      default: () => new Function(),
    },
    from: {
      type: String,
      default: 'popup',
    },
  },
  computed: {
    location() {
      return this.from == 'detailPage' ? 'detail' : 'popup'
    },
    exposeStr() {
      return this.mallList.map((_) => _.mall_code).join('`')
    },
    mallInfo() {
      return this.mallList?.find((_) => _.mall_code == this.mallCode) || {}
    },
    mallStocks() {
      return this.skuInfo?.mall_stock || []
    },
    mallStock() {
      return this.mallStocks.find((_) => _.mall_code == this.mallCode) || {}
    },
    currentSkuSoldOut() {
      if (!this.mallStocks?.length) return false
      return !!this.mallStocks.every(item => !item.stock)
    },
    mallTips() {
      const { mallDescription } = this.mallInfo || {}
      return mallDescription?.reduce?.((allStr, str, index) => {
        if (typeof str !== 'string' || !str) return allStr
        if (index % 2 === 0) {
          return allStr += `${str}`
        }
        return allStr += `<b>${str}</b>`
      }) || ''
    },
  },
  methods: {
    mallClick(mallCode) {
      const mallStock = this.handleChooseCheck(-1, '', '', mallCode)
      this.$emit('mallClick', {
        mallCode,
        mallStock,
      })
      daEventCenter.triggerNotice({
        daId: '1-6-1-51',
        extraData: {
          mall_code: mallCode,
          location: this.location,
        },
      })
    },
  },
  render() {
    const { 
      customerStyle,
      exposeStr, 
      location, 
      language, 
      mallList, 
      mallCode, 
      isOnSale, 
      mallTips,
      currentSkuSoldOut,
    } = this
    const { bold = false } = customerStyle || {}
    const { handleChooseCheck, mallClick } = this

    const wrapExposeData = {
      mall_code: exposeStr,
      location: location,
    }

    return (
      <MallSelectContent
        bold={bold}
        mall-list={mallList}
        mall-tips={mallTips}
        mall-code={mallCode}
        is-on-sale={isOnSale}
        current-disable={currentSkuSoldOut}
        handle-choose-check={handleChooseCheck}
        wrap-expose-data={wrapExposeData}
        title={language.SHEIN_KEY_PWA_20779}
        v-on:mallClick={mallClick}
      />
    )
  },
}
</script>

<style lang="less">
.goods-size {
  &__mall {
    margin: 0.3733rem 0 0.32rem;
  }
  &__sizes-malltip {
    .flexbox();
    .align-center();
    .space-between();
    padding: 0.213333rem 0.32rem;
    background: #f6f6f6;
    color: #666;
    .font-dpr(24px);
    line-height: 1.2;
    i {
      color: @sui_color_warning;
      margin-right: 0.32rem;
    }
  }
}
</style>
